import { useMemo } from "react";
import { Grid, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useOptionsList } from "api/hooks";
import { ProfileShow } from "api/hooks/useProfile/type";
import { OptionsParams } from "api/urls";
import { WorkExperienceFormScheme } from "components/Modals/WorkExperienceModal/validation";
import { WorkExperienceIcons } from "components/Options/WorkPreference";
import DetailIcon from "components/User/components/DetailIcon";
import ShowMoreContainer from "components/User/components/ShowMoreContainer";
import { HorizontalList, TooltipButton, Typography } from "theme";
import { Edit, FileImage, InfoFill } from "theme/icons";
import { parseDateRange } from "utils/helpers";
import { mapShowToWorkExperience } from "utils/mappers/profileMappers";

type ExperienceItemProps = {
  isLast?: boolean;
  experience: ProfileShow;
  readOnly?: boolean;
  editLabel?: string;
  onEdit?: (data?: WorkExperienceFormScheme) => void;
};

const Line = styled(Grid)(({ theme }) => ({
  borderLeft: "0.25rem solid",
  minHeight: "0.5rem",
  borderColor: theme.palette.background.chart,
}));

const StyledWrap = styled(Grid, {
  shouldForwardProp: (propName) => propName !== "readOnly",
})<Pick<ExperienceItemProps, "readOnly">>(({ readOnly }) => ({
  "& .experience-item-edit-icon": {
    visibility: "hidden",
  },

  ...(!readOnly && {
    cursor: "pointer",
  }),

  "&:hover": {
    ...(!readOnly && {
      "& .experience-item-edit-icon": {
        visibility: "visible",
      },
    }),
  },
}));

const ExperienceItem: React.FC<ExperienceItemProps> = ({
  experience,
  isLast = false,
  readOnly,
  editLabel,
  onEdit,
}) => {
  const {
    palette: { text },
  } = useTheme();
  const {
    showTitle,
    responsibilities = "",
    endDate,
    startDate,
    genre,
    location,
    position,
  } = experience;

  const { muiOptions } = useOptionsList(OptionsParams.ShowExperienceCategories);

  const optionName = useMemo(
    () => muiOptions.find((el) => el.value === genre)?.label || "",
    [genre, muiOptions],
  );

  return (
    <StyledWrap readOnly={readOnly} flexWrap="nowrap" container columnGap={4}>
      <Grid item flex={1} width="50%" container columnGap={2} position="relative">
        <Grid item>
          <Grid container flexDirection="column" rowGap={2} height="100%">
            <Grid item>
              <DetailIcon size="md" bgcolor="chart">
                {WorkExperienceIcons[optionName as keyof typeof WorkExperienceIcons] || (
                  <FileImage />
                )}
              </DetailIcon>
            </Grid>
            {!isLast && (
              <Grid item container justifyContent="center" flex={1}>
                <Line />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item flex={1} textOverflow="ellipsis" overflow="hidden">
          <Typography
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            variant="body1"
            weight="medium"
            mb={0.5}
          >
            {optionName ? `${optionName}: "${showTitle}"` : showTitle}
          </Typography>
          <HorizontalList>
            <Typography component="li" variant="body2" color="secondary">
              {parseDateRange(startDate, endDate)}
            </Typography>
            {location && (
              <Typography component="li" variant="body2" color="secondary">
                {location?.cityName && `${location?.cityName}, `}
                {location?.adminName && `${location?.adminName}, `}
                {location?.countryName}
              </Typography>
            )}
            {position && (
              <Typography
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                component="li"
                variant="body2"
                color="secondary"
              >
                {position}
              </Typography>
            )}
          </HorizontalList>
        </Grid>
      </Grid>
      <Grid
        item
        flex={1}
        width="100%"
        container
        flexWrap="nowrap"
        justifyContent="space-between"
        columnGap={4}
      >
        <Typography noWrap variant="body1" component="span">
          <ShowMoreContainer lines="3" key={responsibilities}>
            {responsibilities ? (
              responsibilities
            ) : (
              <Grid item container alignItems="center" columnGap={1}>
                <InfoFill color={text.placeholder} />
                <Typography variant="body2" color="secondary">
                  No data available
                </Typography>
              </Grid>
            )}
          </ShowMoreContainer>
        </Typography>
        <Grid item className="experience-item-edit-icon">
          <TooltipButton
            mb={(theme) => theme.spacing(2)}
            onClick={() => onEdit?.(mapShowToWorkExperience(experience))}
            variant="contained"
            tooltipTitle={editLabel ?? "Edit Show"}
            icon={<Edit />}
          />
        </Grid>
      </Grid>
    </StyledWrap>
  );
};

export default ExperienceItem;
