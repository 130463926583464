import { FC } from "react";
import { useOptionsList } from "api/hooks";
import { OptionsParams } from "api/urls";
import ControlledSelectionCheckbox, {
  ControlledSelectionCheckboxProps,
} from "components/ControlledInputs/ControlledSelectionCheckbox";

type ExpertiseProps = Pick<ControlledSelectionCheckboxProps, "multiple" | "name" | "control">;

const Expertise: FC<ExpertiseProps> = (props) => {
  const { muiOptions } = useOptionsList(OptionsParams.Specializations);

  return (
    <ControlledSelectionCheckbox
      label="Expertise"
      variant="tiles"
      options={muiOptions}
      {...props}
    />
  );
};

export default Expertise;
