import { FC, useState } from "react";
import WorkExperienceModal, {
  WORK_EXPERIENCE_DEFAULT_VALUES,
} from "components/Modals/WorkExperienceModal/WorkExperienceModal";
import { WorkExperienceFormScheme } from "components/Modals/WorkExperienceModal/validation";
import WorkExperience from "components/WorkExperience/WorkExperience";

type ProfileWorkExperienceProps = {
  readOnly?: boolean;
};

const ProfileWorkExperience: FC<ProfileWorkExperienceProps> = ({ readOnly }) => {
  const [isWorkAddExperienceModalOpen, setIsWorkAddExperienceModalOpen] = useState(false);
  const [isWorkEditExperienceModalOpen, setIsWorkEditExperienceModalOpen] = useState(false);

  const [defaultValues, setDefaultValues] = useState<WorkExperienceFormScheme>(
    WORK_EXPERIENCE_DEFAULT_VALUES,
  );

  return (
    <>
      <WorkExperience
        readonly={readOnly}
        variant="large"
        unwraped={false}
        onAddWorkExperience={() => setIsWorkAddExperienceModalOpen(true)}
        onEditWorkExperience={(data) => {
          setIsWorkEditExperienceModalOpen(true);
          if (data) {
            setDefaultValues(data);
          }
        }}
      />
      <WorkExperienceModal
        key={`${isWorkAddExperienceModalOpen}`}
        open={isWorkAddExperienceModalOpen}
        onClose={() => setIsWorkAddExperienceModalOpen(false)}
        title={readOnly ? "Add new Show" : "Add Work Experience"}
        defaultValues={WORK_EXPERIENCE_DEFAULT_VALUES}
      />
      <WorkExperienceModal
        formId="work-experience-form-edit"
        key={`${defaultValues?.id}${isWorkEditExperienceModalOpen}`}
        open={isWorkEditExperienceModalOpen}
        onClose={() => setIsWorkEditExperienceModalOpen(false)}
        title={readOnly ? "Edit Show" : "Edit Work Experience"}
        defaultValues={defaultValues}
      />
    </>
  );
};

export default ProfileWorkExperience;
