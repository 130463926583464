import { FC, ReactNode, SyntheticEvent, useCallback } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  styled,
} from "@mui/material";
import { Tooltip, Typography } from "theme";

type SelectionGroupVariant = "list" | "tiles";
type SelectionGroupDirection = "row" | "column";

export type SelectionGroupProps = {
  className?: string;
  label?: string;
  description?: string;
  name: string;
  options: { value: string; icon?: ReactNode; label: string }[];
  disabled?: boolean;
  disabledTooltip?: string;
  variant?: SelectionGroupVariant;
  loading?: boolean;
} & (
  | {
      type: "radio";
      value?: string;
      onChange: (value: string) => void;
    }
  | {
      type: "checkbox";
      value: string[];
      onChange: (value: string[]) => void;
    }
);
type StyledSelectionGroupProps = {
  variant?: SelectionGroupVariant;
  dense?: boolean;
  direction?: SelectionGroupDirection;
  wrap?: boolean;
  width?: string;
};

export type AllSelectionGroupProps = SelectionGroupProps & StyledSelectionGroupProps;

const Loader = styled(CircularProgress)(({ theme }) => ({
  alignSelf: "center",
  margin: theme.spacing(2),
}));

const LabelWrapper: FC<{ children: ReactNode; tooltip?: string }> = ({
  children,
  tooltip,
}: any) => {
  return tooltip ? (
    <Tooltip title={tooltip} placement="top-start">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

const SelectionGroup = ({
  label,
  name,
  type,
  value,
  options,
  variant = "list",
  className,
  onChange,
  description,
  disabled,
  disabledTooltip,
  loading,
}: SelectionGroupProps) => {
  const isTileView = variant === "tiles";

  const handleChange = useCallback(
    (e: SyntheticEvent, checked: boolean) => {
      const changedValue = (e.currentTarget as HTMLInputElement).value;

      if (type === "radio") {
        onChange(changedValue);
      } else {
        onChange(checked ? [...value, changedValue] : value.filter((i) => i !== changedValue));
      }
    },
    [type, value, onChange],
  );

  return (
    <FormControl className={className}>
      {label && <FormLabel focused={false}>{label}</FormLabel>}
      {description && (
        <Typography color="secondary" variant="body2" mt={0.5} mb={1}>
          {description}
        </Typography>
      )}

      {loading ? (
        <Loader size={32} />
      ) : (
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              name={name}
              key={option.value}
              value={option.value}
              control={type === "radio" ? <Radio /> : <Checkbox />}
              disabled={disabled}
              label={
                <LabelWrapper tooltip={(disabled && disabledTooltip) || undefined}>
                  {option.icon && isTileView ? (
                    <>
                      <span className="icon">{option.icon}</span>
                      {option.label}
                    </>
                  ) : (
                    option.label
                  )}
                </LabelWrapper>
              }
              checked={type === "radio" ? value === option.value : value.includes(option.value)}
              onChange={handleChange}
            />
          ))}
        </FormGroup>
      )}
    </FormControl>
  );
};

export default styled(SelectionGroup, {
  shouldForwardProp: (propName) =>
    !["dense", "direction", "wrap", "width"].includes(propName as string),
})<StyledSelectionGroupProps>(({
  theme,
  variant = "list",
  dense = false,
  direction = "row",
  wrap,
  width = "30%",
}) => {
  const isTileView = variant === "tiles";
  const isRowDirection = direction === "row";

  if (!isTileView) {
    return isRowDirection
      ? {
        "& .MuiFormGroup-root": {
          display: "flex",
          flexDirection: "row",
          columnGap: theme.spacing(4),
        },
      }
      : { width: "100%" };
  }

  return {
    width: "100%",

    "&.MuiFormControl-root": {
      width: "100%",
    },
    "& > .MuiFormLabel-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      flexWrap: wrap ? "wrap" : "nowrap",
      gap: theme.spacing(dense ? 1 : 2),
    },
    "& .MuiFormControlLabel-root": {
      display: "flex",
      width: "100%",
      margin: 0,
      position: "relative",

      ...(wrap &&
        width && {
        flex: `0 0 ${width}`,
      }),

      "& .MuiRadio-root, & .MuiCheckbox-root": {
        position: "absolute",
        left: 0,
        top: 0,

        "&.Mui-checked + .MuiTypography-root": {
          borderColor: theme.palette.common.black,
          boxShadow: `inset 0px 0px 0px 1px ${theme.palette.common.black}`,
        },
      },
      "& .MuiTypography-root": {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: theme.spacing(dense ? 2 : 3, 1),
        textAlign: "center",
        alignItems: "center",
        fontWeight: theme.typography.fontWeightMedium,
        border: "1px solid",
        borderColor: theme.palette.tertiary.main,
        borderRadius: theme.shape.borderRadius,

        "& .icon": {
          width: theme.spacing(6),
          height: theme.spacing(6),
          padding: theme.spacing(1.5),
          borderRadius: "50%",
          backgroundColor: theme.palette.tertiary.main,
          marginBottom: theme.spacing(1),

          "& svg": {
            display: "block",
            width: theme.spacing(3),
            height: theme.spacing(3),
          },
        },
      },
    },
  };
});
