import { FC, memo } from "react";
import { Grid, Stack, styled } from "@mui/material";
import { Button, Tag, Typography } from "theme";
import { Clear, Delete } from "theme/icons";
import { ProfileQueryLabels, ProfileQueryValues, formatLocationString } from "utils/helpers";
import { QueryObject } from "utils/hooks/useFilter";

type AppliedFilterProps = {
  filter: QueryObject;
  onClear: (item: FilterItem) => void;
  onClearAll: VoidFunction;
};

type FilterItem = {
  key: string;
  keyLabel: string;
  value: string;
  valueLabel: string;
};

const TagLabel = styled(Typography)(({ theme }) => ({
  alignSelf: "center",

  "& > span": {
    color: theme.palette.text.secondary,
  },
}));

const AppliedFilter: FC<AppliedFilterProps> = memo(({ filter, onClear, onClearAll }) => {
  const filterItems = Object.entries(filter).reduce<FilterItem[]>((acc, [key, value]) => {
    if (!value?.length) {
      return acc;
    }

    return [
      ...acc,
      ...value.map((v) => ({
        key,
        keyLabel: ProfileQueryLabels[key as ProfileQueryValues],
        value: v,
        valueLabel: key === ProfileQueryValues.Location ? formatLocationString(v) : v,
      })),
    ];
  }, []);

  const filterItemsCount = filterItems.length;

  if (!filterItemsCount) {
    return null;
  }

  return (
    <Grid container direction="row" spacing={1} wrap="nowrap">
      <Grid item flexGrow={1}>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          <TagLabel weight="medium" component="div">
            Filters <span>({filterItemsCount})</span>:
          </TagLabel>

          {filterItems.map((item) => (
            <Tag
              key={item.key + item.value}
              label={
                <>
                  <Typography variant="body2" weight="medium" component="span">
                    {item.keyLabel}:
                  </Typography>{" "}
                  <Typography variant="body2" weight="regular" component="span">
                    {item.valueLabel}
                  </Typography>
                </>
              }
              deleteIcon={<Clear />}
              onDelete={() => onClear(item)}
            />
          ))}
        </Stack>
      </Grid>

      <Grid item flexShrink={0}>
        <Button color="tertiary" variant="contained" startIcon={<Delete />} onClick={onClearAll}>
          Clear Filters
        </Button>
      </Grid>
    </Grid>
  );
});

export default AppliedFilter;
