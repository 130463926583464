import ToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";

export default styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(1.25, 1.25),
  border: 0,
  color: theme.palette.secondary.main,
  textTransform: "capitalize",

  "&.MuiToggleButton-root": {
    "&.Mui-disabled": {
      color: "inherit",
      border: "none",
    },

    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.inverse,
      borderRadius: theme.shape.borderRadius,

      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },

  "&:hover": {
    backgroundColor: "inherit",
    opacity: 0.8,
  },
}));
