import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Link, Typography, Button } from "theme";
import { useAuth } from "utils/context/Auth/AuthContext";
import { Roles, User } from "utils/context/Auth/user";
import { useError } from "utils/hooks";
import useFetch, { ResponseError } from "utils/hooks/useFetch";
import { SignInFormScheme, SignInResponse, signInScheme } from "./validation";
import { ErrorsAlert } from "../components/ErrorsAlert/ErrorsAlert";

const SignIn = () => {
  const navigate = useNavigate();
  const request = useFetch();
  const location = useLocation();
  const { setUser, isAccessEnabled } = useAuth();
  const isFromLocation = Boolean(location.state?.from);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm<SignInFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(signInScheme),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { setServerError, errorsList, isError } = useError(errors);

  const { mutate: signIn, isPending } = useMutation({
    mutationFn: (data: SignInFormScheme) => {
      return request<SignInResponse>(Urls.Login, {
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    onMutate: () => {
      setServerError(null);
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }

      setUser(data);

      const isTechnician = data.role === Roles.Technician;

      if (isFromLocation) {
        navigate(location.state.from, { replace: true });
      } else {
        navigate(isTechnician ? Routes.MyProfile : Routes.Root, {
          replace: true,
        });
      }
    },
  });

  const { mutate: resendEmail, isPending: isResendEmailLoading } = useMutation({
    mutationFn: () => {
      return request<Pick<User, "firstName" | "lastName" | "email">>(Urls.ResendEmail, {
        method: "POST",
        body: JSON.stringify({ email: getValues("email") }),
      });
    },
    onSuccess: (data) => {
      navigate(Routes.SignIn, {
        state: {
          fullName: `${data?.firstName} ${data?.lastName}`,
          email: data?.email,
        },
      });
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
  });

  const onSubmit: SubmitHandler<SignInFormScheme> = (data) => {
    if (!isDirty) return;

    signIn(data);
  };

  const isSubmitting = isPending || isResendEmailLoading;

  return (
    <Grid container rowSpacing={4}>
      <Grid item flexGrow={1}>
        <Grid
          container
          component="form"
          autoComplete="off"
          id="sign-in-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          flexDirection="column"
          rowSpacing={2.5}
        >
          <Grid item>
            <ControlledTextField
              control={control}
              name="email"
              placeholder="Enter your email"
              label="Email"
              required
              type="text"
              autoComplete="off"
              loading={isSubmitting}
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              control={control}
              name="password"
              placeholder="8 characters minimum"
              label="Password"
              required
              type="password"
              autoComplete="off"
              link={<Link to={Routes.ForgotPassword}>Forgot password</Link>}
              loading={isSubmitting}
            />
          </Grid>
          {isError && (
            <Grid item>
              <ErrorsAlert message={errorsList} resendEmail={resendEmail} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item flexGrow={1} container rowSpacing={2} flexDirection="column">
        <Grid item>
          <Button
            loading={isSubmitting}
            type="submit"
            form="sign-in-form"
            color="primary"
            variant="contained"
            fullWidth
          >
            {isSubmitting ? "Loading" : "Sign In"}
          </Button>
        </Grid>
        {isAccessEnabled && (
          <Grid item textAlign="center">
            <Typography display="inline" variant="body2">
              Don’t have an account? <Link to={Routes.SignUp}>Sign Up</Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SignIn;
