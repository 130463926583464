import { useQuery } from "@tanstack/react-query";
import { Urls } from "api/urls";
import { useFetch } from "utils/hooks";
import { ResponseError } from "utils/hooks/useFetch";
import { ValidateResetPasswordTokenResponse } from "./type";

const useForgotPassword = (token?: string | null) => {
  const fetch = useFetch();

  const validateResetPasswordToken = useQuery<
    ValidateResetPasswordTokenResponse | null,
    ResponseError
  >({
    queryKey: ["validate-reset-password-token", token],
    queryFn: () => fetch(Urls.ValidateResetPasswordToken, { method: "GET", slug: { token } }),
    enabled: Boolean(token),
  });

  return {
    validateResetPasswordToken,
  };
};

export default useForgotPassword;
