import { List, styled } from "@mui/material";

const HorizontalList = styled(List)(({ theme }) => {
  return {
    display: "flex",
    listStyleType: "none",
    flexWrap: "wrap",
    padding: 0,

    "& > li": {
      padding: 0,
      "&:not(:first-of-type)::before": {
        width: "1rem",
        display: "inline-flex",
        justifyContent: "center",
        color: theme.palette.text.placeholder,
        content: "\"•\"",
      },
    },
  };
});

export default HorizontalList;
