import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import SignPageLayout from "components/Layout/SignPageLayout";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { useFetch } from "utils/hooks";

const Activation = () => {
  let params = useParams();
  const request = useFetch();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: (token: string) =>
      request(Urls.Activation, {
        method: "PATCH",
        slug: { token },
      }),
    onSuccess: () => {
      navigate(Routes.SignIn, { replace: true });
    },
  });

  useEffect(() => {
    mutate(params.token as string);
  }, [mutate, params]);

  if (isPending) {
    return (
      <SignPageLayout
        iconType="loading"
        title="Waiting for activation"
        description="Your user account is just a step away from being activated."
        base
      />
    );
  }

  return (
    <SignPageLayout
      iconType="exclamation"
      title="Failed to activate account"
      description="An error occurred during the activation account, please try following the link in the email again."
      base
    />
  );
};

export default Activation;
