import { CircularProgress, Grid, styled } from "@mui/material";

const LoaderWrapper = styled(Grid)(() => ({
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

type LoaderProps = {
  size: number;
};

const Loader: React.FC<LoaderProps> = ({ size }) => {
  return (
    <LoaderWrapper>
      <CircularProgress size={size} />
    </LoaderWrapper>
  );
};

export default Loader;
