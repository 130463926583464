export const getFullname = (firstname?: string, lastname?: string) => {
  if (firstname && lastname) {
    return `${firstname} ${lastname}`;
  }

  return "--";
};

export const getFirstnameLetter = (firstname?: string) => {
  if (firstname) {
    return firstname[0].toUpperCase();
  }

  return "-";
};

export const getNickname = (nickname?: string) => {
  if (!nickname) return "";
  return `a.k.a. ${nickname}`;
};
