import { TypeText, Typography, TypographyProps } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";

export type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "body1" | "body2";
type FontWeight = "regular" | "medium" | "semiBold";

type Props = Omit<TypographyProps, "variant" | "color"> & {
  weight?: FontWeight;
  variant?: Variant;
  color?: keyof TypeText;
};

const getFontWeightLabel = (variant: Variant): FontWeight => {
  if (variant.startsWith("h")) {
    return "semiBold";
  }

  return "regular";
};

const getFontWeight = (theme: Theme, fontWeight: FontWeight) => {
  switch (fontWeight) {
    case "semiBold":
      return theme.typography.fontWeightBold;
    case "medium":
      return theme.typography.fontWeightMedium;

    case "regular":
    default:
      return theme.typography.fontWeightRegular;
  }
};

export default styled(Typography, { shouldForwardProp: (propName) => propName !== "color" })<Props>(
  ({ theme, weight, variant = "body1", color }) => {
    const fontWeightLabel = weight ?? getFontWeightLabel(variant);
    const textColor = theme.palette.text[(color as keyof TypeText) || theme.palette.text.primary];

    return {
      fontWeight: getFontWeight(theme, fontWeightLabel),
      color: textColor,
    };
  },
);
