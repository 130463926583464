import { FC, useMemo } from "react";
import { Grid, useTheme } from "@mui/material";
import { useOptionsList } from "api/hooks";
import { OptionsParams } from "api/urls";
import { UserTileProps } from "components/User/UserTile/UserTile";
import { UserName } from "components/User/components/UserName";
import { Avatar, CopyButton, Typography } from "theme";
import { CopyLink, InfoFill } from "theme/icons";
import { nonNullable } from "utils/helpers";
import { mapExpertise } from "utils/mappers/profileMappers";
import { Expertise } from "utils/types/common";
import ExpertiseLevel from "../../components/ExpertiseLevel";

type ProfileInfoProps = Pick<UserTileProps["profile"], "name" | "avatar" | "nickname"> & {
  expertise: Expertise[] | null;
  copyText?: string;
};

const ProfileInfo: FC<ProfileInfoProps> = ({ name, avatar, expertise, nickname, copyText }) => {
  const {
    palette: { text },
  } = useTheme();
  const { data } = useOptionsList(OptionsParams.Specializations);

  const mappedExpertise = useMemo(() => {
    const mapped = mapExpertise(expertise);

    if (!data) {
      return mapped;
    }

    return data.map((i) => mapped?.find(({ label }) => label === i.name)).filter(nonNullable);
  }, [expertise, data]);

  const initial = name?.[0]?.toUpperCase();
  const isExpertiseAvailable = expertise && expertise.length > 0;

  return (
    <Grid container alignItems="center" flexWrap="nowrap" columnGap={2}>
      <Grid item>
        <Avatar size="md" src={avatar || undefined} fallback={initial} />
      </Grid>
      <Grid item container width="auto" flexDirection="column" rowGap={1} flexGrow={1}>
        <Grid
          item
          container
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          columnGap={1}
        >
          <UserName name={name} nickname={nickname || undefined} />

          {copyText && (
            <CopyButton
              copySuccessText="Link copied!"
              icon={<CopyLink />}
              tooltipTitle="Copy link to profile"
              copyText={copyText}
              variant="contained"
            />
          )}
        </Grid>
        {isExpertiseAvailable ? (
          <Grid item flexWrap="nowrap" width="100%" columnGap={2} container>
            {mappedExpertise?.map((item, index) => (
              <Grid item key={index}>
                <ExpertiseLevel {...item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item container alignItems="center" columnGap={1}>
            <InfoFill color={text.secondary} />
            <Typography color="secondary" variant="body2">
              No expertise available yet
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ProfileInfo;
