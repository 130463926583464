import { ReactNode } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { TextField } from "theme";
import { TextFieldProps } from "theme/components/FormInputs/TextField";

const MAX_INPUT_LENGTH = 3000;

interface ControlledTextFiledProps<TFieldValues extends FieldValues> extends TextFieldProps {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  loading?: boolean;
  link?: ReactNode;
  maxLength?: number;
}

const ControlledTextField = <TFieldValues extends FieldValues>({
  control,
  name,
  loading,
  placeholder,
  label,
  required,
  type,
  link,
  multiline,
  maxLength,
  ...rest
}: ControlledTextFiledProps<TFieldValues>) => {
  const {
    field: { ref, ...fields },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <TextField
      inputRef={ref}
      type={type}
      error={Boolean(error)}
      placeholder={placeholder}
      label={label}
      required={required}
      link={link}
      InputProps={{
        ...rest.InputProps,
        readOnly: loading,
      }}
      multiline={multiline}
      {...fields}
      {...rest}
      {...(multiline && {
        endAdornment: (
          <>
            {fields.value?.length}/{maxLength || MAX_INPUT_LENGTH}
          </>
        ),
      })}
    />
  );
};

export default ControlledTextField;
