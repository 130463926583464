export const enum Specialization {
  Lighting = "Lighting",
  Automation = "Automation",
  Rigging = "Rigging",
}

export enum Level {
  NoLevel = "Level not set",
  Entry = "Entry",
  Intermediate = "Intermediate",
  Advanced = "Advanced",
  Senior = "Senior",
  Expert = "Expert",
}

export type Expertise = {
  specializationName: Specialization;
  specializationId: string;
  levelName: Level | null;
  asOf?: Date;
};

export type ProfileExpertise = {
  name: string;
  level: string;
};

export enum TravelStatus {
  TravelInside = "travelInside",
  TravelAbroad = "travelAbroad",
}

export type FormModalProps = {
  open: boolean;
  onClose: () => void;
};
