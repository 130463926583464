import { FC, useCallback } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, Divider, useTheme } from "@mui/material";
import { useMutateProfile } from "api/hooks";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import Certifications from "components/Options/Certifications";
import Skills from "components/Options/Skills";
import WorkPreference from "components/Options/WorkPreference";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Button } from "theme";
import { ArrowLeft } from "theme/icons";
import { useError } from "utils/hooks";
import ControlSection from "./components/ControlSection";
import { SkillsAndCertificationsFormScheme, skillsAndCertificationsScheme } from "./validation";
import { handlePromiseError } from "../../../utils/helpers";

type SkillsAndCertificationsProps = {
  defaultValues: SkillsAndCertificationsFormScheme;
};

const SkillsAndCertifications: FC<SkillsAndCertificationsProps> = ({ defaultValues }) => {
  const navigate = useNavigate();
  const { spacing } = useTheme();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<SkillsAndCertificationsFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(skillsAndCertificationsScheme),
    defaultValues,
  });

  const { isError, errorsList, setServerError } = useError(errors);
  const {
    certificates: { mutateAsync: updateProfileCertificates, isPending: isCertificatesLoading },
    workPreferencesAndSkills: {
      mutateAsync: updateProfileWorkPreferencesAndSkills,
      isPending: isPreferencesAndSkillsLoading,
    },
  } = useMutateProfile(setServerError);

  const isPending = isPreferencesAndSkillsLoading || isCertificatesLoading;

  const onSubmit: SubmitHandler<SkillsAndCertificationsFormScheme> = useCallback(
    async (data) => {
      if (!isDirty) {
        navigate(Routes.ProfileCreationSummaryAndWorkExperience, { replace: true });
        return;
      }

      await handlePromiseError(() =>
        Promise.all([
          updateProfileWorkPreferencesAndSkills({
            skills: data.skills,
            workPreferences: data.workPreferences,
          }),
          updateProfileCertificates(data.certifications),
        ]),
      );

      if (!isError) {
        navigate(Routes.ProfileCreationSummaryAndWorkExperience, { replace: true });
      }
    },
    [isDirty, isError, navigate, updateProfileCertificates, updateProfileWorkPreferencesAndSkills],
  );

  const onGoBack = useCallback(() => {
    navigate(Routes.ProfileCreationProfileInfo, { replace: true });
  }, [navigate]);

  return (
    <Box
      component="form"
      autoComplete="off"
      id="skills-and-certifications-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <ControlSection
        m={spacing(0, 0, 4, 0)}
        label="Skills"
        description="Highlight your professional skills. This will help Labor Coordinators connect with you for relevant opportunities."
      >
        <Skills name="skills" control={control} />
      </ControlSection>
      <Divider />

      <ControlSection
        m={spacing(4, 0)}
        label="Work preferences"
        description="Tell us about the types of events for which you would be interested in being hired."
      >
        <WorkPreference name="workPreferences" control={control} />
      </ControlSection>
      <Divider />

      <ControlSection
        m={spacing(4, 0, 6.5, 0)}
        label="Certifications"
        description="Highlight your attained certifications and recognitions."
      >
        <Certifications name="certifications" control={control} />
      </ControlSection>

      <Divider />
      <Grid container mt={2} justifyContent="space-between" alignItems="end" columnGap={1}>
        <Grid item />
        <Grid item>{isError && <ErrorsAlert message={errorsList} />}</Grid>
        <Grid item>
          <Grid container columnGap={2}>
            <Button variant="contained" startIcon={<ArrowLeft />} onClick={onGoBack}>
              Go back
            </Button>
            <Button type="submit" loading={isPending} color="primary" variant="contained">
              {isPending ? "Saving" : "Continue"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SkillsAndCertifications;
