import { Grid, styled } from "@mui/material";

const SelectedValuesContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

SelectedValuesContainer.defaultProps = {
  container: true,
  item: true,
  gap: 1,
};

export default SelectedValuesContainer;
