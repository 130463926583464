import { FC } from "react";
import { Box, FormLabel, Grid } from "@mui/material";
import { Typography } from "theme";

export type LabelProps = {
  label: string;
  description?: string;
  htmlFor?: string;
};

const Label: FC<LabelProps> = ({ label, description, htmlFor }) => {
  return (
    <Box>
      <Grid container flexDirection="column" rowGap={0.5}>
        <FormLabel htmlFor={htmlFor}>
          <Typography variant="h5">{label}</Typography>
        </FormLabel>
        {description && (
          <Typography maxWidth="36rem" variant="body2" color="secondary">
            {description}
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default Label;
