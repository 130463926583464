import { FC, ReactNode, useMemo } from "react";
import { styled, ListItemIcon, ListItemText, MenuItem as MuiMenuItem } from "@mui/material";
import { Link } from "react-router-dom";

type MenuItemProps = {
  icon: ReactNode;
  label: string;
} & (
  | {
      to: string;
    }
  | {
      onClick: () => void;
    }
);

const StyledMuiMenuItem = styled(MuiMenuItem)(({ theme }) => {
  return {
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 1.5),

    "& .MuiListItemIcon-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiTypography-root": {
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightMedium,
    },

    "&:hover, &:active, &:focus": {
      backgroundColor: theme.palette.background.primary,
    },
  };
});

const MenuItem: FC<MenuItemProps> = (props) => {
  const menuItemProps = useMemo(
    () =>
      "onClick" in props
        ? {
          onClick: props.onClick,
        }
        : {
          to: props.to,
          component: Link,
        },
    [props],
  );

  return (
    <StyledMuiMenuItem {...menuItemProps}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText>{props.label}</ListItemText>
    </StyledMuiMenuItem>
  );
};

export default MenuItem;
