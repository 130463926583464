import { Box } from "@mui/material";
import { useProfile } from "api/hooks";
import SkillsAndCertifications from "components/Profile/SkillsAndCertifications/SkillsAndCertifications";
import { mapSkillAndCertificationValues } from "utils/mappers";

const DEFAULT_VALUES = {
  skills: [],
  workPreferences: [],
  certifications: [],
};

const PopulatedSkillsAndCertifications = () => {
  const { isPending, profile } = useProfile();

  if (isPending && !profile) {
    return <SkillsAndCertifications defaultValues={DEFAULT_VALUES} />;
  }

  return (
    <Box>
      <SkillsAndCertifications
        defaultValues={mapSkillAndCertificationValues({
          skills: profile?.skills || [],
          certifications: profile?.certificates || [],
          workPreferences: profile?.workPreferences || [],
        })}
      />
    </Box>
  );
};

export default PopulatedSkillsAndCertifications;
