import SummaryAndWorkExperience from "components/Profile/SummaryAndWorkExperience/SummaryAndWorkExperience";
import AccountSettings from "pages/AccountSettings";
import Activation from "pages/Activation";
import ForgotPassword from "pages/ForgotPassword";
import Home from "pages/Home";
import Profile from "pages/Profile";
import PopulatedProfileInfo from "pages/ProfileCreation/PopulatedProfileInfo";
import PopulatedSkillsAndCertifications from "pages/ProfileCreation/PopulatedSkillsAndCertifications";
import ProfileCreation from "pages/ProfileCreation/ProfileCreation";
import ResetPassword from "pages/ResetPassword";
import Search from "pages/Search";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import Survey from "pages/Survey/Survey";
import SurveyQuestionnaire from "pages/Survey/SurveyQuestionnaire";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { Roles } from "utils/context/Auth/user";
import { AuthRoute } from "./AuthRoute";
import { InvitationModeRoute } from "./InvitationModeRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import { Routes } from "./routes";

const router = createBrowserRouter([
  {
    path: Routes.Root,
    element: <Home />,
  },
  {
    path: Routes.AccountSettings,
    element: (
      <InvitationModeRoute>
        <ProtectedRoute isPrivate>
          <AccountSettings />
        </ProtectedRoute>
      </InvitationModeRoute>
    ),
  },
  {
    path: Routes.MyProfile,
    element: (
      <InvitationModeRoute>
        <Profile />
      </InvitationModeRoute>
    ),
  },
  {
    path: Routes.Profile,
    element: (
      <InvitationModeRoute>
        <Profile />
      </InvitationModeRoute>
    ),
  },
  {
    path: Routes.ProfileCreation,
    element: (
      <InvitationModeRoute>
        <ProfileCreation />
      </InvitationModeRoute>
    ),
    children: [
      {
        path: Routes.ProfileInfo,
        element: <PopulatedProfileInfo />,
      },
      {
        path: Routes.SkillsAndCertifications,
        element: <PopulatedSkillsAndCertifications />,
      },
      {
        path: Routes.SummaryAndWorkExperience,
        element: <SummaryAndWorkExperience />,
      },
    ],
  },

  {
    path: Routes.Survey,
    element: (
      <InvitationModeRoute>
        <ProtectedRoute isPrivate allowedRole={Roles.Technician}>
          <Survey />
        </ProtectedRoute>
      </InvitationModeRoute>
    ),
    children: [
      {
        path: Routes.SurveyQuestionnaire,
        element: <SurveyQuestionnaire />,
      },
    ],
  },

  {
    path: Routes.Search,
    element: (
      <InvitationModeRoute>
        <Search />
      </InvitationModeRoute>
    ),
  },
  {
    path: Routes.SignUp,
    element: (
      <AuthRoute>
        <SignUp />
      </AuthRoute>
    ),
  },
  {
    path: Routes.InvitationSignUp,
    element: (
      <InvitationModeRoute>
        <AuthRoute>
          <SignUp />
        </AuthRoute>
      </InvitationModeRoute>
    ),
  },
  {
    path: Routes.SignIn,
    element: (
      <AuthRoute>
        <SignIn />
      </AuthRoute>
    ),
  },
  {
    path: Routes.Activation,
    element: (
      <AuthRoute>
        <Activation />
      </AuthRoute>
    ),
  },
  {
    path: Routes.ForgotPassword,
    element: (
      <AuthRoute>
        <ForgotPassword />
      </AuthRoute>
    ),
  },
  {
    path: Routes.ResetPassword,
    element: (
      <AuthRoute>
        <ResetPassword />
      </AuthRoute>
    ),
  },
  {
    path: "*",
    element: <Navigate to={Routes.Root} />,
  },
]);

export default router;
