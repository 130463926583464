import { FC, useCallback } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Divider, Grid } from "@mui/material";
import { useMutateProfile } from "api/hooks";
import { Profile } from "api/hooks/useProfile/type";
import AvatarUploader from "components/AvatarUploader/AvatarUploader";
import ControlledLocation from "components/ControlledInputs/ControlledLocation";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import Expertise from "components/Options/Expertise";
import Travel from "components/Options/Travel";
import Label from "components/Profile/components/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Button } from "theme";
import { useAuth } from "utils/context/Auth/AuthContext";
import { getFirstnameLetter } from "utils/helpers/getFullname";
import { useError } from "utils/hooks";
import { ProfileInfoFormScheme, profileInfoScheme } from "./validation";
import { handlePromiseError } from "../../../utils/helpers";

type ProfileInfoProps = {
  defaultValues: ProfileInfoFormScheme;
  profile?: Profile;
};

const ProfileInfo: FC<ProfileInfoProps> = ({ defaultValues, profile }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const avatarFallback = getFirstnameLetter(profile?.firstName || user?.firstName);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ProfileInfoFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(profileInfoScheme),
    defaultValues,
  });

  const { isError, errorsList, setServerError } = useError(errors);
  const {
    profileInfo: { mutateAsync: updateProfileInfo, isPending: isProfileInfoLoading },
  } = useMutateProfile(setServerError);

  const onSubmit: SubmitHandler<ProfileInfoFormScheme> = useCallback(
    async (data) => {
      await handlePromiseError(() => updateProfileInfo(data));
      if (!isError) {
        navigate(Routes.ProfileCreationSkillsAndCertifications, { replace: true });
      }
    },
    [isError, navigate, updateProfileInfo],
  );

  return (
    <Box
      component="form"
      autoComplete="off"
      id="profile-info-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Grid container flexDirection="column" rowGap={2.5} mb={4}>
        <Grid item>
          <Label label="Personal info" description="Update your photo and personal details." />
        </Grid>
        <Grid item>
          <AvatarUploader avatar={profile?.avatar} fallback={avatarFallback} />
        </Grid>
        <Grid item>
          <ControlledTextField
            control={control}
            name="nickname"
            placeholder="Enter your nickname"
            label="Nickname"
            type="text"
            autoComplete="off"
          />
        </Grid>
        <Grid item>
          <Expertise name="expertise" control={control} />
        </Grid>
        <Grid item>
          <ControlledLocation
            label="Location"
            name="location"
            placeholder="Search location by City"
            control={control}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container flexDirection="column" rowGap={2.5} my={4}>
        <Label label="Travel" />
        <Travel name="travel" control={control} />
      </Grid>
      <Divider />
      <Grid container mt={2} justifyContent="space-between" alignItems="end" columnGap={1}>
        <Grid item />
        <Grid item>{isError && <ErrorsAlert message={errorsList} />}</Grid>
        <Grid item>
          <Button type="submit" loading={isProfileInfoLoading} color="primary" variant="contained">
            {isProfileInfoLoading ? "Saving" : "Continue"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileInfo;
