import { FC, useState } from "react";
import { Profile } from "api/hooks/useProfile/type";
import SkillsModal from "components/Modals/SkillsModal";
import { Sections } from "components/User/UserProfile/UserProfile";
import { useParams } from "react-router-dom";
import { TooltipButton } from "theme";
import { Edit } from "theme/icons";
import { ProfileSectionLayout, ProfileSectionProps } from "./ProfileSectionLayout";

type EditSkillsProps = Pick<ProfileSectionProps, "readOnly" | "children"> & {
  skills?: Profile["skills"];
};

const EditSkills: FC<EditSkillsProps> = ({ skills, ...props }) => {
  const { profileId } = useParams();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const shouldShowEditIcon = !profileId && skills && skills?.length > 0;

  return (
    <>
      <ProfileSectionLayout
        {...props}
        title={Sections.Skills}
        shouldShowIcon={shouldShowEditIcon}
        placeholder="Highlight your Professional skills. This will help Labor Coordinators connect with you for relevant opportunities."
        onAddClick={() => setEditModalOpen(true)}
        icon={
          <TooltipButton
            onClick={() => setEditModalOpen(true)}
            variant="contained"
            tooltipTitle="Edit Skills"
            icon={<Edit />}
          />
        }
      />
      {skills && (
        <SkillsModal
          key={`${editModalOpen}`}
          defaultValues={{
            skills: skills?.map((el) => el.id) || [],
          }}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditSkills;
