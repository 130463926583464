import { useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { useOptionsList, useProfile } from "api/hooks";
import { OptionsParams } from "api/urls";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import Expertise from "components/Options/Expertise";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Modal, Typography } from "theme";
import { Clipboard } from "theme/icons";
import { useError } from "utils/hooks";
import { useSurveyModal } from "utils/hooks/useSurveyModal";
import { FormModalProps } from "utils/types/common";
import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { z } from "zod";

const ModalDescription = ({ selectedExpertise }: { selectedExpertise?: string[] }) => {
  return (
    <>
      <Typography variant="body2" color="secondary" mb={1}>
        This survey will allow you to set your expertise level for any or all of the three expertise
        areas available: Lighting, Rigging and Automation.
      </Typography>
      <Typography variant="body2" color="secondary">
        {selectedExpertise && selectedExpertise.length > 0
          ? `You have selected ${selectedExpertise.join(
            ", ",
          )} as your expertise. If you want to take a survey for different areas, mark them below:`
          : "You haven't selected any expertise yet. Mark any areas below:"}
      </Typography>
    </>
  );
};

type SurveyStartModalProps = FormModalProps;

const surveyStartScheme = z.object({
  expertise: z
    .array(z.string())
    .min(1, { message: getErrorMessage("expertise", ErrorMessage.SelectAtLeastOneField) }),
});

type SurveyStartValues = z.infer<typeof surveyStartScheme>;

const SurveyStartModal: React.FC<SurveyStartModalProps> = ({ open, onClose }) => {
  const { profile, isPending } = useProfile();
  const { startSurvey, isSurveyModalOpen } = useSurveyModal();
  const { muiOptions: expertiseOptions } = useOptionsList(OptionsParams.Specializations);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SurveyStartValues>({
    defaultValues: {
      expertise: [],
    },
    resolver: zodResolver(surveyStartScheme),
  });

  const selectedExpertise = useMemo(
    () => profile?.expertise?.map((exp) => exp.specializationId) || [],
    [profile?.expertise],
  );
  const expertiseLabels = expertiseOptions
    ?.filter((exp) => selectedExpertise.includes(exp.value))
    .map((exp) => exp.label);

  const { isError, errorsList } = useError(errors);

  const onSubmit: SubmitHandler<SurveyStartValues> = (data) => {
    startSurvey(data.expertise);
  };

  useEffect(() => {
    reset({ expertise: selectedExpertise });
  }, [reset, isSurveyModalOpen, selectedExpertise]);

  if (isPending && !profile) {
    return null;
  }

  return (
    <Modal
      title="Welcome to our expertise survey!"
      description={<ModalDescription selectedExpertise={expertiseLabels} />}
      icon={<Clipboard />}
      open={open}
      size="medium"
      onClose={onClose}
      actionButtons={
        <Grid container columnGap={2}>
          <Grid item flex={1}>
            <Button variant="contained" fullWidth onClick={onClose}>
              Skip for now
            </Button>
          </Grid>
          <Grid item flex={1}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleSubmit(onSubmit)}
            >
              Get started
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Expertise name="expertise" control={control} />
      {isError && (
        <Box mt={2}>
          <ErrorsAlert message={errorsList} />
        </Box>
      )}
    </Modal>
  );
};

export default SurveyStartModal;
