import { useState } from "react";
import { useProfile } from "api/hooks";
import PersonalInfoModal from "components/Modals/PersonalInfoModal/PersonalInfoModal";
import { TooltipButton } from "theme";
import { Edit } from "theme/icons";
import { mapToEditPersonalInfo } from "utils/mappers/profileMappers";

const EditProfile = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { profile } = useProfile();

  return (
    <>
      <TooltipButton
        onClick={() => setEditModalOpen(true)}
        variant="contained"
        tooltipTitle="Edit Personal info"
        icon={<Edit />}
      />
      {profile && (
        <PersonalInfoModal
          key={`${editModalOpen}`}
          avatar={profile.avatar}
          defaultValues={mapToEditPersonalInfo(profile)}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditProfile;
