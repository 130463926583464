import { FC, ReactNode } from "react";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import Account from "components/Account/Account";
import Logo from "components/Logo/Logo";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "router";
import { Button } from "theme";
import { useAuth } from "utils/context/Auth/AuthContext";

export type HeaderProps = {
  mode: "light" | "dark";
  children?: ReactNode;
};

const HeaderWrapper = styled("header")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1.5, 4),

  "@media print": {
    display: "none",
  },
}));

const Header: FC<HeaderProps> = ({ mode, children }) => {
  const { pathname } = useLocation();
  const { isAccessEnabled, isAuthenticated } = useAuth();

  return (
    <HeaderWrapper>
      <Link to="/">
        <Logo mode={mode} />
      </Link>
      {children}
      <Grid item>
        {!isAuthenticated ? (
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <Button
                component={Link}
                variant="contained"
                to={Routes.SignIn}
                state={{ from: pathname }}
              >
                Sign In
              </Button>
            </Grid>
            {isAccessEnabled && (
              <Grid item xs="auto">
                <Button component={Link} to={Routes.SignUp} color="primary" variant="contained">
                  Sign Up
                </Button>
              </Grid>
            )}
          </Grid>
        ) : (
          <Account />
        )}
      </Grid>
    </HeaderWrapper>
  );
};

export default Header;
