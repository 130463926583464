export enum Urls {
  Accounts = "/accounts",
  Activation = "/accounts/activation/:token",
  ResendEmail = "/accounts/activation/resend-email",
  Login = "/accounts/login",
  Logout = "/accounts/logout",
  ForgotPassword = "/accounts/forgot-password",
  ResetPassword = "/accounts/reset-password/:token",
  ChangePassword = "/accounts/change-password",
  ValidateResetPasswordToken = "/accounts/validate-password-token/:token",
  ProfilesSearch = "/search",
  Profile = "/profiles/:id",
  Options = "/lists/:type",
  ProfileAvatar = "/profiles/:profileId/avatar",
  ProfileSummary = "/profiles/:profileId/summary",
  ProfileComplete = "/profiles/:profileId/complete",
  ProfileSkills = "/profiles/:profileId/skills",
  ProfileCertificates = "/profiles/:profileId/certificates",
  ProfileWorkPreferences = "/profiles/:profileId/workpreferences",
  ProfileWorkPreferencesAndSkills = "/profiles/:profileId/workpreferencesandskills",
  ProfileWorkExperience = "/profiles/:profileId/workexperience",
  ProfileWorkExperienceDelete = "/profiles/:profileId/workexperience/:showId",

  Questionnaires = "/questionnaires/:expertise",
  SubmitSurvey = "/profiles/survey/:expertise",
  SystemFeatures = "/system/features",
  SystemValidateInvitation = "/system/validate-invitation/:token",
}

export enum OptionsParams {
  Skills = "skills",
  Certifications = "certifications",
  ShowExperienceCategories = "showExperienceCategories",
  Specializations = "specializations",
  Levels = "levels",
  Sections = "sections",
}
