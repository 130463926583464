import { FC } from "react";
import { useOptionsList } from "api/hooks";
import { OptionsParams } from "api/urls";
import ControlledSelectionCheckbox, {
  ControlledSelectionCheckboxProps,
} from "components/ControlledInputs/ControlledSelectionCheckbox";
import { Theatre, Corporate, Movie, Concert, SpecialEvents } from "theme/icons";

type WorkPreferenceProps = Pick<
  ControlledSelectionCheckboxProps,
  "multiple" | "name" | "control" | "wrap" | "width" | "label"
>;

export const WorkExperienceIcons = {
  Corporate: <Corporate />,
  Theater: <Theatre />,
  "Tv/Film": <Movie />,
  "Concert/Touring": <Concert />,
  Festivals: <SpecialEvents />,
};

const WorkPreference: FC<WorkPreferenceProps> = ({ multiple = true, ...props }) => {
  const { muiOptions } = useOptionsList(OptionsParams.ShowExperienceCategories);

  return (
    <ControlledSelectionCheckbox
      multiple={multiple}
      variant="tiles"
      options={muiOptions}
      {...props}
    />
  );
};

export default WorkPreference;
