import { FC } from "react";
import { Box, BoxProps, styled, Link as MuiLink, Grid } from "@mui/material";
import { UserTileProps } from "components/User/UserTile/UserTile";
import { useLocation } from "react-router-dom";
import { Routes } from "router";
import { Typography, CopyButton, Link } from "theme";
import { MapPinSmall, Email, SuitcaseSmall, SuitcaseCrossed, ArrowRightSmall } from "theme/icons";
import { getGeoNameLabel } from "theme/utils";
import { useAuth } from "utils/context/Auth/AuthContext";
import { TravelStatusLabel } from "utils/mappers/profileMappers";
import UserDetailItem from "./UserDetailItem";

const StyledWrap = styled(Box, {
  shouldForwardProp: (propName: string) => !["medium", "columns"].includes(propName),
})<
  BoxProps & {
    columns?: 2 | 3;
    medium: boolean;
  }
>(({ theme, medium, columns = 3 }) => {
  const gridTemplateColumns =
    columns === 3
      ? "fit-content(33%) fit-content(33%) fit-content(33%)"
      : "fit-content(50%) fit-content(50%)";

  return {
    borderWidth: medium ? 0 : 1,
    borderStyle: "solid",
    backgroundColor: theme.palette.background.default,
    borderColor: medium ? theme.palette.background.default : theme.palette.tertiary.main,
    padding: medium ? theme.spacing(2) : theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    display: "grid",
    width: "100%",
    gridTemplateColumns,
    alignItems: "center",
    columnGap: medium ? theme.spacing(8) : theme.spacing(3),
    overflow: "hidden",

    "@media print": {
      padding: 0,
      columnGap: columns === 3 ? theme.spacing(2) : theme.spacing(6),
      justifyContent: columns === 3 ? "space-between !important" : "start !important",

      "& *": {
        overflow: "visible !important",
      },

      "& > *": {
        columnGap: theme.spacing(2),
        flexDirection: "column !important",
      },
    },
  };
});

type ProfileDetailsProps = Pick<UserTileProps["profile"], "email" | "location"> & {
  travelPreference: TravelStatusLabel;
  size?: "sm" | "md";
};

const ProfileDetails: FC<ProfileDetailsProps> = ({
  email,
  location,
  travelPreference,
  size = "sm",
}) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const isSmall = size === "sm";
  const isReadyToTravel = travelPreference !== TravelStatusLabel.NotReadyToTravel;
  const readyToTravelIcon = isReadyToTravel ? <SuitcaseSmall /> : <SuitcaseCrossed />;
  const readyToTravelText = isReadyToTravel ? travelPreference : "Not ready to travel";

  const locationLabel = getGeoNameLabel(location);

  return (
    <StyledWrap columns={!!location ? 3 : 2} medium={size === "md"}>
      {!!locationLabel && (
        <UserDetailItem size={size} icon={<MapPinSmall />}>
          {isSmall ? (
            locationLabel
          ) : (
            <>
              <Typography variant="body1" color="secondary">
                Location
              </Typography>
              {locationLabel}
            </>
          )}
        </UserDetailItem>
      )}
      <UserDetailItem size={size} icon={readyToTravelIcon}>
        {isSmall ? (
          readyToTravelText
        ) : (
          <>
            <Typography variant="body1" color="secondary">
              Ready to travel
            </Typography>
            {readyToTravelText}
          </>
        )}
      </UserDetailItem>
      <UserDetailItem size={size} icon={<Email />}>
        {isAuthenticated ? (
          <Grid
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            alignItems={isSmall ? "center" : "end"}
            flexWrap="nowrap"
            container
            columnGap={0.5}
          >
            <MuiLink
              color="secondary.main"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              underline="none"
              href={`mailto:${email}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Typography
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                weight="medium"
                color="primary"
                variant={isSmall ? "body2" : "body1"}
                component="span"
              >
                {isSmall ? (
                  email
                ) : (
                  <>
                    <Typography variant="body1" color="secondary">
                      Email
                    </Typography>
                    {email}
                  </>
                )}
              </Typography>
            </MuiLink>
            <CopyButton color="secondary" copyText={email} tooltipTitle="Copy email" />
          </Grid>
        ) : (
          <>
            {!isSmall && (
              <Typography variant="body1" color="secondary">
                Email
              </Typography>
            )}
            {isSmall ? (
              "Sign In "
            ) : (
              <Typography variant="body1" component="span">
                <Link to={Routes.SignIn} state={{ from: pathname }} variant="body1">
                  Sign In <ArrowRightSmall />
                </Link>
              </Typography>
            )}
            to see the email
          </>
        )}
      </UserDetailItem>
    </StyledWrap>
  );
};

export default ProfileDetails;
