import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";
import { Urls } from "api/urls";
import { WorkExperienceFormScheme } from "components/Modals/WorkExperienceModal/validation";
import { ProfileInfoFormScheme } from "components/Profile/ProfileInfo/validation";
import { SkillsAndCertificationsFormScheme } from "components/Profile/SkillsAndCertifications/validation";
import { useAuth } from "utils/context/Auth/AuthContext";
import { useFetch } from "utils/hooks";
import { ResponseError } from "utils/hooks/useFetch";
import { mapProfileInfoToProfile, mapToWorkExperience } from "utils/mappers/profileMappers";
import { Profile, ProfileShow } from "./type";

const useMutateProfile = (setServerError: (err: ResponseError | null) => void) => {
  const { user } = useAuth();
  const request = useFetch();

  const onMutate = useCallback(() => {
    setServerError(null);
  }, [setServerError]);

  const onError = useCallback(
    (error: unknown) => {
      const err = error as ResponseError;
      setServerError(err);
    },
    [setServerError],
  );

  const skills = useMutation({
    mutationFn: (data: SkillsAndCertificationsFormScheme["skills"]) => {
      return request<any>(Urls.ProfileSkills, {
        method: "PUT",
        body: JSON.stringify({
          skillIds: data,
        }),
        slug: {
          profileId: user?.profileId as string,
        },
      });
    },
    onMutate,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["profile", user?.profileId] });
    },
  });

  const certificates = useMutation({
    mutationFn: (data: SkillsAndCertificationsFormScheme["certifications"]) => {
      return request<any>(Urls.ProfileCertificates, {
        method: "PUT",
        body: JSON.stringify({
          certificateIds: data,
        }),
        slug: {
          profileId: user?.profileId as string,
        },
      });
    },
    onMutate,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["profile", user?.profileId] });
    },
  });

  const workPreferences = useMutation({
    mutationFn: (data: SkillsAndCertificationsFormScheme["workPreferences"]) => {
      return request<any>(Urls.ProfileWorkPreferences, {
        method: "PUT",
        body: JSON.stringify({
          workPreferenceIds: data,
        }),
        slug: {
          profileId: user?.profileId as string,
        },
      });
    },
    onMutate,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["profile", user?.profileId] });
    },
  });

  const completeProfile = useMutation({
    mutationFn: () => {
      return request(Urls.ProfileComplete, {
        method: "PUT",
        slug: { profileId: user?.profileId as string },
      });
    },
    onMutate,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["profile", user?.profileId] });
    },
  });

  const summary = useMutation({
    mutationFn: (summary: string) => {
      return request(Urls.ProfileSummary, {
        method: "PUT",
        body: JSON.stringify({ summary }),
        slug: { profileId: user?.profileId as string },
      });
    },
    onMutate,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["profile", user?.profileId] });
    },
  });

  const profileInfo = useMutation({
    mutationFn: (data: ProfileInfoFormScheme) => {
      return request<Profile>(Urls.Profile, {
        method: "PUT",
        body: JSON.stringify(mapProfileInfoToProfile(data)),
        slug: { id: user?.profileId },
      });
    },
    onMutate,
    onError,
    onSuccess: async (data) => {
      if (!data) return;

      queryClient.setQueryData<Profile | null>(["profile", user?.profileId], (oldData) =>
        oldData
          ? {
            ...oldData,
            nickName: data?.nickName || "",
            expertise: data?.expertise || [],
            location: data?.location || null,
            travel: data?.travel || null,
          }
          : oldData,
      );
    },
  });

  const workExperience = useMutation({
    mutationFn: (data: WorkExperienceFormScheme) => {
      return request<{ shows: ProfileShow[] }>(Urls.ProfileWorkExperience, {
        method: "PUT",
        body: JSON.stringify(mapToWorkExperience(data)),
        slug: {
          profileId: user?.profileId as string,
        },
      });
    },
    onMutate,
    onError,
    onSuccess: async (data) => {
      if (!data) return;

      queryClient.setQueryData<Profile | null>(["profile", user?.profileId], (oldData) =>
        oldData
          ? {
            ...oldData,
            shows: data?.shows || [],
          }
          : oldData,
      );
    },
  });

  const deleteWorkExperience = useMutation({
    mutationFn: (id: string) => {
      return request<{ shows: ProfileShow[] }>(Urls.ProfileWorkExperienceDelete, {
        method: "DELETE",
        slug: {
          profileId: user?.profileId as string,
          showId: id,
        },
      });
    },
    onMutate,
    onError,
    onSuccess: async (data) => {
      queryClient.setQueryData<Profile | null>(["profile", user?.profileId], (oldData) =>
        oldData
          ? {
            ...oldData,
            shows: data?.shows || [],
          }
          : oldData,
      );
    },
  });

  // hack to fix the race condition issue on the BE side
  // https://dev.azure.com/NexusTechLink/TechLink/_workitems/edit/509
  const workPreferencesAndSkills = useMutation({
    mutationFn: (data: Omit<SkillsAndCertificationsFormScheme, "certifications">) => {
      return request<any>(Urls.ProfileWorkPreferencesAndSkills, {
        method: "POST",
        body: JSON.stringify({
          workPreferenceIds: data.workPreferences,
          skillIds: data.skills,
        }),
        slug: {
          profileId: user?.profileId as string,
        },
      });
    },
    onMutate,
    onError,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["profile", user?.profileId] });
    },
  });

  return {
    skills,
    certificates,
    workPreferences,
    workPreferencesAndSkills,
    completeProfile,
    summary,
    profileInfo,
    workExperience,
    deleteWorkExperience,
  };
};

export default useMutateProfile;
