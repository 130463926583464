import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "router";

export const useSurveyModal = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const isSurveyModalOpen = state?.isSurveyModalOpen || false;
  const selectedSurveys = state?.selectedSurveys as string[];

  const openSurveyModal = (path?: string) => {
    navigate(path ? path : pathname, {
      replace: true,
      state: { isSurveyModalOpen: true },
    });
  };

  const closeSurveyModal = () => {
    navigate(pathname, {
      replace: true,
      state: { isSurveyModalOpen: false },
    });
  };

  const startSurvey = (selectedSurveys: string[]) => {
    navigate(Routes.Survey, {
      state: { selectedSurveys, isSurveyModalOpen: false },
    });
  };

  return {
    isSurveyModalOpen,
    openSurveyModal,
    closeSurveyModal,
    selectedSurveys,
    startSurvey,
  };
};
