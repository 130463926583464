import { type User } from "utils/context/Auth/user";
import { emailValidation, passwordValidation } from "utils/validation/utils";
import { z } from "zod";

export const signInScheme = z.object({
  email: emailValidation(),
  password: passwordValidation("Password"),
});

export type SignInFormScheme = z.infer<typeof signInScheme>;

export type SignInResponse = User;
