import { ReactNode, memo } from "react";
import { ListItem, styled, ListItemProps, Box } from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Typography } from "theme";
import { CheckMark } from "theme/icons";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",

  [`&.${autocompleteClasses.option}`]: {
    padding: theme.spacing(1, 1.5),

    "&[aria-selected='true']": {
      background: "none",
    },

    [`&.${autocompleteClasses.focused}, &[aria-selected='true'].${autocompleteClasses.focused}`]: {
      backgroundColor: theme.palette.secondary.light,
      cursor: "pointer",
    },
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "block",
  marginTop: theme.spacing(-0.25),
  marginBottom: theme.spacing(-0.25),

  "& svg": {
    display: "block",
  },
}));

type DropdownOptionProps = ListItemProps & {
  icon?: ReactNode;
  label: ReactNode;
  note?: string;
  selected: boolean;
};

const DropdownOption = memo(
  ({ icon, label, note, selected, ...listItemProps }: DropdownOptionProps) => {
    return (
      <StyledListItem {...listItemProps}>
        {icon && <IconWrapper mr={0.5}>{icon}</IconWrapper>}

        <Typography flexGrow={1} overflow="hidden" textOverflow="ellipsis">
          <Typography variant="body2" component="span" color="primary" noWrap>
            {label}
          </Typography>

          {note && (
            <Typography variant="body2" component="span" color="placeholder" ml={1.25}>
              {note}
            </Typography>
          )}
        </Typography>

        {selected && (
          <IconWrapper color="success.main">
            <CheckMark />
          </IconWrapper>
        )}
      </StyledListItem>
    );
  },
);

export default DropdownOption;
