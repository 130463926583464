import { useEffect, useRef, useState } from "react";
import { Grid, GridProps, Link, styled } from "@mui/material";
import { Typography } from "theme";

type ShowMoreContainerProps = {
  children: React.ReactNode;
  lines: string;
};

const Container = styled(Grid, {
  shouldForwardProp: (propName: string) => !["showMore", "lines"].includes(propName),
})<GridProps & { showMore: boolean; lines: string }>(({ showMore, lines }) => ({
  whiteSpace: "pre-line",
  wordBreak: "break-word",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: showMore ? lines : "none",
  WebkitBoxOrient: "vertical",

  "@media print": {
    WebkitLineClamp: "unset !important",

    "& + *": {
      display: "none",
    },
  },
}));

const ShowMoreContainer: React.FC<ShowMoreContainerProps> = ({ children, lines }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    if (ref.current) {
      if (ref.current.scrollHeight > ref.current.clientHeight) {
        setShowMore(true);
      } else if (ref.current.clientHeight === ref.current.scrollHeight) {
        setShowMore(false);
      }
    }
  }, [setShowMore]);

  return (
    <>
      <Container ref={ref} showMore={showMore} lines={lines}>
        {children}
      </Container>
      {showMore && (
        <Link component="button" underline="none" onClick={() => setShowMore(false)}>
          <Typography variant="body2" weight="medium">
            Show more
          </Typography>
        </Link>
      )}
    </>
  );
};

export default ShowMoreContainer;
