import { memo } from "react";
import { Grid } from "@mui/material";
import { Typography } from "theme";
import { InfoFill } from "theme/icons";

const DropdownNoOptions = memo(() => (
  <Grid container alignItems="center" justifyContent="center" columnGap={1}>
    <InfoFill />

    <Typography color="secondary" variant="body2">
      No results found
    </Typography>
  </Grid>
));

export default DropdownNoOptions;
