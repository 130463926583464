import { Navigate } from "react-router-dom";
import { useAuth } from "utils/context/Auth/AuthContext";
import { Routes } from "./routes";

type PrivateRouteProps = {
  children: React.ReactNode;
};

export const AuthRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const auth = useAuth();

  if (auth && auth.isAuthenticated && auth.user) {
    const isCompleted = auth.user?.isCompleted;
    const isTechnician = auth.isTechnician;

    if (!isCompleted && isTechnician) return <Navigate to={Routes.ProfileCreation} replace />;

    return <Navigate to={isTechnician ? Routes.MyProfile : Routes.Root} replace />;
  }

  return <>{children}</>;
};
