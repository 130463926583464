import { FC } from "react";
import { Grid, styled } from "@mui/material";
import { Questionnaire, options } from "api/hooks/useSurvey/type";
import { useController } from "react-hook-form";
import { SelectionGroup, Typography } from "theme";

type QuestionsListItemProps = {
  question: Questionnaire;
  index: number;
};

const Counter = styled(Grid)(({ theme }) => ({
  width: theme.spacing(6),
  fontVariantNumeric: "tabular-nums",
  letterSpacing: -0.4,
  textAlign: "right",
}));

const getCounterLabel = (index: number) => {
  return `Q${(index + 1).toString().padStart(2, "0")}.`;
};

const QuestionsListItem: FC<QuestionsListItemProps> = ({ question: { id, text }, index }) => {
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name: id,
  });

  return (
    <Grid key={id} container wrap="nowrap" gap={1.5} py={0.25} component="li" alignItems="center">
      <Counter item flexShrink={0}>
        <Typography component="div" variant="body2" color="secondary">
          {getCounterLabel(index)}
        </Typography>
      </Counter>
      <Grid item flexGrow={1} flexShrink={1}>
        <Typography component="div" variant="body2" color="primary">
          {text}
        </Typography>
      </Grid>

      <Grid item flexShrink={0}>
        <SelectionGroup
          name={field.name}
          type="radio"
          options={options}
          value={field.value}
          onChange={field.onChange}
          direction="row"
          disabled={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

export default QuestionsListItem;
