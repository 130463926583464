import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ProfileQueryValues, SearchParamKey } from "utils/helpers";

export type QueryObject = Record<string, string[]>;

const allowedParams = [SearchParamKey, ...Object.values(ProfileQueryValues)];

const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!allowedParams || !searchParams.toString()) {
      return;
    }

    const paramsKeys = [...new Set(searchParams.keys())];
    let hasDeletedKeys = false;

    paramsKeys.forEach((key) => {
      if (!allowedParams.includes(key)) {
        searchParams.delete(key);
        hasDeletedKeys = true;
      }
    });

    if (hasDeletedKeys) {
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  const setSearch = (value: string | null) => {
    setSearchParams((params) => {
      if (value) {
        params.set(SearchParamKey, value);
      } else {
        params.delete(SearchParamKey);
      }

      return params;
    });
  };

  const filter = useMemo(() => {
    const paramsKeys = [...new Set(searchParams.keys())];

    return paramsKeys.reduce<QueryObject>((acc, key) => {
      if ((allowedParams && !allowedParams.includes(key)) || key === SearchParamKey) {
        return acc;
      }

      acc[key] = [...new Set(searchParams.getAll(key))];

      return acc;
    }, {});
  }, [searchParams]);

  const updateFilterParams = useCallback(
    (newFilter: QueryObject) => {
      const updatedSearchParams = new URLSearchParams();

      // added `search` to URLSearchParams to make sure the search param will be first
      const search = searchParams.get(SearchParamKey);
      if (search) {
        updatedSearchParams.set(SearchParamKey, search);
      }

      for (const [key, value] of Object.entries(newFilter)) {
        if (allowedParams && !allowedParams.includes(key)) {
          continue;
        }

        const isValueArray = Array.isArray(value);

        if (isValueArray && value?.length) {
          value?.forEach((v) => {
            updatedSearchParams.append(key, v);
          });
        } else if (!isValueArray && value) {
          updatedSearchParams.append(key, value);
        }
      }

      setSearchParams(updatedSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const clearFilterParams = useCallback(
    (key: string, value?: string) => {
      // @ts-ignore
      searchParams.delete(key, value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const clearAllFilterParams = useCallback(() => {
    const newParams = new URLSearchParams();

    const search = searchParams.get(SearchParamKey);
    if (search) {
      newParams.set(SearchParamKey, search);
    }

    setSearchParams(newParams);
  }, [searchParams, setSearchParams]);

  return {
    searchParams,
    search: searchParams.get(SearchParamKey),
    setSearch,
    filter,
    updateFilterParams,
    clearFilterParams,
    clearAllFilterParams,
  };
};

export default useFilter;
