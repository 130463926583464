import { FC } from "react";
import { styled } from "@mui/material";
import { TextField } from "theme";
import { TextFieldProps } from "theme/components/FormInputs/TextField";
import { Calendar } from "theme/icons";
import DatepickerPopper from "./components/DatepickerPopper";
import StyledDatepicker from "./components/StyledDatepicker";

export type DatepickerProps = {
  value: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
} & Pick<TextFieldProps, "label" | "required" | "name">;

const OpenPickerIcon = styled(Calendar)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const Datepicker: FC<DatepickerProps> = ({ value, onChange, minDate, maxDate, ...props }) => {
  return (
    <StyledDatepicker
      slots={{
        // @ts-expect-error
        textField: TextField,
        popper: DatepickerPopper,
        openPickerIcon: OpenPickerIcon,
      }}
      slotProps={{
        textField: { placeholder: "month, year", disabled: true, ...props },
      }}
      value={value}
      minDate={minDate || null}
      maxDate={maxDate || null}
      format="MMMM, yyyy"
      onChange={(newDate) => onChange(newDate as Date)}
      views={["month", "year"]}
    />
  );
};

export default Datepicker;
