import {
  createRef,
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce } from "../helpers";

const useWrappedElement = <T>(list: T[] = []) => {
  const listLength = list.length;

  const listRefs = useRef<MutableRefObject<HTMLLIElement | null>[]>([]);
  const [wrappedElem, setWrappedElem] = useState<null | number>(null);

  if (listRefs.current.length !== listLength) {
    listRefs.current = Array(listLength)
      .fill(null)
      .map((_, i) => listRefs.current[i] || createRef());
  }

  const handleListWrap = useCallback(() => {
    const wrappedItems = [];
    let prevItem = {};
    let currItem = {};

    for (let i = 0; i < listRefs.current.length; i++) {
      currItem = listRefs.current?.[i]?.current?.getBoundingClientRect() ?? {};
      if (
        prevItem instanceof DOMRect &&
        currItem instanceof DOMRect &&
        prevItem["top"] < currItem["top"]
      ) {
        wrappedItems.push(i);
      }
      prevItem = currItem;
    }

    if (wrappedItems?.[0]) {
      setWrappedElem(wrappedItems?.[0] - 1);
    }
  }, []);

  useLayoutEffect(() => {
    handleListWrap();
    const onResize = debounce(() => handleListWrap(), 60);

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [handleListWrap]);

  const hiddenElementsCount = useMemo(
    () => (wrappedElem ? listLength - wrappedElem : 0),
    [listLength, wrappedElem],
  );

  const wrappedList = useMemo(
    () => list.slice(0, wrappedElem ? wrappedElem : listLength),
    [list, listLength, wrappedElem],
  );

  return {
    list: wrappedList,
    listRefs,
    hiddenElementsCount,
  };
};

export default useWrappedElement;
