import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { useAuth } from "utils/context/Auth/AuthContext";
import { getFullname } from "utils/helpers/getFullname";
import { useFetch } from "utils/hooks";
import { ResponseError } from "utils/hooks/useFetch";
import SignUpFields from "./components/SignUpFields";
import SignUpSubmit from "./components/SignUpSubmit";
import { SignUpFormScheme, SignUpRequest, SignUpResponse, signUpScheme } from "./validation";

const SignUp = () => {
  const request = useFetch();
  const navigate = useNavigate();
  const { invitationToken = "" } = useParams();
  const { isInvitationModeEnabled } = useAuth();

  const [serverError, setServerError] = useState<ResponseError | null>(null);

  const shouldAddInvitationTokeParam = invitationToken && isInvitationModeEnabled;

  const methods = useForm<SignUpFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(signUpScheme),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      company: "",
    },
  });

  const { mutate: registerUser, isPending } = useMutation({
    mutationFn: (userData: SignUpRequest) => {
      const searchParams = new URLSearchParams("");
      if (invitationToken) {
        searchParams.set("invitationToken", invitationToken);
      }

      return request<SignUpResponse>(
        `${Urls.Accounts}${shouldAddInvitationTokeParam ? `?${searchParams.toString()}` : ""}`,
        {
          method: "POST",
          body: JSON.stringify(userData),
        },
      );
    },
    onMutate: () => {
      setServerError(null);
    },
    onSuccess: () => {
      navigate(Routes.SignUp, {
        state: {
          fullName: getFullname(methods.getValues("firstName"), methods.getValues("lastName")),
          email: methods.getValues("email"),
        },
      });
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
  });

  const { mutate: resendEmail, isPending: isResendEmailLoading } = useMutation({
    mutationFn: () => {
      return request(Urls.ResendEmail, {
        method: "POST",
        body: JSON.stringify({ email: methods.getValues("email") }),
      });
    },
    onSuccess: () => {
      navigate(Routes.SignUp, {
        state: {
          fullName: getFullname(methods.getValues("firstName"), methods.getValues("lastName")),
          email: methods.getValues("email"),
        },
      });
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
  });

  const isSubmitting = isPending || isResendEmailLoading;

  return (
    <FormProvider {...methods}>
      <Grid container rowSpacing={4}>
        <Grid item flexGrow={1}>
          <SignUpFields
            registerUser={registerUser}
            resendEmail={resendEmail}
            isSubmitting={isSubmitting}
            serverError={serverError}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <SignUpSubmit isSubmitting={isSubmitting} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default SignUp;
