import { FC, ReactElement } from "react";
import {
  BoxProps,
  styled,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps as MuiTooltipProps,
} from "@mui/material";
import { Typography } from "theme";

type TooltipProps = {
  children: ReactElement;
  title: string;
} & Pick<BoxProps, "mb"> &
  Pick<MuiTooltipProps, "placement">;

const StyledTooltip = styled(
  ({ className, ...props }: MuiTooltipProps & Pick<TooltipProps, "mb">) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (propName) => propName !== "mb",
  },
)(({ theme, mb }) => ({
  [`& > .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(0.75, 1.5),
    borderRadius: theme.shape.borderRadius,
  },

  "&.MuiTooltip-popper > .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop": {
    marginBottom: mb || theme.spacing(1),
  },

  "@media print": {
    display: "none",
  },
}));

const Tooltip: FC<TooltipProps> = ({ children, title, placement = "top", ...props }) => {
  return (
    <StyledTooltip
      placement={placement}
      title={
        <Typography variant="body2" color="inverse">
          {title}
        </Typography>
      }
      {...props}
    >
      <span>{children}</span>
    </StyledTooltip>
  );
};

export default Tooltip;
