import { Grid } from "@mui/material";
import { Tag } from "theme";
import { Clear } from "theme/icons";

export type Item = {
  id: string;
  text: string;
};

type TagsSectionProps = {
  onDelete: (items: string[]) => void;
  items: Item[];
  isDeleting?: boolean;
  readOnly?: boolean;
};

const TagsSection: React.FC<TagsSectionProps> = ({
  onDelete,
  items,
  readOnly = false,
  isDeleting = false,
}) => {
  const removedItem = (id: string) => items.filter((item) => item.id !== id).map((item) => item.id);

  return (
    <Grid container gap={2}>
      {items.map((item) => (
        <Tag
          key={item.id}
          label={item.text}
          deleteIcon={<Clear />}
          onDelete={readOnly ? undefined : () => onDelete(removedItem(item.id))}
          disabled={isDeleting}
        />
      ))}
    </Grid>
  );
};

export default TagsSection;
