import React from "react";
import { Grid, styled } from "@mui/material";
import { Typography } from "theme";
import { getNickname } from "utils/helpers/getFullname";

const DescriptionWrap = styled(Grid)(({ theme }) => {
  return {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "fit-content(100%) minmax(auto, auto)",
    alignItems: "baseline",
    columnGap: theme.spacing(1),
    overflow: "hidden",
  };
});

type UserNameProps = {
  name?: string;
  nickname?: string;
  size?: "sm" | "md";
};

export const UserName: React.FC<UserNameProps> = ({ name, nickname, size = "sm" }) => {
  return (
    <DescriptionWrap>
      <Typography
        variant={size === "sm" ? "h3" : "h2"}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {name}
      </Typography>
      {nickname && (
        <Typography
          variant="h4"
          color="secondary"
          weight="regular"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {getNickname(nickname)}
        </Typography>
      )}
    </DescriptionWrap>
  );
};
