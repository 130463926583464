import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { z } from "zod";

export const EditPersonalInfoScheme = z.object({
  firstName: z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(250, { message: getErrorMessage("First name", ErrorMessage.FieldTooLong) }),
  lastName: z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(250, { message: getErrorMessage("Last name", ErrorMessage.FieldTooLong) }),
  nickname: z
    .string()
    .max(250, { message: getErrorMessage("Nickname", ErrorMessage.FieldTooLong) }),
  expertise: z.array(z.string()),
  location: z
    .object({
      adminCode: z.string(),
      adminName: z.string(),
      cityName: z.string(),
      countryCode: z.string(),
      countryName: z.string(),
      geoNameId: z.string(),
    })
    .nullable(),
  travel: z.array(z.string()),
});

export type EditPersonalInfoFormScheme = z.infer<typeof EditPersonalInfoScheme>;
