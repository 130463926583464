import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { useMutateAccount } from "api/hooks";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "theme";
import { useAuth } from "utils/context/Auth/AuthContext";
import { Roles } from "utils/context/Auth/user";
import { useError } from "utils/hooks";
import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { z } from "zod";
import { ErrorsAlert } from "../components/ErrorsAlert/ErrorsAlert";

const accountSettingsScheme = z.object({
  firstName: z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(250, { message: getErrorMessage("First name", ErrorMessage.FieldTooLong) }),

  lastName: z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(250, { message: getErrorMessage("Last name", ErrorMessage.FieldTooLong) }),

  company: z
    .string()
    .max(250, { message: getErrorMessage("Company", ErrorMessage.FieldTooLong) })
    .optional(),
});

type AccountSettingsForm = z.infer<typeof accountSettingsScheme>;

const ProfileInfo = () => {
  const { user } = useAuth();
  const isLaborCoordinator = user?.role === Roles.LaborCoordinator;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AccountSettingsForm>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(accountSettingsScheme),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      company: user?.company,
    },
  });

  const { isError, errorsList, setServerError } = useError(errors);

  const {
    account: { mutate: updateAccount, isPending: isAccountLoading },
  } = useMutateAccount(setServerError);

  const handlePersonalInfoSubmit: SubmitHandler<AccountSettingsForm> = (data) => {
    updateAccount(data);
  };

  return (
    <Grid
      component="form"
      id="profile-info-form"
      noValidate
      autoComplete="off"
      container
      flexDirection="column"
      rowGap={2.5}
      onSubmit={handleSubmit(handlePersonalInfoSubmit)}
    >
      <Grid item>
        <ControlledTextField
          control={control}
          name="firstName"
          label="First name"
          required
          placeholder="Enter your first name"
        />
      </Grid>
      <Grid item>
        <ControlledTextField
          control={control}
          name="lastName"
          label="Last name"
          required
          placeholder="Enter your last name"
          loading={isAccountLoading}
        />
      </Grid>
      {isLaborCoordinator && (
        <Grid item>
          <ControlledTextField
            control={control}
            name="company"
            label="Company"
            placeholder="Enter your company name"
            loading={isAccountLoading}
          />
        </Grid>
      )}
      {isError && (
        <Grid item>
          <ErrorsAlert message={errorsList} />
        </Grid>
      )}
      <Grid item>
        <Button variant="contained" color="secondary" type="submit" loading={isAccountLoading}>
          {isAccountLoading ? "Saving..." : "Update personal info"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProfileInfo;
