import { useMemo } from "react";
import StepperLayout from "components/Layout/StepperLayout";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Routes } from "router";
import { useAuth } from "utils/context/Auth/AuthContext";

const getStep = (pathname: string) => {
  return pathname.split("/").pop();
};

const ProfileCreation = () => {
  const { isTechnician, user } = useAuth();
  const location = useLocation();

  const stepName = getStep(location.pathname);

  const stepNumber = useMemo(
    () => ({
      [Routes.ProfileInfo]: 1,
      [Routes.SkillsAndCertifications]: 2,
      [Routes.SummaryAndWorkExperience]: 3,
    }),
    [],
  );

  const stepTitles = useMemo(
    () => ({
      [Routes.ProfileInfo]: "Great to have you!",
      [Routes.SkillsAndCertifications]: "Tell us more about your skillset",
      [Routes.SummaryAndWorkExperience]: "Tell us more about your experience",
    }),
    [],
  );

  const stepDescriptions = useMemo(
    () => ({
      [Routes.ProfileInfo]:
        "It's time to expand your profile by adding more information about yourself.",
      [Routes.SkillsAndCertifications]: "Making your path to a gig easier.",
      [Routes.SummaryAndWorkExperience]: "Making your path to a gig easier.",
    }),
    [],
  );

  const step = stepNumber[stepName as keyof typeof stepNumber];

  if (user?.isCompleted && user?.profileId) {
    return <Navigate to={Routes.MyProfile} replace />;
  }

  if (!isTechnician) {
    return <Navigate to={Routes.Root} replace />;
  }

  if (!step) {
    return <Navigate to={Routes.ProfileCreationProfileInfo} replace />;
  }

  return (
    <StepperLayout
      steps={["Personal Info", "Skills & Certifications", "Summary & Work experience"]}
      step={step}
      title={stepTitles[stepName as keyof typeof stepTitles] || ""}
      description={stepDescriptions[stepName as keyof typeof stepDescriptions] || ""}
    >
      <Outlet />
    </StepperLayout>
  );
};

export default ProfileCreation;
