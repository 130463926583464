import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "router";
import { useAuth } from "utils/context/Auth/AuthContext";
import { ServerErrorCode, ServerErrorMessage, serverErrorMap } from "utils/validation/errors";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

const responseErrorScheme = z.object({
  errors: z
    .array(
      z.object({
        code: z.nativeEnum(ServerErrorCode),
        message: z.string().nonempty().nullable(),
      }),
    )
    .min(1)
    .transform((errorArray) => ({
      code: errorArray[0].code,
      message: errorArray[0].message,
    })),
});

export const CacheTime = 60 * 1000; // 1 min
export const ExtendedCacheTime = 5 * 60 * 1000; // 5 min

const host = process.env.REACT_APP_SERVER_HOST;
const authKey = process.env.REACT_APP_SERVER_AUTH_KEY;

export class ResponseError extends Error {
  status: number;
  errorCode: ServerErrorCode = ServerErrorCode.NotFound;
  errorMessage: ServerErrorMessage = serverErrorMap(this.errorCode);

  constructor(status: number, message?: string) {
    super(message);
    this.status = status;

    if (!message) {
      return;
    }

    const jsonError = this.parseErrorMessageToJson(message);
    const parsedError = responseErrorScheme.safeParse(jsonError);

    if (parsedError.success) {
      const error = parsedError.data.errors;
      this.errorCode = error.code;
      this.errorMessage = serverErrorMap(this.errorCode);
    }
  }

  private parseErrorMessageToJson(message: string): unknown | null {
    try {
      const parsedError = JSON.parse(message);
      return parsedError;
    } catch {
      return null;
    }
  }
}

type FetchSlugParams = Record<string, string | undefined | null>;

interface FetchParams<T extends BodyInit> {
  method: Method;
  headers?: Headers;
  body?: T;
  slug?: FetchSlugParams;
}

const defaultParams = <T extends BodyInit>(): FetchParams<T> => ({
  method: "GET",
  headers: new Headers({
    "Content-Type": "application/json; charset=utf-8",
  }),
});

const setHeaders = <T extends BodyInit>(params: FetchParams<T>, token?: string | null) => {
  const headers = new Headers(params.headers);

  headers.append("Request-Id", uuidv4());

  if (token) {
    headers.append("Authorization", token);
  }

  return headers;
};

const setUrl = (url: string | URL) => {
  const fullUrl = new URL(`/api/v1${url}`, host);

  if (authKey) {
    fullUrl.searchParams.append("code", authKey);
  }

  return fullUrl;
};

const applySlugParams = (url: string, slugParams?: FetchSlugParams) => {
  const paramPlaceholders = url.match(/:[a-zA-Z_][a-zA-Z0-9_]*/g) || [];

  for (const placeholder of paramPlaceholders) {
    const paramName = placeholder.slice(1);

    if (!slugParams || !slugParams[paramName]) {
      throw new Error(`Parameter '${paramName}' is required for this URL`);
    }

    url = url.replace(placeholder, slugParams[paramName] as string);
  }

  return url;
};

export const request = <T extends BodyInit = BodyInit>(
  requestUrl: string,
  token: string | null,
  params: FetchParams<T> = defaultParams<T>(),
): Promise<Response> => {
  const prepareUrl = applySlugParams(requestUrl, params.slug);
  const url = setUrl(prepareUrl);
  const headers = setHeaders(params, token);

  return fetch(url.href, {
    ...params,
    headers,
  }).catch(() => {
    throw new ResponseError(500);
  });
};

const useFetch = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return async <R, T extends BodyInit = BodyInit>(
    requestUrl: string,
    params: FetchParams<T> = defaultParams<T>(),
  ): Promise<R | null> => {
    const response = await request<T>(requestUrl, auth?.token, params);

    if (!response.ok) {
      switch (response.status) {
        case 401:
          auth?.clearToken();
          navigate(Routes.SignIn, { state: { from: location.pathname } });
          break;
        default:
          const err = await response.text();
          throw new ResponseError(response.status, err);
      }
    }

    const token = response.headers.get("Authorization");
    if (!auth?.isAuthenticated && token) {
      auth?.setToken(token);
    }

    try {
      const json = await response.json();
      return json;
    } catch {
      return null;
    }
  };
};

export default useFetch;
