import { useCallback, useState } from "react";
import { Grid, Divider, styled } from "@mui/material";
import { useMutateProfile, useProfile } from "api/hooks";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import ProfileCreatedModal from "components/Modals/ProfileCreatedModal";
import WorkExperienceModal, {
  WORK_EXPERIENCE_DEFAULT_VALUES,
} from "components/Modals/WorkExperienceModal/WorkExperienceModal";
import { WorkExperienceFormScheme } from "components/Modals/WorkExperienceModal/validation";
import WorkExperience from "components/WorkExperience/WorkExperience";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Typography, Button } from "theme";
import { ArrowLeft } from "theme/icons";
import { useError } from "utils/hooks";
import useSummaryForm, { SummaryFormOptionalScheme } from "utils/hooks/form/useSummaryForm";
import { handlePromiseError } from "../../../utils/helpers";

const ServerErrorWrapper = styled(Grid)(() => ({
  position: "absolute",
  maxWidth: "26rem",
  top: 0,
  right: "50%",
  transform: "translate(50%)",
}));

const SummaryAndWorkExperience = () => {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const [openAddWorkExperienceModal, setOpenAddWorkExperienceModal] = useState(false);
  const [openEditWorkExperienceModal, setOpenEditWorkExperienceModal] = useState(false);

  const [defaultValues, setDefaultValues] = useState<WorkExperienceFormScheme>(
    WORK_EXPERIENCE_DEFAULT_VALUES,
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useSummaryForm({ summary: profile?.summary || "", required: false });

  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const { isFormError, errorsList, isServerError, setServerError } = useError(errors);
  const {
    summary: { mutateAsync: updateSummary, isError: isCompleteError, isPending: isSummaryLoading },
    completeProfile: { isPending: isCompleteLoading, mutateAsync: completeProfile },
  } = useMutateProfile(setServerError);

  const onGoBack = useCallback(() => {
    navigate(Routes.ProfileCreationSkillsAndCertifications);
  }, [navigate]);

  const onSubmit: SubmitHandler<SummaryFormOptionalScheme> = async (data) => {
    await handlePromiseError(() => updateSummary(data.summary));
    await handlePromiseError(() => completeProfile());
    if (!isCompleteError) {
      setShowCompleteModal(true);
    }
  };

  const isPending = isCompleteLoading || isSummaryLoading;

  return (
    <>
      <ProfileCreatedModal open={showCompleteModal} />
      <Grid
        container
        component="form"
        id="3-step-form"
        noValidate
        autoComplete="off"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item container flexDirection="column" rowGap={4}>
          <Grid item container flexDirection="column" rowGap={2.5}>
            <Grid item>
              <Typography variant="h5">Summary</Typography>
              <Typography variant="body2" mt={0.5} color="secondary">
                Give us a brief overview of your professional path and areas of expertise.
              </Typography>
            </Grid>
            <Grid item>
              <ControlledTextField
                control={control}
                name="summary"
                rows={4}
                placeholder="Share a few words about your background"
                required
                multiline
              />
            </Grid>
            {isFormError && (
              <Grid item>
                <ErrorsAlert message={errorsList} />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <WorkExperience
              onEditWorkExperience={(data) => {
                setOpenEditWorkExperienceModal(true);
                if (data) {
                  setDefaultValues(data);
                }
              }}
              onAddWorkExperience={() => setOpenAddWorkExperienceModal(true)}
              unwraped
            />
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
          <Grid
            container
            my={2}
            position="relative"
            justifyContent="end"
            alignItems="center"
            columnGap={1}
          >
            {isServerError && (
              <ServerErrorWrapper>
                <ErrorsAlert message={errorsList} />
              </ServerErrorWrapper>
            )}
            <Grid item>
              <Grid container columnGap={2}>
                <Button variant="contained" startIcon={<ArrowLeft />} onClick={onGoBack}>
                  Go back
                </Button>
                <Button type="submit" color="primary" variant="contained" loading={isPending}>
                  {isPending ? "Saving..." : "Complete profile"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <WorkExperienceModal
        key={`${openAddWorkExperienceModal}`}
        defaultValues={WORK_EXPERIENCE_DEFAULT_VALUES}
        open={openAddWorkExperienceModal}
        onClose={() => setOpenAddWorkExperienceModal(false)}
        title="Add new Show"
      />
      <WorkExperienceModal
        formId="work-experience-form-edit"
        key={`${defaultValues?.id}${openEditWorkExperienceModal}`}
        open={openEditWorkExperienceModal}
        onClose={() => setOpenEditWorkExperienceModal(false)}
        title="Edit Show"
        defaultValues={defaultValues}
      />
    </>
  );
};

export default SummaryAndWorkExperience;
