import { useEffect, useState } from "react";
import { FieldErrors } from "react-hook-form";
import { ResponseError } from "./useFetch";

const useError = (
  errors:
    | {
        [key: string]: {
          message?: string;
        };
      }
    | FieldErrors,
): {
  isError: boolean;
  errorsList: ResponseError | string[] | string;
  setServerError: (err: ResponseError | null) => void;
  isServerError: boolean;
  isFormError: boolean;
} => {
  const [serverError, setServerError] = useState<ResponseError | null>(null);

  const formErrors = Object.values(errors)
    .map((err) => err?.message)
    .filter(Boolean) as string[];
  const formErrorsSet = Array.from(new Set([...formErrors]));
  const formErrorsList = formErrorsSet.length > 0 ? formErrorsSet : null;

  const isFormError = formErrorsList !== null;
  const isServerError = serverError !== null;

  useEffect(() => {
    if (formErrorsList) {
      setServerError(null);
    }
  }, [formErrorsList, setServerError]);

  return {
    isError: isFormError || isServerError,
    isFormError,
    errorsList: formErrorsList || serverError || "",
    setServerError,
    isServerError,
  };
};

export default useError;
