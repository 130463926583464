import { Popper, styled } from "@mui/material";

const DatepickerPopper = styled(Popper)(({ theme }) => ({
  zIndex: 2000,
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
    boxShadow: theme.shadows[3],
    width: "100%",
  },

  "& .MuiDateCalendar-root": {
    height: "fit-content",
  },

  "& .MuiPickersCalendarHeader-root": {
    "& .MuiPickersArrowSwitcher-root": {
      display: "none",
    },

    "& .MuiPickersCalendarHeader-labelContainer": {
      ...theme.typography.body1,
      margin: "0 auto",
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1, 3),

      "&:hover": {
        backgroundColor: theme.palette.background.primary,
      },
    },
  },

  "& .MuiPickersFadeTransitionGroup-root": {
    "& .MuiMonthCalendar-root, & .MuiPickersYear-root": {
      padding: 0,
      marginBottom: theme.spacing(1),
    },

    "& .MuiPickersYear-root": {
      flexBasis: "33.3%",
    },

    "& .MuiPickersMonth-monthButton, & .MuiPickersYear-yearButton": {
      ...theme.typography.body2,
      padding: theme.spacing(1.25, 2),
      margin: 0,
      borderRadius: 0,
      width: "100%",

      "&:hover": {
        backgroundColor: theme.palette.background.primary,
      },

      "&[aria-checked='true']": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,

        "&:hover": {
          backgroundColor: theme.palette.background.primary,
        },
      },

      "&:active": {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.text.inverse,

        "&[aria-checked='true']": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

export default DatepickerPopper;
