import { FC, memo, ReactNode } from "react";
import { useTheme } from "@mui/material";
import { TypeBackground } from "@mui/material/styles/createPalette";

const RADIUS = 24;
const MAX_VALUE = 5;
const STROKE_WIDTH = 3;

export type CircularProgressBarProps = {
  selectedValue: number;
  activeColor: string;
  backgroundColor?: keyof TypeBackground;
  icon: ReactNode;
};

const CircularProgressBar: FC<CircularProgressBarProps> = memo(
  ({ selectedValue, activeColor, icon, backgroundColor = "default" }) => {
    const {
      palette: { background },
    } = useTheme();

    const generatePie = (value: number) => {
      const x = RADIUS - Math.cos((2 * Math.PI) / (100 / value)) * RADIUS;
      const y = RADIUS + Math.sin((2 * Math.PI) / (100 / value)) * RADIUS;
      const long = value <= 50 ? 0 : 1;

      return `M${RADIUS} ${RADIUS} L${RADIUS} ${0} A${RADIUS} ${RADIUS} 0 ${long} 1 ${y} ${x} Z`;
    };

    // PIE Area calculation
    const calculatePieValue = (numberOfBars: number) => {
      const angle = 360 / numberOfBars;
      const pieValue = Math.floor(angle / 4);
      return pieValue < 1 ? 1 : Math.floor(angle / 4);
    };

    const renderPie = (i: number) => {
      const direction = -1;
      // Rotation Calculation
      const primaryRotationAngle = (MAX_VALUE - 1) * (360 / MAX_VALUE);
      const rotationAngle =
        -1 * direction * primaryRotationAngle + i * direction * primaryRotationAngle;
      const rotationTransformation = `rotate(${rotationAngle}, ${RADIUS}, ${RADIUS})`;

      const pieValue = calculatePieValue(MAX_VALUE);
      const dValue = generatePie(pieValue);

      const fillColor = selectedValue > 0 && i <= selectedValue ? activeColor : background.chart;

      return (
        <path
          style={{ opacity: i === 0 ? 0 : 1 }}
          key={i}
          d={dValue}
          fill={fillColor}
          transform={rotationTransformation}
        />
      );
    };

    // Creates a circle by combining the Pie(s)
    const renderOuterCircle = () => [...Array(MAX_VALUE + 1)].map((_, i) => renderPie(i));

    // MAIN Render
    return (
      <svg width={RADIUS * 2} height={RADIUS * 2} color="inherit">
        {renderOuterCircle()}

        <circle
          className="progress-bar-circle"
          r={RADIUS - STROKE_WIDTH}
          cx={RADIUS}
          cy={RADIUS}
          fill={background[backgroundColor]}
        />

        <svg x={RADIUS / 2} y={RADIUS / 2}>
          {icon}
        </svg>
      </svg>
    );
  },
);

export default CircularProgressBar;
