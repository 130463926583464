import { memo } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Typography } from "theme";

const DropdownLoader = memo(() => (
  <Grid container alignItems="center" justifyContent="center" columnGap={1}>
    <Grid item>
      <CircularProgress size={24} color="primary" />
    </Grid>
    <Grid item>
      <Typography color="secondary" variant="body2">
        Loading
      </Typography>
    </Grid>
  </Grid>
));

export default DropdownLoader;
