import { Grid } from "@mui/material";
import { ReactComponent as CrewLinkLogoDark } from "assets/logo/crew-link-logo-dark.svg";
import { ReactComponent as CrewLinkLogoLight } from "assets/logo/crew-link-logo-light.svg";
import { ReactComponent as CrewLinkNameDark } from "assets/logo/crew-link-name-dark.svg";
import { ReactComponent as CrewLinkNameLight } from "assets/logo/crew-link-name-light.svg";

type LogoProps = {
  className?: string;
  mode: "light" | "dark";
};

const Logo: React.FC<LogoProps> = ({ mode, className }) => {
  return (
    <Grid container alignItems="center" columnGap={1.25} className={className}>
      {mode === "light" ? <CrewLinkLogoLight /> : <CrewLinkLogoDark />}
      {mode === "light" ? <CrewLinkNameLight /> : <CrewLinkNameDark />}
    </Grid>
  );
};

export default Logo;
