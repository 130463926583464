import { useMemo } from "react";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { Urls } from "api/urls";
import { useFetch, useFilter } from "utils/hooks";
import { CacheTime, ResponseError } from "utils/hooks/useFetch";
import { QueryObject } from "utils/hooks/useFilter";
import { PaginatedProfilesSearch, getFilters } from "./type";

export const ITEMS_PER_PAGE = 7;

const useInfiniteSearch = () => {
  const { search, filter } = useFilter();
  const fetch = useFetch();

  const infiniteProfilesSearch = useInfiniteQuery<
    PaginatedProfilesSearch | null,
    ResponseError,
    InfiniteData<PaginatedProfilesSearch | null>,
    [string, string | null, QueryObject],
    number
  >({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["profile-infinite-search", search, filter],
    queryFn: ({ pageParam }) => {
      const body = {
        searchText: search || "*",
        currentPage: pageParam,
        filters: getFilters(filter),
      };

      return fetch<PaginatedProfilesSearch>(Urls.ProfilesSearch, {
        method: "POST",
        body: JSON.stringify(body),
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      const totalPages = lastPage?.totalProfiles && lastPage.totalProfiles / ITEMS_PER_PAGE;
      return totalPages && lastPageParam < totalPages ? lastPageParam + 1 : null;
    },
    gcTime: CacheTime,
    staleTime: CacheTime,
  });

  const nextBatchCount = useMemo(() => {
    const { pageParams, pages } = infiniteProfilesSearch.data || {};

    if (!pageParams || !pages) {
      return undefined;
    }

    const lastPageParam = pageParams[pageParams.length - 1] as number;
    const lastPage = pages[pages.length - 1];
    const totalItemsLeft = lastPage?.totalProfiles
      ? lastPage.totalProfiles - lastPageParam * ITEMS_PER_PAGE
      : null;

    if (!totalItemsLeft) {
      return undefined;
    }

    return Math.min(totalItemsLeft, ITEMS_PER_PAGE);
  }, [infiniteProfilesSearch.data]);

  return {
    infiniteProfilesSearch,
    nextBatchCount,
  };
};

export default useInfiniteSearch;
