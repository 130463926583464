import { ReactNode } from "react";
import { Box, BoxProps, Modal as MuiModal, styled } from "@mui/material";

type ModalContentIconProps = {
  bgcolor?: BoxProps["bgcolor"];
  children: ReactNode;
};

export const StyledMuiModal = styled(MuiModal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ModalCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: "small" | "medium" | "large" }>(({ theme, size }) => ({
  padding: theme.spacing(4),
  width: theme.layout.modalWidth[size],
  backgroundColor: theme.palette.text.inverse,
  borderRadius: theme.shape.borderRadius,
  margin: "0 auto",
}));

export const ModalContentIcon = styled(Box)<ModalContentIconProps>(({ theme }) => {
  return {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    width: theme.spacing(8),
    height: theme.spacing(8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& svg": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  };
});
