import { FC } from "react";
import { Button } from "theme";
import { ArrowLeft } from "theme/icons";
import { useGoBack } from "utils/hooks";

const BackButton: FC<{ fallback?: string }> = ({ fallback }) => {
  const { navigate, shouldShowBackButton } = useGoBack(fallback);

  if (!shouldShowBackButton) return null;

  return (
    <Button onClick={navigate} startIcon={<ArrowLeft />} variant="contained">
      Go Back
    </Button>
  );
};

export default BackButton;
