import SignInForm from "components/Forms/SignIn/SignIn";
import SignPageLayout from "components/Layout/SignPageLayout";
import { useLocation } from "react-router-dom";
import SignUpSuccess from "../components/Modals/SuccessModal";
import { ConfirmationEmail } from "../theme/icons/colored";

const SignIn = () => {
  const location = useLocation();

  const isUserResendEmail = Boolean(location.state?.fullName);

  if (isUserResendEmail) {
    return (
      <SignPageLayout
        icon={<ConfirmationEmail />}
        title="Confirm your email"
        description={<SignUpSuccess />}
      />
    );
  }

  return (
    <SignPageLayout
      iconType="logo"
      title="Sign In to your account"
      description="Welcome back! Please enter your details."
    >
      <SignInForm />
    </SignPageLayout>
  );
};

export default SignIn;
