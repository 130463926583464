import { FC, ReactNode } from "react";
import { Grid, GridProps } from "@mui/material";
import Label, { LabelProps } from "components/Profile/components/Label";

type ControlSectionProps = LabelProps & {
  children: ReactNode;
  m: GridProps["m"];
};

const ControlSection: FC<ControlSectionProps> = ({ children, m, ...labelProps }) => {
  return (
    <Grid container flexDirection="column" rowGap={2.5} m={m}>
      <Grid item>
        <Label {...labelProps} />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default ControlSection;
