import { FC, memo, ReactNode, useCallback, useMemo, useState } from "react";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material";
import { Tag, TextField, Highlight } from "theme";
import { Search, Clear, ChevronDown, ClearSmall } from "theme/icons";
import DropdownContainer from "./components/DropdownContainer";
import DropdownLoader from "./components/DropdownLoader";
import DropdownNoOptions from "./components/DropdownNoOptions";
import DropdownOption from "./components/DropdownOption";
import SelectedValuesContainer from "./components/SelectedValuesContainer";
import StyledAutocomplete from "./components/StyledAutocomplete";

export type AutocompleteOption = {
  value: string | number | boolean;
  label: string;
  icon?: ReactNode;
};

export type AutocompleteProps = Pick<
  MuiAutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>,
  "multiple" | "defaultValue" | "disabled"
> & {
  value: string[] | string;
  name: string;
  placeholder?: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  options: AutocompleteOption[];
  onChange?: (options: string[] | string) => void;
};

const Autocomplete: FC<AutocompleteProps> = memo(
  ({ multiple, options, value, placeholder, name, label, error, required, onChange, ...rest }) => {
    const loading = options.length === 0;

    const [inputValue, setInputValue] = useState("");

    const autocompleteValues = useMemo(() => {
      return options.filter((option) => value.includes(String(option.value)));
    }, [options, value]);

    const onDeleteValue = useCallback(
      (option: AutocompleteOption) => () => {
        if (!Array.isArray(value)) return;

        const newOptions = value.filter((v) => v !== String(option.value));
        onChange?.(newOptions);
      },
      [value, onChange],
    );

    return (
      <>
        <StyledAutocomplete
          disableCloseOnSelect
          multiple={multiple}
          options={options}
          value={multiple ? autocompleteValues : autocompleteValues?.[0]}
          renderTags={() => null}
          loading={loading}
          loadingText={<DropdownLoader />}
          noOptionsText={<DropdownNoOptions />}
          getOptionLabel={(option) => option.label || ""}
          PopperComponent={DropdownContainer}
          clearIcon={!multiple && <ClearSmall />}
          popupIcon={<ChevronDown />}
          onChange={(_, newValue) => {
            if (Array.isArray(newValue)) {
              onChange?.(newValue.map((el) => String(el.value)));
              return;
            }
            onChange?.(newValue?.value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              label={label}
              error={error}
              required={required}
              startIcon={<Search />}
              placeholder={placeholder}
              {...(multiple && {
                onChange: (e) => setInputValue(e.target.value),
              })}
            />
          )}
          renderOption={(props, option, { selected, inputValue }) => (
            <DropdownOption
              {...props}
              icon={option?.icon}
              selected={selected}
              label={<Highlight text={option.label} highlight={inputValue} />}
            />
          )}
          {...rest}
          {...(multiple && {
            inputValue,
            onClose: () => setInputValue(""),
          })}
        />
        {multiple && Boolean(autocompleteValues.length) && (
          <SelectedValuesContainer>
            {autocompleteValues?.map((option: AutocompleteOption, index: number) => (
              <Tag
                key={index}
                label={option.label}
                deleteIcon={<Clear />}
                onDelete={onDeleteValue?.(option)}
              />
            ))}
          </SelectedValuesContainer>
        )}
      </>
    );
  },
);

export default Autocomplete;
