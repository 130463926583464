import { FC } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMutateAccount, useMutateProfile } from "api/hooks";
import AvatarUploader from "components/AvatarUploader/AvatarUploader";
import ControlledLocation from "components/ControlledInputs/ControlledLocation";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import Expertise from "components/Options/Expertise";
import Travel from "components/Options/Travel";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormModal, Typography } from "theme";
import { getFirstnameLetter } from "utils/helpers/getFullname";
import { useError } from "utils/hooks";
import { FormModalProps } from "utils/types/common";
import { EditPersonalInfoFormScheme, EditPersonalInfoScheme } from "./validation";
import { handlePromiseError } from "../../../utils/helpers";
import FormControls from "../components/FormControls";

const PERSONAL_INFO_FORM_ID = "profile-info-form";

type PersonalInfoModalProps = FormModalProps & {
  defaultValues: EditPersonalInfoFormScheme;
  avatar?: string | null;
};

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(4, 0),
}));

const PersonalInfoModal: FC<PersonalInfoModalProps> = ({
  open,
  onClose,
  defaultValues,
  avatar,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditPersonalInfoFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(EditPersonalInfoScheme),
    defaultValues: defaultValues,
  });

  const { errorsList, isError, setServerError } = useError(errors);

  const {
    profileInfo: {
      mutateAsync: updateProfileInfo,
      isPending: isProfileInfoLoading,
      isError: isProfileInfoError,
    },
  } = useMutateProfile(setServerError);

  const {
    account: { mutateAsync: updateAccount, isPending: isAccountLoading, isError: isAccountError },
  } = useMutateAccount(setServerError);

  const onSubmit: SubmitHandler<EditPersonalInfoFormScheme> = async (data) => {
    await handlePromiseError(() => updateProfileInfo(data));
    await handlePromiseError(() =>
      updateAccount({
        firstName: data.firstName,
        lastName: data.lastName,
      }),
    );

    if (!isProfileInfoError && !isAccountError) {
      onClose();
    }
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      id={PERSONAL_INFO_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormModal
        actionButtons={
          <FormControls
            isPending={isProfileInfoLoading || isAccountLoading}
            formId={PERSONAL_INFO_FORM_ID}
            onClose={onClose}
          />
        }
        open={open}
        title="Edit Personal info"
        onClose={onClose}
      >
        <Grid container flexDirection="column" rowGap={2.5}>
          <Grid item>
            <AvatarUploader
              avatar={avatar}
              fallback={getFirstnameLetter(defaultValues.firstName)}
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              control={control}
              label="First Name"
              name="firstName"
              required
              type="text"
              autoComplete="off"
              placeholder="Enter your first name"
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              control={control}
              label="Last Name"
              name="lastName"
              required
              type="text"
              autoComplete="off"
              placeholder="Enter your last name"
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              control={control}
              type="text"
              autoComplete="off"
              label="Nickname"
              name="nickname"
              placeholder="Enter your nickname"
            />
          </Grid>
          <Grid item>
            <Expertise name="expertise" control={control} />
          </Grid>
          <Grid item>
            <ControlledLocation label="Location" name="location" control={control} />
          </Grid>
          {isError && (
            <Grid item>
              <ErrorsAlert message={errorsList} />
            </Grid>
          )}
        </Grid>
        <StyledDivider />
        <Grid container flexDirection="column" rowGap={2}>
          <Grid item>
            <Typography variant="h5">Travel</Typography>
          </Grid>
          <Grid item>
            <Travel name="travel" control={control} />
          </Grid>
        </Grid>
      </FormModal>
    </Box>
  );
};

export default PersonalInfoModal;
