import { FC, useCallback, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { useMutateProfile } from "api/hooks";
import ControlledDatepicker from "components/ControlledInputs/ControlledDatepicker";
import ControlledLocation from "components/ControlledInputs/ControlledLocation";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import FormControls from "components/Modals/components/FormControls";
import WorkPreference from "components/Options/WorkPreference";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormModal, Modal, TooltipButton } from "theme";
import ModalActionButtons from "theme/components/Modal/ModalActionButtons";
import { Delete } from "theme/icons";
import { handlePromiseError } from "utils/helpers";
import { useError } from "utils/hooks";
import { FormModalProps } from "utils/types/common";
import { WorkExperienceScheme, WorkExperienceFormScheme } from "./validation";
import DiscardChangesConfirmation, {
  useDiscardChangesConfirmation,
} from "../DiscardChangesConfirmation";

type WorkExperienceModalProps = FormModalProps & {
  title: string;
  defaultValues: WorkExperienceFormScheme;
  formId?: string;
};

const WORK_EXPERIENCE_FORM_ID = "work-experience-form";

const MIN_DATE = new Date(`${new Date(Date.now()).getFullYear() - 47}-01-01T00:00:00`);
const MAX_DATE = new Date(`${new Date(Date.now()).getFullYear()}-12-31T00:00:00`);

export const WORK_EXPERIENCE_DEFAULT_VALUES = {
  showTitle: "",
  showType: [],
  startDate: null,
  endDate: null,
  location: null,
  position: "",
  responsibilities: "",
};

const WorkExperienceModal: FC<WorkExperienceModalProps> = ({
  open,
  onClose,
  title,
  defaultValues,
  formId,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    clearErrors,
  } = useForm<WorkExperienceFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(WorkExperienceScheme),
    defaultValues,
  });

  const { modalProps, discardConfirmation } = useDiscardChangesConfirmation({
    isDirty,
    onDiscard: () => {
      reset();
      clearErrors();
      onClose();
    },
  });

  const { errorsList, isError, setServerError } = useError(errors);

  const {
    workExperience: {
      mutateAsync: addWorkExperience,
      isPending: isWorkExperienceLoading,
      isError: isWorkExperienceError,
    },
    deleteWorkExperience: {
      mutateAsync: deleteWorkExperience,
      isPending: isDeletingWorkExperience,
      isError: isDeleteWorkExperienceError,
    },
  } = useMutateProfile(setServerError);

  const onSubmit: SubmitHandler<WorkExperienceFormScheme> = async (data) => {
    await handlePromiseError(() => addWorkExperience(data));
    if (!isWorkExperienceError) {
      reset();
      clearErrors();
      onClose();
    }
  };

  const onDelete = useCallback(async () => {
    const workExperienceId = defaultValues?.id;
    if (!workExperienceId) return;
    await handlePromiseError(() => deleteWorkExperience(workExperienceId));
    if (!isDeleteWorkExperienceError) {
      reset();
      clearErrors();
      setIsDeleteModalOpen(false);
      onClose();
    }
  }, [
    clearErrors,
    defaultValues?.id,
    deleteWorkExperience,
    isDeleteWorkExperienceError,
    onClose,
    reset,
  ]);

  const id = formId || WORK_EXPERIENCE_FORM_ID;
  const isDeleteEnabled = !!defaultValues?.id;

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" autoComplete="off" id={id}>
      <FormModal
        actionButtons={
          <Grid container alignItems="center" justifyContent="space-between" width="100%">
            <Box>
              {isDeleteEnabled && (
                <TooltipButton
                  onClick={() => setIsDeleteModalOpen(true)}
                  variant="contained"
                  mb={(theme) => theme.spacing(2)}
                  icon={<Delete />}
                  tooltipTitle="Delete show"
                />
              )}
            </Box>
            <Box>
              <FormControls
                isPending={isWorkExperienceLoading}
                formId={id}
                onClose={discardConfirmation}
              />
            </Box>
          </Grid>
        }
        actionButtonsAlignment="start"
        open={open}
        title={title}
        onClose={discardConfirmation}
      >
        <Grid container flexDirection="column" rowGap={2.5}>
          <Grid item>
            <ControlledTextField
              control={control}
              label="Show title"
              name="showTitle"
              required
              type="text"
              autoComplete="off"
              placeholder="Enter show title"
            />
          </Grid>
          <Grid item>
            <WorkPreference
              multiple={false}
              label="Show type"
              wrap
              width="30%"
              name="showType"
              control={control}
            />
          </Grid>
          <Grid item container flexWrap="nowrap" columnGap={2}>
            <Grid item width="100%">
              <ControlledDatepicker
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                label="Start date"
                required
                name="startDate"
                control={control}
              />
            </Grid>
            <Grid item width="100%">
              <ControlledDatepicker
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                label="End date"
                required
                name="endDate"
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item>
            <ControlledLocation
              label="Location"
              placeholder="Search location by City"
              name="location"
              control={control}
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              control={control}
              label="Position"
              name="position"
              type="text"
              autoComplete="off"
              placeholder="Enter position name"
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              control={control}
              label="Responsibilities"
              name="responsibilities"
              type="text"
              autoComplete="off"
              placeholder="Share a few words about your duties and tasks"
              multiline
              maxLength={1000}
            />
          </Grid>
          {isError && (
            <Grid item>
              <ErrorsAlert message={errorsList} />
            </Grid>
          )}
        </Grid>
      </FormModal>
      <Modal
        open={isDeleteModalOpen}
        iconType="exclamation"
        title="Confirm deletion"
        description="Are you sure that you want to delete this show? This action cannot be undone."
        actionButtons={
          <ModalActionButtons
            buttons={[
              {
                label: "Cancel",
                color: "tertiary",
                onClick: () => setIsDeleteModalOpen(false),
                disabled: isDeletingWorkExperience,
              },
              {
                label: "Delete show",
                color: "primary",
                onClick: () => onDelete(),
                loading: isDeletingWorkExperience,
              },
            ]}
          />
        }
      />
      <DiscardChangesConfirmation {...modalProps} />
    </Box>
  );
};

export default WorkExperienceModal;
