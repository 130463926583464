import { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { queryClient } from "api/queryClient";
import ExpertiseLevel from "components/User/components/ExpertiseLevel";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Link, Modal, Typography } from "theme";
import ModalActionButtons, {
  ModalActionButtonsProps,
} from "theme/components/Modal/ModalActionButtons";
import { PartyPopper } from "theme/icons/colored";
import { useAuth } from "utils/context/Auth/AuthContext";
import { mapExpertise } from "utils/mappers/profileMappers";
import { Expertise, Level } from "utils/types/common";

export type NextStepModalProps = {
  result: Expertise[] | null;
  currentLevel?: Level | null;
  goNext?: VoidFunction;
  onRetake: VoidFunction;
};

const NextStepModal: FC<NextStepModalProps> = ({ result, currentLevel, goNext, onRetake }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const description = useMemo(() => {
    if (!currentLevel) {
      return;
    }
    const level = (
      <Typography variant="body2" color="primary" weight="semiBold" component="span">
        {currentLevel}
      </Typography>
    );

    return (
      <Typography variant="body2" color="secondary">
        You have successfully completed the survey. Based on your survey responses, we have
        determined your expertise level as {level}. Your level will now be shown in your profile.
      </Typography>
    );
  }, [currentLevel]);

  const mappedExpertise = mapExpertise(result);

  const actionButtons = useMemo(() => {
    const buttons: ModalActionButtonsProps["buttons"] = [
      {
        label: "Finish & Go to my profile",
        color: "secondary",
        onClick: async () => {
          await queryClient.invalidateQueries({ queryKey: ["profile", user?.profileId] });
          navigate(Routes.MyProfile, { replace: true });
        },
      },
    ];

    goNext &&
      buttons.push({
        label: "Go to next expertise",
        color: "primary",
        onClick: goNext,
      });

    return (
      <ModalActionButtons buttons={buttons}>
        <Typography variant="body2" textAlign="center">
          Not happy with your survey results?{" "}
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              onRetake();
            }}
          >
            Re-take this survey
          </Link>
        </Typography>
      </ModalActionButtons>
    );
  }, [navigate, goNext, onRetake, user?.profileId]);

  return (
    <Modal
      size="medium"
      open={Boolean(result)}
      icon={<PartyPopper />}
      title="Congratulations!"
      description={description}
      actionButtons={actionButtons}
    >
      <Grid item flexWrap="nowrap" columnGap={2} container justifyContent="center">
        {mappedExpertise?.map((item, index) => {
          return (
            <Grid item key={index} width="33%">
              <ExpertiseLevel {...item} />
            </Grid>
          );
        })}
      </Grid>
    </Modal>
  );
};

export default NextStepModal;
