import { emailValidation, passwordValidation } from "utils/validation/utils";
import { z } from "zod";

export const forgotPasswordScheme = z.object({
  email: emailValidation(),
});

export type ForgotPasswordFormScheme = z.infer<typeof forgotPasswordScheme>;
export type ForgotPasswordResponse = void;

const newPasswordScheme = passwordValidation("New password");
const confirmPasswordScheme = passwordValidation("Confirm password");

export const resetPasswordScheme = z
  .object({
    newPassword: newPasswordScheme,
    confirmPassword: confirmPasswordScheme,
  })
  .superRefine(({ confirmPassword, newPassword }, ctx) => {
    const { success: newPasswordSuccess } = newPasswordScheme.safeParse(newPassword);
    const { success: confirmPasswordSuccess } = confirmPasswordScheme.safeParse(confirmPassword);

    if (newPasswordSuccess && confirmPasswordSuccess && confirmPassword !== newPassword) {
      ctx.addIssue({
        code: "custom",
        message: "\"Confirm password\" field does not match \"New password\" field.",
      });
    }
  });

export type ResetPasswordFormScheme = z.infer<typeof resetPasswordScheme>;
export type ResetPasswordResponse = void;
