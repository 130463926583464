import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Button } from "theme";
import { useError } from "utils/hooks";
import useFetch from "utils/hooks/useFetch";
import { ResetPasswordFormScheme, resetPasswordScheme } from "./validation";
import { ErrorsAlert } from "../components/ErrorsAlert/ErrorsAlert";

const FORM_ID = "reset-password-form";

type ResetPasswordProps = {
  token?: string | null;
};

const ResetPassword = ({ token }: ResetPasswordProps) => {
  const navigate = useNavigate();
  const request = useFetch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(resetPasswordScheme),
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { setServerError, errorsList, isError } = useError(errors);

  const { mutate: resetPassword, isPending } = useMutation({
    mutationFn: (data: ResetPasswordFormScheme) => {
      return request(Urls.ResetPassword, {
        method: "PUT",
        body: JSON.stringify(data),
        slug: { token },
      });
    },
    onMutate: () => setServerError(null),
    onError: setServerError,
    onSuccess: () => navigate(Routes.SignIn, { replace: true }),
  });

  const onSubmit: SubmitHandler<ResetPasswordFormScheme> = (data) => {
    resetPassword(data);
  };

  return (
    <Grid container rowSpacing={4}>
      <Grid
        item
        container
        component="form"
        autoComplete="off"
        id={FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        flexDirection="column"
        rowSpacing={2.5}
        flexGrow={1}
      >
        <Grid item>
          <ControlledTextField
            control={control}
            name="newPassword"
            placeholder="Enter new password"
            label="New password"
            required
            type="password"
            autoComplete="off"
            loading={isPending}
          />
        </Grid>
        <Grid item>
          <ControlledTextField
            control={control}
            name="confirmPassword"
            placeholder="Enter confirmation password"
            label="Confirm password"
            required
            type="password"
            autoComplete="off"
            loading={isPending}
          />
        </Grid>
        {isError && (
          <Grid item>
            <ErrorsAlert message={errorsList} />
          </Grid>
        )}
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container flexDirection="column" rowSpacing={2}>
          <Grid item>
            <Button
              loading={isPending}
              type="submit"
              form={FORM_ID}
              color="primary"
              variant="contained"
              fullWidth
            >
              {isPending ? "Loading" : "Change password"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
