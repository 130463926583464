import { styled } from "@mui/material";
import Typography from "./Typography";

type TitleProps = {
  label: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5";
  notes?: string;
};

const Notes = styled("span")(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Title: React.FC<TitleProps> = ({ label, notes, variant = "h2" }) => (
  <Typography variant={variant}>
    {label} {notes && <Notes>({notes})</Notes>}
  </Typography>
);

export default Title;
