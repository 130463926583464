import { FC, useCallback } from "react";
import { useMutateProfile } from "api/hooks";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import FormModalLayout from "components/Modals/components/FormModalLayout";
import { SubmitHandler } from "react-hook-form";
import { useError } from "utils/hooks";
import useSummaryForm, { SummaryFormScheme } from "utils/hooks/form/useSummaryForm";
import { FormModalProps } from "utils/types/common";
import { handlePromiseError } from "../../../utils/helpers";

type SummaryModalProps = FormModalProps & {
  summary?: string;
};

const SUMMARY_FORM_ID = "summary-form";

const SummaryModal: FC<SummaryModalProps> = ({ open, onClose, summary }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useSummaryForm({ summary: summary || "", required: false });

  const { errorsList, isError, setServerError, isServerError } = useError(errors);
  const {
    summary: { mutateAsync: updateSummary, isPending: isSummaryLoading },
  } = useMutateProfile(setServerError);

  const onModalClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit: SubmitHandler<SummaryFormScheme> = async (data) => {
    await handlePromiseError(() => updateSummary(data.summary));
    if (!isServerError && isValid) {
      onModalClose();
    }
  };

  return (
    <FormModalLayout
      formId={SUMMARY_FORM_ID}
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onModalClose}
      isPending={isSummaryLoading}
      title="Edit Summary"
      description="Give us a brief overview of your professional path and areas of expertise."
      error={isError ? <ErrorsAlert message={errorsList} /> : undefined}
    >
      <ControlledTextField
        placeholder="Share a few words about your background"
        control={control}
        name="summary"
        rows={4}
        required
        multiline
      />
    </FormModalLayout>
  );
};

export default SummaryModal;
