import { FC } from "react";
import { TooltipButton } from "theme";
import { TooltipButtonProps } from "theme/components/Button/TooltipButton";
import { Copy } from "theme/icons";
import { useCopy } from "utils/hooks";

type CopyButtonProps = TooltipButtonProps & {
  copyText: string;
  copySuccessText?: string;
};

const CopyButton: FC<CopyButtonProps> = ({
  tooltipTitle,
  copyText,
  variant = "text",
  color,
  icon = <Copy />,
  copySuccessText = "Copied!",
}) => {
  const { isCopied, handleCopyClick } = useCopy(copyText);

  return (
    <TooltipButton
      variant={variant}
      color={color}
      tooltipTitle={isCopied ? copySuccessText : tooltipTitle}
      icon={icon}
      onClick={handleCopyClick}
    />
  );
};

export default CopyButton;
