import { useCallback, useMemo, useRef, useState } from "react";
import { useSurvey } from "api/hooks";
import { Option } from "api/hooks/useSurvey/type";
import Questionnaires, {
  FormValues,
  QuestionnairesRef,
} from "components/Forms/Survey/Questionnaires";
import NextStepModal from "components/Survey/NextStepModal";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Routes } from "router";
import { useSurveyModal } from "utils/hooks/useSurveyModal";
import { Expertise, Specialization } from "utils/types/common";
import { StepData } from "./Survey";

const SurveyQuestionnaire = () => {
  const formRef = useRef<QuestionnairesRef>(null);
  const { selectedSurveys } = useSurveyModal();
  const { stepsData, stepData } = useOutletContext<{ stepsData: StepData[]; stepData: StepData }>();
  const { specialization } = useParams<{ specialization: Specialization }>();
  const { questionnaires, groupedQuestionnaires, submitSurvey } = useSurvey(specialization);
  const navigate = useNavigate();
  const [result, setResult] = useState<Expertise[] | null>(null);

  const nextStepData = useMemo(
    () => stepsData.find((i: any) => i.step > stepData.step),
    [stepsData, stepData],
  );

  const goNext = useCallback(() => {
    if (nextStepData) {
      navigate(nextStepData.route, { replace: true, state: { selectedSurveys } });

      result && setResult(null);
      formRef.current?.formReset();
    }

    return null;
  }, [nextStepData, selectedSurveys, result, setResult, navigate]);

  const handleRetakeSurvey = useCallback(() => {
    setResult(null);
    formRef.current?.formReset();
  }, [setResult]);

  const handleSubmitSurvey = useCallback(
    async (data: FormValues) => {
      const questionIds = Object.entries(data).reduce<string[]>((acc, [questionId, value]) => {
        if (value === Option.YES) {
          acc.push(questionId);
        }

        return acc;
      }, []);

      const result = await submitSurvey.mutateAsync({ questionIds });
      result && setResult([result]);
    },
    [submitSurvey],
  );

  const isLastStep = !nextStepData;

  const handleSkipStep = useCallback(() => {
    formRef.current?.formReset();
    isLastStep ? navigate(Routes.MyProfile) : goNext();
  }, [isLastStep, goNext, navigate]);

  return (
    <>
      <Questionnaires
        ref={formRef}
        type={specialization as Specialization}
        loading={questionnaires.isPending}
        survey={groupedQuestionnaires}
        questionnaires={questionnaires.data || []}
        onSubmit={handleSubmitSurvey}
        onSkipStep={handleSkipStep}
        isLastStep={isLastStep}
      />

      <NextStepModal
        result={result}
        currentLevel={
          result?.find(({ specializationName }) => specializationName === specialization)?.levelName
        }
        goNext={!isLastStep ? goNext : undefined}
        onRetake={handleRetakeSurvey}
      />
    </>
  );
};

export default SurveyQuestionnaire;
