import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "utils/context/Auth/AuthContext";
import { Roles } from "utils/context/Auth/user";
import { Routes } from "./routes";

type ProtectedRouteProps = {
  isPrivate?: boolean;
  allowedRole?: Roles;
  children: React.ReactNode;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  isPrivate = true,
  allowedRole,
}) => {
  const { isAuthenticated, user } = useAuth();
  const isCorrectRole = allowedRole ? allowedRole === user?.role : true;

  if (isPrivate) {
    if (isAuthenticated && isCorrectRole) {
      return <>{children}</>;
    } else {
      return <Navigate to={Routes.Root} replace />;
    }
  } else {
    if (!isAuthenticated && isCorrectRole) {
      return <>{children}</>;
    } else {
      return (
        <Navigate to={user?.role === Roles.Technician ? Routes.MyProfile : Routes.Root} replace />
      );
    }
  }
};
