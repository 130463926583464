import { LocationOption, getGeoNameLabel } from "theme/utils";
import { TravelStatus } from "../types/common";

export const travelOptions = [
  { value: TravelStatus.TravelInside, label: "Travel inside the country" },
  { value: TravelStatus.TravelAbroad, label: "Travel anywhere" },
];

const locationDivider = "|";

export const locationToQueryString = (location: LocationOption) => {
  return [
    location.geoNameId,
    location.countryName,
    location.countryCode,
    location.adminCode,
    location.adminName,
    location.cityName,
  ].join(locationDivider);
};

export const stringToLocation = (location: string): LocationOption | null => {
  const loc = location.split(locationDivider);
  if (loc.length !== 6) {
    return null;
  }
  const [geoNameId, countryName, countryCode, adminCode, adminName, cityName] = loc;

  return { geoNameId, countryName, countryCode, adminCode, adminName, cityName };
};

export const formatLocationString = (location: string) => {
  return getGeoNameLabel(stringToLocation(location));
};
