import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Divider, Grid, styled } from "@mui/material";
import { GroupedSurveyData, Option, Questionnaire } from "api/hooks/useSurvey/type";
import DiscardChangesConfirmation, {
  useDiscardChangesConfirmation,
} from "components/Modals/DiscardChangesConfirmation";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Loader } from "theme";
import { Redo } from "theme/icons";
import { Specialization } from "utils/types/common";
import QuestionsList from "./components/QuestionsList";

export type FormValues = Record<string, Option | undefined>;

type QuestionnairesProps = {
  type: Specialization;
  loading: boolean;
  questionnaires: Questionnaire[];
  survey: GroupedSurveyData;
  isLastStep: boolean;
  onSubmit: (data: FormValues) => void;
  onSkipStep: () => void;
};

export type QuestionnairesRef = {
  formReset: VoidFunction;
};

const ActionButtonsDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const Questionnaires = forwardRef<QuestionnairesRef, QuestionnairesProps>(
  ({ type, loading, survey, questionnaires, isLastStep, onSubmit, onSkipStep }, ref) => {
    const [activeAccordion, setActiveAccordion] = useState<string>();

    const formDefaultValues = useMemo(() => {
      return questionnaires.reduce<FormValues>((acc, i) => {
        acc[i.id] = undefined;
        return acc;
      }, {});
    }, [questionnaires]);

    const methods = useForm<FormValues>({
      reValidateMode: "onSubmit",
      defaultValues: formDefaultValues,
    });

    useImperativeHandle(
      ref,
      () => ({
        formReset: () => {
          methods.reset();
          setActiveAccordion("");
        },
      }),
      [methods],
    );

    useEffect(() => {
      if (activeAccordion) {
        return;
      }

      setActiveAccordion(survey.keys().next().value);
    }, [survey, activeAccordion, setActiveAccordion]);

    const handleAccordionClick = (name: string) => {
      if (activeAccordion === name) {
        return;
      }

      setActiveAccordion(name);
    };

    const { isSubmitting, isDirty } = methods.formState;

    const { modalProps, discardConfirmation } = useDiscardChangesConfirmation({
      isDirty,
      onDiscard: onSkipStep,
    });

    if (loading) {
      return <Loader size={42} />;
    }

    return (
      <>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {[...survey.entries()].map(([section, questions]) => (
              <QuestionsList
                key={section}
                name={section}
                questions={questions}
                expanded={activeAccordion === section}
                onClick={handleAccordionClick}
              />
            ))}

            <ActionButtonsDivider />

            <Grid container justifyContent="flex-end" mt={2} gap={2}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="tertiary"
                  startIcon={<Redo />}
                  disabled={isSubmitting}
                  onClick={discardConfirmation}
                >
                  Skip {type} survey
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                >
                  {isSubmitting
                    ? "Loading"
                    : isLastStep
                      ? "Complete survey"
                      : `Complete ${type} survey`}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>

        <DiscardChangesConfirmation {...modalProps} />
      </>
    );
  },
);

export default Questionnaires;
