import { useCallback, useState, SyntheticEvent, useMemo, useEffect } from "react";
import { Grid, debounce } from "@mui/material";
import { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { SearchProfile } from "api/hooks/useSearch/type";
import { ITEMS_PER_PAGE } from "api/hooks/useSearch/useInfiniteSearch";
import useSearch from "api/hooks/useSearch/useSearch";
import { generatePath, useNavigate } from "react-router-dom";
import { Routes } from "router";
import DropdownLoader from "theme/components/Autocomplete/components/DropdownLoader";
import DropdownNoOptions from "theme/components/Autocomplete/components/DropdownNoOptions";
import { ArrowRight, ClearSmall, Search } from "theme/icons";
import { SearchParamKey } from "utils/helpers";
import { useFilter } from "utils/hooks";
import ProfileOption from "./components/ProfileOption";
import ProfileOptionInfo from "./components/ProfileOptionInfo";
import ProfileSeeAll from "./components/ProfileSeeAll";
import { StyledButton, StyledAutocomplete, StyledDropdown, StyledTextField } from "./styles";
import DropdownServiceUnavailable from "../components/DropdownServiceUnavailable";

type ProfileSearchProps = {
  main?: boolean;
  disabled?: boolean;
};

const CHARS_TO_ENABLE = 3;

const ProfileSearch: React.FC<ProfileSearchProps> = ({ main = false, disabled = false }) => {
  const navigate = useNavigate();
  const { search: searchText, setSearch: setSearchText } = useFilter();

  const [search, setSearch] = useState(searchText);
  const [showGoToSearch, setGoToSearch] = useState(false);
  const [open, setOpen] = useState(false);

  const showClear = !!search && search.length >= 1;
  const isSearchEnabled = !!search && search.length >= CHARS_TO_ENABLE;

  const {
    profilesSearch: { data, isPending, isError, refetch },
  } = useSearch(search);

  const debounceFetch = useMemo(() => debounce(refetch, 500), [refetch]);

  const onSearch = useCallback(() => {
    if (main) {
      const searchParams = search
        ? new URLSearchParams({ [SearchParamKey]: search }).toString()
        : "";

      navigate({
        pathname: Routes.Search,
        search: searchParams,
      });
    } else {
      setSearchText(search);
      setOpen(false);
    }
  }, [main, navigate, search, setSearchText]);

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSearch();
    }
  };

  const handleInputChange = (
    _: SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === "reset") {
      return;
    }
    if (!main && reason === "clear") {
      setSearchText("");
    }

    if (value.length >= CHARS_TO_ENABLE) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    setSearch(value);
  };

  const handleRenderTags = useCallback(() => null, []);

  useEffect(() => {
    if (isSearchEnabled && open) {
      debounceFetch();
    }

    if (!isSearchEnabled && open) {
      setOpen(false);
    }
  }, [search, open, isSearchEnabled, debounceFetch]);

  return (
    <>
      <StyledAutocomplete
        disabled={disabled}
        open={open}
        loading={isPending}
        onInputChange={handleInputChange}
        options={data?.searchProfiles || []}
        value={search}
        renderTags={handleRenderTags}
        loadingText={<DropdownLoader />}
        noOptionsText={isError ? <DropdownServiceUnavailable /> : <DropdownNoOptions />}
        clearIcon={<ClearSmall />}
        disableClearable={!showClear}
        PopperComponent={StyledDropdown}
        onFocus={() => {
          setGoToSearch(true);
          setOpen(isSearchEnabled);
        }}
        onBlur={() => {
          setOpen(false);
          setGoToSearch(false);
        }}
        getOptionLabel={() => search || ""}
        fullWidth
        onKeyDown={handleEnterPress}
        forcePopupIcon={true}
        popupIcon={
          <>
            {showGoToSearch && (
              <Grid container gap={0.5}>
                <StyledButton onClick={onSearch} color="secondary" variant="contained">
                  <ArrowRight />
                </StyledButton>
              </Grid>
            )}
          </>
        }
        renderInput={(params) => {
          return (
            <StyledTextField
              {...params}
              name="search"
              startIcon={<Search />}
              placeholder="Search by expertise, location, name..."
              isRounded={!open}
            />
          );
        }}
        renderOption={(props, option) => {
          const id = (props as { "data-option-index": number })["data-option-index"];
          const profile = option as SearchProfile;
          const showOptionInfo = id === 0;
          const showSeeAllLink = id === ITEMS_PER_PAGE - 1 && main && search;

          return (
            <Grid key={profile.profileId}>
              {showOptionInfo && <ProfileOptionInfo>({data?.totalProfiles})</ProfileOptionInfo>}
              <Grid
                onClick={() =>
                  navigate(generatePath(Routes.Profile, { profileId: profile.profileId }))
                }
              >
                <ProfileOption profile={profile} />
              </Grid>
              {showSeeAllLink && <ProfileSeeAll search={search} />}
            </Grid>
          );
        }}
      />
    </>
  );
};

export default ProfileSearch;
