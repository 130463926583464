import { FC, useCallback, useState } from "react";
import { Control, useController } from "react-hook-form";
import { SelectionGroup } from "theme";
import { AllSelectionGroupProps } from "theme/components/FormInputs/SelectionGroup";

export type ControlledSelectionCheckboxProps = Omit<
  AllSelectionGroupProps,
  "value" | "onChange" | "type"
> & {
  control: Control<any>;
  multiple?: boolean;
};

const ControlledSelectionCheckbox: FC<ControlledSelectionCheckboxProps> = ({
  control,
  name,
  multiple = true,
  ...props
}) => {
  const { field } = useController({
    control,
    name,
  });
  const [value, setValue] = useState<string[]>(field.value || []);

  const onMultipleChange = useCallback(
    (newValue: string[]) => {
      field.onChange(newValue);

      setValue(newValue);
    },
    [field],
  );

  const onSingleChange = useCallback(
    (newValue: string[]) => {
      if (newValue.length === 0) {
        setValue(newValue);
        field.onChange(newValue);

        return;
      }

      const singleNewValue = newValue.slice(-1);

      setValue(singleNewValue);
      field.onChange(singleNewValue);
    },
    [field],
  );

  return (
    <SelectionGroup
      name={name}
      value={value}
      onChange={(value) => (multiple ? onMultipleChange(value) : onSingleChange(value))}
      type="checkbox"
      {...props}
    />
  );
};

export default ControlledSelectionCheckbox;
