import React, { ReactElement } from "react";
import { AlertColor, Grid, List, ListItem } from "@mui/material";
import { default as MuiAlert } from "@mui/material/Alert";
import { default as MuiLink } from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { To } from "react-router-dom";
import { ArrowRightSmall, ErrorFill, InfoFill, Success } from "theme/icons";
import Link from "../Link/Link";
import Typography from "../Typography/Typography";

export const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(1, 2),

  "&.MuiAlert-root.MuiAlert-standardError": {
    backgroundColor: theme.palette.error.light,
  },

  "&.MuiAlert-root > .MuiAlert-icon": {
    padding: 0,
    marginRight: theme.spacing(1),
  },

  "&.MuiAlert-root.MuiAlert-standardError > .MuiAlert-icon": {
    color: theme.palette.primary.main,
  },

  "&.MuiAlert-root > .MuiAlert-message": {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  margin: 0,
  padding: 0,
  paddingLeft: theme.spacing(2.5),
}));

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
  display: "list-item",
  listStyleType: "disc",
}));

const LinkContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(0.5),
  fontWeight: theme.typography.fontWeightMedium,
}));

const iconMap: Record<AlertColor, ReactElement> = {
  error: <ErrorFill />,
  warning: <InfoFill />,
  info: <InfoFill />,
  success: <Success />,
} as const;

interface AlertActionLinkProps {
  text?: string;
  to?: To;
  onClick?: () => void;
}

export const AlertActionLink: React.FC<AlertActionLinkProps> = ({ onClick, text, to }) => {
  if (onClick) {
    return (
      <MuiLink type="button" component="button" onClick={onClick} underline="none">
        <LinkContainer>
          {text}
          <ArrowRightSmall />
        </LinkContainer>
      </MuiLink>
    );
  }

  if (to) {
    return (
      <Link to={to}>
        <LinkContainer>
          {text}
          <ArrowRightSmall />
        </LinkContainer>
      </Link>
    );
  }

  return null;
};

type AlertProps = {
  className?: string;
  message: string | string[];
  severity?: AlertColor;
  linkTo?: To;
  linkText?: string;
  onClick?: () => void;
};

const Alert: React.FC<AlertProps> = ({
  className,
  message,
  severity = "error",
  linkText,
  linkTo,
  onClick,
}) => {
  const isArray = Array.isArray(message);

  if (isArray && message.length === 0) {
    throw new Error("Array length is 0");
  }

  if (isArray && message.length > 1) {
    return (
      <StyledAlert className={className} severity={severity} icon={iconMap[severity]}>
        <StyledList>
          {message.map((er, idx) => (
            <Typography component={StyledListItem} key={idx} variant="body2">
              {er}
            </Typography>
          ))}
        </StyledList>
      </StyledAlert>
    );
  }

  const alertMessage = isArray ? message[0] : message;

  const actionProps = linkTo ? { text: linkText, to: linkTo } : { text: linkText, onClick };

  return (
    <StyledAlert className={className} severity={severity} icon={iconMap[severity]}>
      <Typography variant="body2">{alertMessage}</Typography>
      <AlertActionLink {...actionProps} />
    </StyledAlert>
  );
};

export default Alert;
