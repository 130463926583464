import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useGoBack = (fallback?: string) => {
  const routerNavigate = useNavigate();
  const location = useLocation();

  const shouldShowBackButton = location.key !== "default";

  const navigate = useCallback(() => {
    if (fallback) {
      routerNavigate(fallback);
    } else {
      routerNavigate(-1);
    }
  }, [fallback, routerNavigate]);

  return { navigate, shouldShowBackButton };
};

export default useGoBack;
