export enum Routes {
  Root = "/",
  AccountSettings = "/account-settings",
  Search = "/search",

  MyProfile = "/profile",
  Profile = "/profile/:profileId",

  ProfileCreation = "/profile/create",
  ProfileInfo = "profile-info",
  SkillsAndCertifications = "skills-and-certifications",
  SummaryAndWorkExperience = "summary-and-work-experience",

  ProfileCreationProfileInfo = "/profile/create/profile-info",
  ProfileCreationSkillsAndCertifications = "/profile/create/skills-and-certifications",
  ProfileCreationSummaryAndWorkExperience = "/profile/create/summary-and-work-experience",

  SignUp = "/sign-up",
  InvitationSignUp = "/sign-up/:invitationToken",
  SignIn = "/sign-in",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  Activation = "/activation/:token",

  Survey = "/survey",
  SurveyQuestionnaire = "/survey/:specialization",
  SurveyLighting = "/survey/lighting",
  SurveyAutomation = "/survey/automation",
  SurveyRigging = "/survey/rigging",
}
