import { FC } from "react";
import ControlledSelectionCheckbox, {
  ControlledSelectionCheckboxProps,
} from "components/ControlledInputs/ControlledSelectionCheckbox";
import { travelOptions } from "utils/helpers";

type TravelProps = Pick<ControlledSelectionCheckboxProps, "name" | "control">;

const Travel: FC<TravelProps> = (props) => {
  return (
    <ControlledSelectionCheckbox
      multiple={false}
      direction="row"
      label="Ready to travel"
      description="Let us know if you are open to business trips."
      options={travelOptions}
      {...props}
    />
  );
};

export default Travel;
