import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { Typography } from "theme";

const StyledTypography = styled(Typography)({
  wordBreak: "break-word",
});

const SuccessModal = () => {
  const location = useLocation();
  const fullName = location.state?.fullName as string;
  const email = location.state?.email as string;

  return (
    <Grid container rowSpacing={1}>
      <Grid item>
        <StyledTypography variant="body2" color="secondary">
          Dear, {fullName}, a confirmation email has been sent to {email}.
        </StyledTypography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="secondary">
          Kindly proceed to your email, confirm your registration, and set up your profile.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="secondary">
          We're excited to have you join us!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SuccessModal;
