import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";
import { Urls } from "api/urls";
import { useAuth } from "utils/context/Auth/AuthContext";
import { User } from "utils/context/Auth/user";
import { useFetch } from "utils/hooks";
import { ResponseError } from "utils/hooks/useFetch";
import { Profile } from "../useProfile/type";

const useMutateAccount = (setServerError: (err: ResponseError | null) => void) => {
  const request = useFetch();
  const { user, token, setUser } = useAuth();

  const account = useMutation({
    mutationFn: (data: { firstName: string; lastName: string; company?: string }) => {
      return request<User>(Urls.Accounts, {
        method: "PUT",
        body: JSON.stringify(data),
      });
    },
    onMutate: () => {
      setServerError(null);
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
    onSuccess: (data) => {
      if (!data || !user) return;

      setUser({
        ...user,
        firstName: data.firstName,
        lastName: data.lastName,
      });

      queryClient.setQueryData<Profile | null>(["profile", user?.profileId], (oldData) =>
        oldData
          ? {
            ...oldData,
            firstName: data.firstName,
            lastName: data.lastName,
          }
          : oldData,
      );

      queryClient.setQueryData<User | undefined>(["account", token], (oldData) =>
        oldData
          ? {
            ...oldData,
            firstName: data.firstName,
            lastName: data.lastName,
            company: data?.company,
          }
          : oldData,
      );
    },
  });

  return {
    account,
  };
};

export default useMutateAccount;
