import { useCallback } from "react";
import { Grid } from "@mui/material";
import useInfiniteSearch from "api/hooks/useSearch/useInfiniteSearch";
import PageLayout from "components/Layout/PageLayout";
import AppliedFilter from "components/SearchFilter/AppliedFilter";
import ProfilesList from "components/SearchFilter/ProfilesList";
import SearchFilter from "components/SearchFilter/SearchFilter";
import { ProfileSearch, Title } from "theme";
import { nonNullable } from "utils/helpers";
import { useFilter } from "utils/hooks";
import { QueryObject } from "utils/hooks/useFilter";

const Search = () => {
  const { filter, updateFilterParams, clearFilterParams, clearAllFilterParams } = useFilter();
  const { infiniteProfilesSearch } = useInfiniteSearch();

  const onFilterChange = useCallback(
    (payload: QueryObject) => {
      updateFilterParams({
        ...filter,
        ...payload,
      });
    },
    [filter, updateFilterParams],
  );

  const profilePages = (infiniteProfilesSearch.data?.pages || []).filter(nonNullable);

  return (
    <PageLayout mode="light">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProfileSearch />
        </Grid>

        <Grid item xs={12}>
          <Title label="Search Results" notes={profilePages?.[0]?.totalProfiles.toString()} />
        </Grid>

        {Boolean(Object.keys(filter).length) && (
          <Grid item xs={12}>
            <AppliedFilter
              filter={filter}
              onClear={({ key, value }) => clearFilterParams(key, value)}
              onClearAll={clearAllFilterParams}
            />
          </Grid>
        )}

        <Grid item xs={4} alignSelf="stretch">
          <SearchFilter filter={filter} onChange={onFilterChange} />
        </Grid>

        <Grid item xs={8}>
          <ProfilesList />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Search;
