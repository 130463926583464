import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { z } from "zod";

export const profileInfoScheme = z.object({
  nickname: z
    .string()
    .max(250, { message: getErrorMessage("Nickname", ErrorMessage.FieldTooLong) }),
  expertise: z.array(z.string()),
  location: z
    .object({
      adminCode: z.string(),
      adminName: z.string(),
      cityName: z.string(),
      countryCode: z.string(),
      countryName: z.string(),
      geoNameId: z.string(),
    })
    .nullable(),
  travel: z.array(z.string()),
});

export type ProfileInfoFormScheme = z.infer<typeof profileInfoScheme>;
