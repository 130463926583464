import { FC, useCallback, useState } from "react";
import { useBeforeUnload } from "react-router-dom";
import { Modal } from "theme";
import ModalActionButtons from "theme/components/Modal/ModalActionButtons";

type UseDiscardChangesConfirmationProps = {
  isDirty: boolean;
  onDiscard: VoidFunction;
};

type DiscardChangesConfirmationProps = {
  open: boolean;
  onCancel: VoidFunction;
  onDiscard: VoidFunction;
};

export const useDiscardChangesConfirmation = ({
  isDirty,
  onDiscard,
}: UseDiscardChangesConfirmationProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useBeforeUnload(
    useCallback(
      (e) => {
        if (isDirty) {
          e.preventDefault();
          return (e.returnValue = "");
        }
      },
      [isDirty],
    ),
  );

  const discardConfirmation = () => {
    if (isDirty) {
      setIsModalOpen(true);
    } else {
      onDiscard();
    }
  };

  const handleCancel = () => setIsModalOpen(false);

  return {
    discardConfirmation,
    modalProps: {
      open: isModalOpen,
      onDiscard: () => {
        setIsModalOpen(false);
        onDiscard();
      },
      onCancel: handleCancel,
    } as DiscardChangesConfirmationProps,
  };
};

const DiscardChangesConfirmation: FC<DiscardChangesConfirmationProps> = ({
  open,
  onCancel,
  onDiscard,
}) => {
  return (
    <Modal
      open={open}
      iconType="exclamation"
      title="Are you sure that you want to discard changes?"
      description="You have unsaved changes on this page. To keep your changes, click 'Cancel' and continue editing. To discard your changes, click 'Discard'."
      actionButtons={
        <ModalActionButtons
          buttons={[
            {
              label: "Cancel",
              color: "tertiary",
              onClick: onCancel,
            },
            {
              label: "Discard",
              color: "primary",
              onClick: onDiscard,
            },
          ]}
        />
      }
    />
  );
};

export default DiscardChangesConfirmation;
