import { FC } from "react";
import { styled } from "@mui/material";
import { Button } from "theme";

type ShowMoreButtonProps = {
  onClick: VoidFunction;
  itemsCount?: number;
  loading?: boolean;
};

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),

  "& span": {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
}));

const ShowMoreButton: FC<ShowMoreButtonProps> = ({ itemsCount, loading, onClick }) => {
  return (
    <StyledButton
      variant="contained"
      color="tertiary"
      fullWidth
      onClick={onClick}
      loading={loading}
    >
      Show more
      {Boolean(itemsCount) && !loading && <span>(+{itemsCount})</span>}
    </StyledButton>
  );
};

export default ShowMoreButton;
