import { useValidateInvitation } from "api/hooks";
import SignUpForm from "components/Forms/SignUp/SignUp";
import SignPageLayout from "components/Layout/SignPageLayout";
import SignUpSuccess from "components/Modals/SuccessModal";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Routes } from "router";
import { ConfirmationEmail } from "theme/icons/colored";
import { useAuth } from "utils/context/Auth/AuthContext";

const SignUp = () => {
  const { invitationToken = "" } = useParams();
  const location = useLocation();
  const { isAccessEnabled } = useAuth();
  const { isPending } = useValidateInvitation(invitationToken);

  const isUserRegistered = Boolean(location.state?.fullName);

  if (isUserRegistered) {
    return (
      <SignPageLayout
        icon={<ConfirmationEmail />}
        title="Confirm your email"
        description={<SignUpSuccess />}
      />
    );
  }

  if (!isAccessEnabled && !invitationToken) {
    return <Navigate to={Routes.Root} replace />;
  }

  if (isPending) {
    return <SignPageLayout iconType="loading" title="Checking the invitation link" />;
  }

  return (
    <SignPageLayout
      iconType="logo"
      title="Sign Up to CrewLink"
      description="Your journey starts here! Create an Account."
    >
      <SignUpForm />
    </SignPageLayout>
  );
};

export default SignUp;
