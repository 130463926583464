import { styled } from "@mui/material";
import { DesktopDatePicker as MuiDatePicker } from "@mui/x-date-pickers/DesktopDatePicker/DesktopDatePicker";

const StyledDatepicker = styled(MuiDatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused": {
    outlineOffset: 0,
    outlineWidth: theme.spacing(0.25),
    outlineStyle: "solid",
    outlineColor: theme.palette.common.black,

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },

  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.tertiary.main,
    },
  },

  "& .Mui-error.Mui-focused": {
    outlineColor: "transparent",
  },
}));

export default StyledDatepicker;
