import { FC } from "react";
import { styled } from "@mui/material";
import Header from "components/Header/Header";
import Layout, { LayoutProps } from "./components/Layout";

type PageLayoutProps = Pick<
  LayoutProps,
  "backgroundImageSrc" | "backgroundColor" | "mode" | "children"
>;

const MainContent = styled("main")(({ theme }) => {
  return {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: theme.layout.contentMaxWidth,
    width: "100%",
    padding: theme.spacing(4),
    margin: "0 auto",
  };
});

const PageLayout: FC<PageLayoutProps> = ({ children, mode, ...props }) => {
  return (
    <Layout mode={mode} {...props}>
      <Header mode={mode} />

      <MainContent>{children}</MainContent>
    </Layout>
  );
};

export default PageLayout;
