import { FC, useCallback, useState } from "react";
import { Control, useController } from "react-hook-form";
import { Autocomplete } from "theme";
import { AutocompleteProps } from "theme/components/Autocomplete/Autocomplete";

export type ControlledAutocompleteProps = Omit<
  AutocompleteProps,
  "onChange" | "multiple" | "value" | "defaultValue"
> & {
  control: Control<any>;
};

const ControlledAutocomplete: FC<ControlledAutocompleteProps> = ({ name, control, ...props }) => {
  const { field } = useController({
    control,
    name,
  });

  const [value, setValue] = useState<string[]>(field.value);

  const onChange = useCallback(
    (options: string | string[]) => {
      if (!Array.isArray(options)) return;

      field.onChange(options);
      setValue(options);
    },
    [field],
  );

  return <Autocomplete name={name} value={value} onChange={onChange} multiple {...props} />;
};

export default ControlledAutocomplete;
