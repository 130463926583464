import { FC } from "react";
import { styled, Avatar as MuiAvatar, CircularProgress, Box, useTheme } from "@mui/material";
import { AvatarProps as MuiAvatarProps } from "@mui/material/Avatar";
import { Typography } from "theme";
import { Variant } from "theme/components/Typography/Typography";
import { Delete } from "theme/icons";

type AvatarProps = Pick<MuiAvatarProps, "alt"> & {
  src?: string | null;
  size: "xs" | "sm" | "md" | "lg";
  fallback: string;
  isPending?: boolean;
  isDeleteEnabled?: boolean;
  onDelete?: () => void;
};

const getAvatarProps = (size: AvatarProps["size"]) => {
  switch (size) {
    case "xs":
      return { size: "2rem", variant: "body1" };
    case "sm":
      return { size: "2.5rem", variant: "h5" };
    case "md":
      return { size: "6rem", variant: "h2" };
    case "lg":
      return { size: "7.5rem", variant: "h1" };
    default:
      return { size: "2rem", variant: "body1" };
  }
};

const getAbbr = (value: string) => {
  if (!value) return "";

  return value.charAt(0).toUpperCase();
};

const AvatarWrap = styled(Box)(() => {
  return {
    borderRadius: "50%",
    position: "relative",

    "&:hover .MuiBox-root-delete": {
      transform: "scale(1)",
    },
  };
});

const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "size",
})<
  MuiAvatarProps & {
    size: string;
  }
>(({ theme, size }) => {
  return {
    backgroundColor: theme.palette.visualization.light,
    width: size,
    height: size,
  };
});

const Overlay = styled(Box, {
  shouldForwardProp: (propName: string) => !["isPending", "isDeleteEnabled"].includes(propName),
})<{ isPending?: boolean; isDeleteEnabled?: boolean }>(
  ({ theme, isPending = false, isDeleteEnabled = false }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.overlay,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: "50%",
    zIndex: 1,

    ...(isPending && {
      inset: 0,
    }),
    ...(!isPending &&
      isDeleteEnabled && {
      transform: "scale(0)",
    }),
  }),
);

const IconWrap = styled(Box)({
  cursor: "pointer",
});

const Avatar: FC<AvatarProps> = ({
  size,
  fallback,
  isPending,
  isDeleteEnabled,
  onDelete,
  src,
  ...props
}) => {
  const avatarProps = getAvatarProps(size);
  const {
    palette: { common },
  } = useTheme();

  const showDeleteButton = src && !isPending && isDeleteEnabled;

  return (
    <AvatarWrap>
      {isPending && (
        <Overlay isPending>
          <CircularProgress size={24} />
        </Overlay>
      )}
      {showDeleteButton && (
        <Overlay isDeleteEnabled className="MuiBox-root-delete">
          <IconWrap component="span" role="button" onClick={onDelete}>
            <Delete color={common.white} />
          </IconWrap>
        </Overlay>
      )}
      <StyledAvatar size={avatarProps.size} src={src ?? undefined} {...props}>
        <Typography component="span" variant={avatarProps.variant as Variant} color="inverse">
          {getAbbr(fallback)}
        </Typography>
      </StyledAvatar>
    </AvatarWrap>
  );
};

export default Avatar;
