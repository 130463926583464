import { ReactNode } from "react";
import { Grid } from "@mui/material";
import { BackButton, Button, Typography } from "theme";

export type ProfileSectionProps = {
  title: string;
  titleInfo?: string;
  placeholder?: string;
  children?: React.ReactNode;
  readOnly?: boolean;
  icon?: ReactNode;
  showTitle?: boolean;
  showEditButton?: boolean;
  shouldShowIcon?: boolean;
  onAddClick?: () => void;
};

export const ProfileSectionLayout: React.FC<ProfileSectionProps> = ({
  title,
  titleInfo,
  placeholder,
  children,
  readOnly = false,
  showTitle = true,
  shouldShowIcon = true,
  icon,
  onAddClick,
}) => {
  const isEditable = placeholder && !children && !readOnly;

  return (
    <Grid container flexDirection="column" rowGap={3}>
      <Grid item container rowGap={1} flexDirection="column">
        <Grid
          className="section-title"
          item
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            {showTitle ? (
              <Typography variant="h2" textTransform="capitalize" component="span">
                {title}
                <Typography
                  variant="h2"
                  weight="semiBold"
                  color="secondary"
                  component="span"
                  ml={0.5}
                >
                  {titleInfo}
                </Typography>
              </Typography>
            ) : (
              <BackButton />
            )}
          </Grid>
          <Grid item>
            <Grid container gap={2}>
              {shouldShowIcon && <>{icon}</>}
            </Grid>
          </Grid>
        </Grid>
        {isEditable && (
          <Grid item maxWidth="37rem">
            <Typography variant="body2" color="secondary">
              {placeholder}
            </Typography>
          </Grid>
        )}
      </Grid>
      {isEditable ? (
        <Grid item>
          <Button onClick={onAddClick} variant="contained" color="secondary">
            Add {title.toLowerCase()}
          </Button>
        </Grid>
      ) : (
        <Grid item width="100%">
          {children}
        </Grid>
      )}
    </Grid>
  );
};
