import { FC, ReactNode } from "react";
import { Box, styled } from "@mui/material";
import { Typography } from "theme";

const EmptyResultsWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 380,
  textAlign: "center",
  padding: "26vh 0",
  position: "sticky",
  margin: "0 auto",
  top: 0,

  "& svg": {
    display: "block",
    margin: "0 auto",
    marginBottom: theme.spacing(2.5),
  },
  "& .MuiTypography-root": {
    marginTop: theme.spacing(1),
  },
}));

type EmptyPlaceholderProps = {
  icon: ReactNode;
  title: string;
  subtitle: string;
};

const EmptyPlaceholder: FC<EmptyPlaceholderProps> = (props) => {
  return (
    <EmptyResultsWrapper>
      {props.icon}
      <Typography variant="h5" weight="semiBold">
        {props.title}
      </Typography>
      <Typography variant="body2" weight="regular" color="secondary">
        {props.subtitle}
      </Typography>
    </EmptyResultsWrapper>
  );
};

export default EmptyPlaceholder;
