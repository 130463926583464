import { FC, useMemo } from "react";
import { Grid, useTheme } from "@mui/material";
import { useOptionsList, useProfile } from "api/hooks";
import { OptionsParams } from "api/urls";
import ExpertiseLevel from "components/User/components/ExpertiseLevel";
import ProfileDetails from "components/User/components/ProfileDetails";
import { UserName } from "components/User/components/UserName";
import { useParams } from "react-router-dom";
import { Avatar, CopyButton, TooltipButton, Typography } from "theme";
import { CopyLink, Download, InfoFill } from "theme/icons";
import { nonNullable } from "utils/helpers";
import { getFirstnameLetter, getFullname } from "utils/helpers/getFullname";
import { mapExpertise, mapTravelStatus } from "utils/mappers/profileMappers";
import EditProfile from "./EditProfile";
import { ProfileSectionLayout, ProfileSectionProps } from "./ProfileSectionLayout";

type MyProfileProps = Pick<ProfileSectionProps, "title" | "readOnly">;

const MyProfile: FC<MyProfileProps> = ({ title, readOnly }) => {
  const { profileId } = useParams();
  const { profile } = useProfile(profileId);
  const {
    palette: { text },
  } = useTheme();
  const email = profile?.email as string;
  const fullName = getFullname(profile?.firstName, profile?.lastName);
  const { data } = useOptionsList(OptionsParams.Specializations);
  const profileLink = window.location.href;

  const expertise = useMemo(() => {
    const mappedExpertise = mapExpertise(profile?.expertise);

    return (data || [])
      .map((i) => mappedExpertise?.find((e) => e.label === i.name))
      .filter(nonNullable);
  }, [profile?.expertise, data]);

  if (!profile) {
    return null;
  }

  return (
    <ProfileSectionLayout
      icon={
        <>
          <TooltipButton
            variant="contained"
            tooltipTitle="Download profile"
            icon={<Download />}
            onClick={window.print}
          />
          {profileId ? (
            <CopyButton
              copySuccessText="Link copied!"
              icon={<CopyLink />}
              tooltipTitle="Copy link to profile"
              copyText={profileLink}
              variant="contained"
            />
          ) : (
            <EditProfile />
          )}
        </>
      }
      title={title}
      readOnly={readOnly}
      showTitle={!profileId}
    >
      <Grid container flexDirection="column" rowSpacing={3}>
        <Grid container item alignItems="center" flexWrap="nowrap" columnGap={4}>
          <Grid item>
            <Avatar
              src={profile?.avatar}
              size="lg"
              fallback={getFirstnameLetter(profile?.firstName)}
            />
          </Grid>
          <Grid item flex="auto">
            <UserName size="md" name={fullName} nickname={profile?.nickName} />
            <Grid container columnGap={8} mt={1}>
              {expertise && expertise.length > 0 ? (
                expertise.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      <ExpertiseLevel {...item} />
                    </Grid>
                  );
                })
              ) : (
                <Grid item container alignItems="center" columnGap={1}>
                  <InfoFill color={text.placeholder} />
                  <Typography variant="body2" color="secondary">
                    No expertise selected yet
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ProfileDetails
            email={email}
            size="md"
            location={profile.location}
            travelPreference={mapTravelStatus(profile.travel)}
          />
        </Grid>
      </Grid>
    </ProfileSectionLayout>
  );
};

export default MyProfile;
