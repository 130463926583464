import { FC, useState } from "react";
import { Profile } from "api/hooks/useProfile/type";
import CertificationsModal from "components/Modals/CertificationsModal";
import { Sections } from "components/User/UserProfile/UserProfile";
import { useParams } from "react-router-dom";
import { TooltipButton } from "theme";
import { Edit } from "theme/icons";
import { ProfileSectionLayout, ProfileSectionProps } from "./ProfileSectionLayout";

type EditCertificationProps = Pick<ProfileSectionProps, "readOnly" | "children"> & {
  certificates?: Profile["certificates"];
};

const EditCertification: FC<EditCertificationProps> = ({ certificates, ...props }) => {
  const { profileId } = useParams();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const shouldShowEditIcon = !profileId && certificates && certificates?.length > 0;

  return (
    <>
      <ProfileSectionLayout
        {...props}
        shouldShowIcon={shouldShowEditIcon}
        title={Sections.Certifications}
        placeholder="Highlight your attained certifications and recognitions."
        onAddClick={() => setEditModalOpen(true)}
        icon={
          <TooltipButton
            onClick={() => setEditModalOpen(true)}
            variant="contained"
            tooltipTitle="Edit Certifications"
            icon={<Edit />}
          />
        }
      />
      {certificates && (
        <CertificationsModal
          key={`${editModalOpen}`}
          defaultValues={{
            certifications: certificates.map((el) => el.id),
          }}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditCertification;
