import React, { FC } from "react";
import { Box, DialogContent } from "@mui/material";
import ModalActionButtons from "./ModalActionButtons";
import ModalContent, { ModalContentProps } from "./ModalContent";
import { StyledMuiModal } from "./styles";

type ModalProps = {
  open: boolean;
  actionButtons?: React.JSX.Element;
} & ModalContentProps;

const Modal: FC<ModalProps> = ({ open, actionButtons, children, ...rest }) => {
  if (actionButtons?.type.name !== ModalActionButtons.name) {
    console.warn(
      `Consider using "${ModalActionButtons.name}" component for the "actionButtons" prop.`,
    );
  }

  return (
    <StyledMuiModal disableEnforceFocus disableAutoFocus open={open}>
      <DialogContent>
        <ModalContent {...rest}>
          {children}

          {actionButtons && <Box pt={children ? 4 : undefined}>{actionButtons}</Box>}
        </ModalContent>
      </DialogContent>
    </StyledMuiModal>
  );
};

export default Modal;
