import ForgotPasswordForm from "components/Forms/ForgotPassword/ForgotPassword";
import SignPageLayout from "components/Layout/SignPageLayout";
import { useLocation } from "react-router-dom";
import { PartyPopper } from "theme/icons/colored";

const ForgotPassword = () => {
  const { state } = useLocation();

  if (state?.reset) {
    return (
      <SignPageLayout
        icon={<PartyPopper />}
        title="Check your email"
        description="Check your email for a link to reset your password. If it does not appear within a few minutes, check your spam folder."
      />
    );
  }

  return (
    <SignPageLayout
      iconType="logo"
      title="Reset your password"
      description="Enter your email to reset password."
    >
      <ForgotPasswordForm />
    </SignPageLayout>
  );
};

export default ForgotPassword;
