import {
  styled,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from "@mui/material";

export default styled(MuiAutocomplete)<
  MuiAutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>
>(({ theme }) => ({
  "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
    paddingTop: "1px",
    paddingBottom: "1px",
  },

  "& .MuiInputAdornment-root, & .MuiAutocomplete-endAdornment": {
    "& .MuiIconButton-root": {
      padding: theme.spacing(0.25),
    },

    "& svg": {
      color: theme.palette.common.black,
    },
  },

  "& .MuiOutlinedInput-root.Mui-focused": {
    outlineOffset: 0,
    outlineWidth: theme.spacing(0.25),
    outlineStyle: "solid",
    outlineColor: theme.palette.common.black,

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },

  "& .Mui-error.Mui-focused": {
    outlineColor: theme.palette.primary.main,
  },

  "& .Mui-error.Mui-focused:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },

  "& .Mui-error input.MuiInputBase-input::placeholder": {
    color: theme.palette.text.primary,
  },
}));
