import { useState } from "react";
import { Box, Divider, Grid, MenuList } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import SurveyStartModal from "components/Modals/SurveyStartModal";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Avatar, Typography, TooltipButton } from "theme";
import { Badge, LogOut, Settings, User } from "theme/icons";
import { useAuth } from "utils/context/Auth/AuthContext";
import { getFirstnameLetter, getFullname } from "utils/helpers/getFullname";
import { useFetch } from "utils/hooks";
import { useSurveyModal } from "utils/hooks/useSurveyModal";
import MenuItem from "./components/MenuItem";
import Popover from "./components/Popover";

const AccountSettingsWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  width: "18.5rem",
}));

const Account = () => {
  const { user, isTechnician, clearToken } = useAuth();
  const navigate = useNavigate();
  const request = useFetch();
  const { openSurveyModal, closeSurveyModal, isSurveyModalOpen } = useSurveyModal();

  const { mutate: logout, isPending } = useMutation({
    mutationFn: () => {
      return request(Urls.Logout, {
        method: "POST",
      });
    },
    onSuccess: () => {
      clearToken();
      navigate(Routes.Root, { replace: true });
    },
  });

  const userNameFirstLetter = getFirstnameLetter(user?.firstName);
  const fullName = getFullname(user?.firstName, user?.lastName);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverId = popoverOpen ? "account-settings" : undefined;

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  return (
    <>
      <SurveyStartModal open={isSurveyModalOpen} onClose={closeSurveyModal} />
      <Box component="span" role="button" onClick={handlePopoverClick}>
        <Avatar size="xs" fallback={userNameFirstLetter} src={user?.avatar ?? undefined} />
      </Box>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <AccountSettingsWrapper>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            columnGap={1.5}
            flexWrap="nowrap"
          >
            <Grid
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              item
              container
              alignItems="center"
              columnGap={1.5}
              flexWrap="nowrap"
            >
              <Grid item>
                <Avatar size="xs" fallback={userNameFirstLetter} src={user?.avatar ?? undefined} />
              </Grid>
              <Grid item textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                <Typography
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  weight="medium"
                  variant="body2"
                >
                  {fullName}
                </Typography>
                <Typography
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  variant="body2"
                  color="secondary"
                >
                  {user?.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <TooltipButton
                variant="contained"
                tooltipTitle="Sign out"
                onClick={() => logout()}
                disabled={isPending}
                loading={isPending}
                icon={<LogOut />}
              />
            </Grid>
          </Grid>
        </AccountSettingsWrapper>

        <Divider />

        <MenuList onClick={handlePopoverClose}>
          {isTechnician && <MenuItem icon={<User />} label="User profile" to={Routes.MyProfile} />}
          <MenuItem icon={<Settings />} label="Account settings" to={Routes.AccountSettings} />

          {isTechnician && <Divider />}
          {isTechnician && (
            <MenuItem
              icon={<Badge />}
              label="Update expertise level"
              onClick={() => openSurveyModal()}
            />
          )}
        </MenuList>
      </Popover>
    </>
  );
};

export default Account;
