import { FC, useCallback, useState } from "react";
import { Control, useController } from "react-hook-form";
import { Location } from "theme";
import { LocationProps } from "theme/components/Autocomplete/Location";
import { LocationOption } from "theme/utils";

type ControlledLocationProps = Omit<LocationProps, "value" | "onChange" | "multiple"> & {
  control: Control<any>;
};

const ControlledLocation: FC<ControlledLocationProps> = ({ name, control, ...props }) => {
  const { field } = useController({
    control,
    name,
  });
  const [location, setLocation] = useState<LocationOption | null>(field.value);

  const onChange = useCallback(
    (value: LocationOption) => {
      setLocation(value);

      field.onChange(value);
    },
    [field],
  );

  return <Location onChange={onChange} name={name} value={location} {...props} />;
};

export default ControlledLocation;
