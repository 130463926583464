import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";
import { Urls } from "api/urls";
import Layout from "components/Layout/components/Layout";
import { Routes } from "router/routes";
import { Loader } from "theme";
import { Features, Roles, User } from "utils/context/Auth/user";
import { TokenContext, parseToken } from "utils/helpers";
import { ExtendedCacheTime, request } from "utils/hooks/useFetch";

type AuthContextValues = {
  token: string | null;
  tokenContext: TokenContext | null;
  isAuthenticated: boolean;
  isTechnician: boolean;
  setToken: (token: string) => void;
  setUser: (user: User) => void;
  clearToken: () => void;
  user: User | null;
  isAccessEnabled: boolean;
  isInvitationModeEnabled: boolean;
};

const noop = () => {};

const AuthContext = createContext<AuthContextValues>({
  user: null,
  setUser: noop,
  token: null,
  tokenContext: null,
  isAuthenticated: false,
  isTechnician: false,
  setToken: noop,
  clearToken: noop,
  isAccessEnabled: true,
  isInvitationModeEnabled: false,
});

type AuthProviderProps = {
  children: React.ReactNode;
};

const getToken = (): string | null => {
  const token = localStorage.getItem("token");
  return token;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, updateToken] = useState<string | null>(getToken());

  const tokenContext = useMemo(() => parseToken(token), [token]);

  const userQuery = useQuery<User | undefined>({
    queryKey: ["account", token],
    queryFn: () =>
      request(Urls.Accounts, token).then((response) => {
        if (!response.ok) {
          window.location.href = Routes.SignIn;
          clearToken();
          return;
        }

        return response.json() as Promise<User>;
      }),
    enabled: Boolean(token),
    gcTime: ExtendedCacheTime,
    staleTime: ExtendedCacheTime,
  });

  const featureQuery = useQuery<Features | undefined>({
    queryKey: ["feature"],
    queryFn: () => request(Urls.SystemFeatures, null).then((response) => response.json()),
    gcTime: ExtendedCacheTime,
    staleTime: ExtendedCacheTime,
  });

  const isInvitationModeEnabled = useMemo(
    () => featureQuery.data?.invitationMode === "True",
    [featureQuery.data?.invitationMode],
  );

  const isTechnician = useMemo(
    () => userQuery.data?.role === Roles.Technician,
    [userQuery.data?.role],
  );

  const clearToken = useCallback(() => {
    updateToken(null);
    localStorage.removeItem("token");
    queryClient.invalidateQueries({ queryKey: ["account", token] });
  }, [token]);

  const setToken = useCallback((token: string) => {
    updateToken(token);
    localStorage.setItem("token", token);
  }, []);

  const setUser = useCallback(
    (user: User) => {
      queryClient.setQueryData<User>(["account", token], (oldData) => ({
        ...oldData,
        ...user,
      }));
    },
    [token],
  );

  if ((token && userQuery.isPending) || featureQuery.isPending) {
    return (
      <Layout
        mode="dark"
        backgroundImageSrc={process.env.PUBLIC_URL + "/images/sign-up-page-background.png"}
      >
        <Loader size={48} />
      </Layout>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user: userQuery.data ?? null,
        setUser,
        token,
        tokenContext,
        isAuthenticated: Boolean(token),
        isTechnician,
        setToken,
        clearToken,
        isAccessEnabled: !isInvitationModeEnabled || Boolean(token),
        isInvitationModeEnabled,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth was used outside of its Provider");
  }

  return authContext;
};
