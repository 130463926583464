import { useLayoutEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useProfile } from "api/hooks";
import { WorkExperienceFormScheme } from "components/Modals/WorkExperienceModal/validation";
import { useParams } from "react-router-dom";
import { Button, TooltipButton, Typography } from "theme";
import { Plus } from "theme/icons";
import useDetectPrint from "utils/hooks/useDetectPrint";
import ExperienceItem from "./components/ExperienceItem";

const ITEMS_TO_SHOW = 4;

type WorkExperienceProps = {
  unwraped?: boolean;
  onAddWorkExperience: () => void;
  variant?: "small" | "large";
  readonly?: boolean;
  onEditWorkExperience?: (data?: WorkExperienceFormScheme) => void;
};

const WorkExperience: React.FC<WorkExperienceProps> = ({
  unwraped = false,
  onAddWorkExperience,
  variant = "small",
  readonly = false,
  onEditWorkExperience,
}) => {
  const { profileId } = useParams();
  const { isPrinting } = useDetectPrint();
  const { profile } = useProfile(profileId);

  const { shows } = profile || {};

  const workExperience = useMemo(() => shows || [], [shows]);

  const len = workExperience.length;
  const [listLen, setListLen] = useState(0);

  useLayoutEffect(() => {
    if (isPrinting) {
      setListLen(len);
    } else {
      setListLen(len > ITEMS_TO_SHOW && !unwraped ? ITEMS_TO_SHOW : len);
    }
  }, [len, isPrinting, unwraped]);

  const showMoreActive = workExperience.length > ITEMS_TO_SHOW && listLen <= ITEMS_TO_SHOW;
  const showWorkExperience = workExperience.length > 0;
  const isSmall = variant === "small";
  const emptyEditorWorkExperienceSection = !isSmall && !showWorkExperience && !readonly;

  return (
    <Grid item container flexDirection="column" rowGap={3}>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <Typography variant={isSmall ? "h5" : "h2"}>
            {isSmall ? "Work experience" : "Work Experience"}
          </Typography>
          {isSmall && (
            <Typography variant="body2" mt={0.5} color="secondary" maxWidth="37rem">
              Tell us about the events you've participated in as a technician. Your events will be
              displayed in your account, sorted by date from newest to oldest.
            </Typography>
          )}
          {emptyEditorWorkExperienceSection && (
            <Typography variant="body2" color="secondary" maxWidth="37rem">
              Tell us about the shows you've participated in as a technician.
            </Typography>
          )}
        </Grid>
        {showWorkExperience && !readonly && (
          <TooltipButton
            onClick={onAddWorkExperience}
            variant="contained"
            tooltipTitle={isSmall ? "Add new Show" : "Add work experience"}
            icon={<Plus />}
          />
        )}
      </Grid>
      <Grid item mb={isSmall ? 4 : undefined} width="100%">
        {showWorkExperience ? (
          <Grid container rowGap={4}>
            <Grid item container rowGap={2} flexDirection="column">
              {workExperience.slice(0, listLen).map((exp, id) => (
                <ExperienceItem
                  key={id}
                  readOnly={readonly}
                  onEdit={onEditWorkExperience}
                  editLabel={isSmall ? undefined : "Edit work experience"}
                  isLast={listLen === id + 1}
                  experience={exp}
                />
              ))}
            </Grid>
            {showMoreActive && (
              <Grid item width="100%">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setListLen(workExperience.length)}
                >
                  Show more{" "}
                  <Typography ml={0.5} variant="body2" color="secondary" weight="medium">
                    (+{workExperience.length - ITEMS_TO_SHOW})
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid>
            {!readonly && (
              <Button
                onClick={onAddWorkExperience}
                variant="contained"
                color="secondary"
                startIcon={isSmall ? <Plus /> : undefined}
              >
                {isSmall ? "Add new show" : "Add work experience"}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WorkExperience;
