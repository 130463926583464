import { Popper, PopperProps, styled } from "@mui/material";
import { Button, TextField } from "theme";
import { default as Autocomplete } from "theme/components/Autocomplete/components/StyledAutocomplete";
import { TextFieldProps } from "theme/components/FormInputs/TextField";

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "1.5rem",
  padding: theme.spacing(1),

  "& svg": {
    color: `${theme.palette.common.white} !important`,
  },
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
    top: theme.spacing(0.5),
    right: theme.spacing(0.75),

    display: "flex",
    gap: theme.spacing(0.5),

    "& .MuiIconButton-root": {
      padding: 0,
    },

    "& .MuiAutocomplete-popupIndicator": {
      transform: "none",
      backgroundColor: "transparent",
    },

    "& .MuiAutocomplete-clearIndicator": {
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      visibility: "visible",
    },
  },
}));

const StyledDropdownContainer = styled(Popper)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(0, 0, 1.25, 1.25),
    boxShadow: theme.shadows[0],
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.tertiary.main,
    borderTop: "none",

    "& .MuiAutocomplete-listbox": {
      padding: 0,
      maxHeight: "18rem",
    },
  },
}));

export const StyledDropdown = (props: PopperProps) => (
  <StyledDropdownContainer
    {...props}
    placement="bottom"
    disablePortal={false}
    modifiers={[
      {
        name: "flip",
        enabled: true,
        phase: "main",
        options: {
          fallbackPlacements: ["bottom"],
        },
      },
      {
        name: "preventOverflow",
        enabled: true,
        phase: "main",
        options: {
          altAxis: true,
          altBoundary: true,
          tether: true,
          rootBoundary: "document",
          padding: 0,
        },
      },
    ]}
  />
);

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (propName) => propName !== "isRounded",
})<TextFieldProps & { isRounded?: boolean }>(({ theme, isRounded }) => ({
  borderRadius: isRounded ? theme.shape.borderRadius : theme.spacing(1.25, 1.25, 0, 0),
  display: "inline-block",

  "& .MuiInputBase-root": {
    borderRadius: isRounded ? theme.shape.borderRadius : theme.spacing(1.25, 1.25, 0, 0),
    padding: theme.spacing(1.5, 0),
    paddingLeft: theme.spacing(1.5),
    ...theme.typography.body1,

    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.tertiary.main,
      },
    },
  },

  "& .MuiOutlinedInput-root.Mui-focused": {
    outline: "none",
  },
}));
