import { memo } from "react";
import { Grid } from "@mui/material";
import { Typography } from "theme";
import { ErrorFill } from "theme/icons";

const DropdownServiceUnavailable = memo(() => (
  <Grid container alignItems="center" justifyContent="center" columnGap={1}>
    <ErrorFill />

    <Typography color="secondary" variant="body2">
      Service unavailable
    </Typography>
  </Grid>
));

export default DropdownServiceUnavailable;
