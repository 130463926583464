import { FC, ReactNode, useMemo } from "react";
import { Box, CircularProgress, Grid, styled } from "@mui/material";
import { Typography, Button } from "theme";
import { Clear } from "theme/icons";
import { ExclamationMark, Logo } from "theme/icons/colored";
import { ModalCard, ModalContentIcon } from "./styles";

export type ModalContentProps = {
  children?: ReactNode;
  title: string;
  description?: ReactNode;
  onClose?: () => void;
  size?: "small" | "medium" | "large";
} & (
  | {
      iconType: "loading" | "logo" | "exclamation";
      icon?: undefined;
    }
  | {
      iconType?: undefined;
      icon: ReactNode;
    }
);

const ClearButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: "1.5rem",
  position: "absolute",
  top: theme.spacing(4),
  right: theme.spacing(4),
}));

const ModalContent: FC<ModalContentProps> = ({ onClose, size = "small", ...props }) => {
  const icon = useMemo(() => {
    if (props.icon) {
      return <ModalContentIcon bgcolor="background.chart">{props.icon}</ModalContentIcon>;
    }

    switch (props.iconType) {
      case "loading":
        return (
          <ModalContentIcon>
            <CircularProgress size={24} />
          </ModalContentIcon>
        );
      case "exclamation":
        return (
          <ModalContentIcon bgcolor="background.accent">
            <ExclamationMark />
          </ModalContentIcon>
        );

      case "logo":
      default:
        return (
          <ModalContentIcon>
            <Logo />
          </ModalContentIcon>
        );
    }
  }, [props.icon, props.iconType]);

  return (
    <ModalCard size={size} position="relative">
      <Grid container rowSpacing={4}>
        <Grid item flexGrow={1}>
          <Grid
            container
            rowSpacing={2.5}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Grid item>{icon}</Grid>
            <Grid item textAlign="center">
              <Typography variant="h5">{props.title}</Typography>
              {props.description && (
                <Box mt={1} textAlign="start">
                  {typeof props.description === "string" ? (
                    <Typography variant="body2" color="secondary">
                      {props.description}
                    </Typography>
                  ) : (
                    props.description
                  )}
                </Box>
              )}
              {Boolean(onClose) && (
                <ClearButton variant="text" color="secondary" onClick={onClose}>
                  <Clear />
                </ClearButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        {props.children && (
          <Grid item flexGrow={1}>
            {props.children}
          </Grid>
        )}
      </Grid>
    </ModalCard>
  );
};

export default ModalContent;
