import React from "react";
import { ProfileCertificate } from "api/hooks/useProfile/type";
import { mapWorkCertItems } from "utils/mappers/profileMappers";
import TagsSection from "./TagsSection";

type CertificationsProps = {
  certificates: ProfileCertificate[];
  isDeleting?: boolean;
  onDelete: (items: string[]) => void;
  readOnly?: boolean;
};

export const Certifications: React.FC<CertificationsProps> = ({
  certificates,
  isDeleting,
  onDelete,
  readOnly,
}) => {
  return (
    <TagsSection
      items={mapWorkCertItems(certificates)}
      onDelete={onDelete}
      isDeleting={isDeleting}
      readOnly={readOnly}
    />
  );
};
