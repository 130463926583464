import Grid from "@mui/material/Grid";
import { Routes } from "router";
import { Button, Link, Typography } from "theme";

type SignUpSubmitProps = {
  isSubmitting: boolean;
};

const SignUpSubmit: React.FC<SignUpSubmitProps> = ({ isSubmitting }) => {
  return (
    <Grid container flexDirection="column" rowSpacing={2}>
      <Grid item>
        <Button
          loading={isSubmitting}
          type="submit"
          form="sign-up-form"
          color="primary"
          variant="contained"
          fullWidth
        >
          {isSubmitting ? "Loading" : "Sign Up"}
        </Button>
      </Grid>
      <Grid item textAlign="center">
        <Typography display="inline" variant="body2">
          Already have an account? <Link to={Routes.SignIn}>Sign In</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SignUpSubmit;
