import { Roles } from "utils/context/Auth/user";
import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { emailValidation, passwordValidation } from "utils/validation/utils";
import { z } from "zod";

export const signUpScheme = z.object({
  firstName: z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(250, { message: getErrorMessage("First name", ErrorMessage.FieldTooLong) }),
  lastName: z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(250, { message: getErrorMessage("Last name", ErrorMessage.FieldTooLong) }),
  email: emailValidation(),
  company: z
    .string()
    .max(250, { message: getErrorMessage("Company", ErrorMessage.FieldTooLong) })
    .optional(),
  password: passwordValidation("Password"),
});

export type SignUpFormScheme = z.infer<typeof signUpScheme>;

export type SignUpResponse = {
  id: string;
  profileId: string;
  email: string;
  company: string;
  createdTimeUtc: Date;
  role: Roles;
};
export type SignUpRequest = SignUpFormScheme & { role: Roles };
