import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import AspectLogo from "assets/logo/aspect-logo.png";
import ChristieLogo from "assets/logo/christie-lites-logo.png";
import JrlxLogo from "assets/logo/jrlx-logo.png";
import KineticLogo from "assets/logo/kinetic-logo.png";
import MEntertainmentLogo from "assets/logo/m-entertainment-logo.png";
import MertzLogo from "assets/logo/mertzcrew-logo.png";
import MusicMattersLogo from "assets/logo/music-matters-logo.png";
import DesignOasisLogo from "assets/logo/the-design-oasis-logo.png";
import { ReactComponent as VoltLogo } from "assets/logo/volt-logo.svg";
import { ProfileSearch, Typography } from "theme";
import { useAuth } from "utils/context/Auth/AuthContext";

const HomeSearch = () => {
  const { isAccessEnabled } = useAuth();

  return (
    <Grid container width="100%">
      <Grid container item rowSpacing={2} direction="column" width="100%">
        <Grid container item rowSpacing={2} direction="column" width="50rem">
          <Grid item mt={35} />
          <Grid item>
            <Typography variant="h1" color="inverse">
              Lighting the{" "}
              <Box color="primary.main" component="span">
                Network
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="information" weight="regular">
              A warm welcome to all Lighting, Automation and Rigging techs.{" "}
              {!isAccessEnabled && "Currently available for invited users only."}
            </Typography>
          </Grid>
          {isAccessEnabled && (
            <Grid item mt={2}>
              <ProfileSearch main />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          mt={15}
          justifyContent="space-evenly"
          alignItems="center"
          maxWidth="100%"
        >
          <Grid item>
            <img src={AspectLogo} alt="Aspect Lighting" style={{ width: "125px" }} />
          </Grid>
          <Grid item>
            <img src={ChristieLogo} alt="Christie Lites" style={{ width: "125px" }} />
          </Grid>
          <Grid item>
            <img src={JrlxLogo} alt="JRLX" style={{ width: "125px" }} />
          </Grid>
          <Grid item>
            <img src={KineticLogo} alt="Kinetic Lighting" style={{ height: "70px" }} />
          </Grid>
          <Grid item>
            <img src={MEntertainmentLogo} alt="M Entertainment" style={{ width: "125px" }} />
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-evenly" alignItems="center" maxWidth="100%">
          <Grid item>
            <img src={MertzLogo} alt="Mertzcrew" style={{ width: "125px" }} />
          </Grid>
          <Grid item>
            <img src={MusicMattersLogo} alt="Music Matters" style={{ width: "125px" }} />
          </Grid>
          <Grid item>
            <img src={DesignOasisLogo} alt="The Design Oasis" style={{ width: "125px" }} />
          </Grid>
          <Grid item>
            <VoltLogo width="125px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeSearch;
