import { Grid, GridProps, TypeBackground, styled } from "@mui/material";

type Size = "sm" | "md";

const getIconSize = (size: Size) => {
  switch (size) {
    case "sm":
      return { height: "2rem", width: "2rem" };
    case "md":
      return { height: "3rem", width: "3rem" };
    default:
      return { height: "2rem", width: "2rem" };
  }
};

const IconWrap = styled(Grid, {
  shouldForwardProp: (propName) => propName !== "bgcolor",
})<GridProps>(({ theme, bgcolor }) => {
  const bgColor =
    theme.palette.background[(bgcolor as keyof TypeBackground) || theme.palette.background.primary];

  return {
    borderRadius: "50%",
    backgroundColor: bgColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media print": {
      backgroundColor: `${bgColor} !important`,
      printColorAdjust: "exact",
    },
  };
});

type DetailIconProps = {
  children: React.ReactNode;
  size?: Size;
  bgcolor?: keyof TypeBackground;
};

const DetailIcon: React.FC<DetailIconProps> = ({ children, size = "sm", bgcolor = "primary" }) => {
  return (
    <IconWrap bgcolor={bgcolor} {...getIconSize(size)}>
      {children}
    </IconWrap>
  );
};

export default DetailIcon;
