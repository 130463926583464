import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { z } from "zod";

export const SummaryScheme = z.object({
  summary: z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(3000, { message: getErrorMessage("Summary", ErrorMessage.FieldTooLong) }),
});

export const SummarySchemeOptional = z.object({
  summary: z.string().max(3000, { message: getErrorMessage("Summary", ErrorMessage.FieldTooLong) }),
});

export type SummaryFormScheme = z.infer<typeof SummaryScheme>;
export type SummaryFormOptionalScheme = z.infer<typeof SummarySchemeOptional>;

const useSummaryForm = (
  props: { summary: string; required: boolean } = { summary: "", required: false },
) => {
  const form = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: zodResolver(props.required ? SummaryScheme : SummarySchemeOptional),
    defaultValues: {
      summary: props.summary,
    },
  });

  useEffect(() => {
    form.reset({ summary: props.summary });
  }, [props.summary, form]);

  return form;
};

export default useSummaryForm;
