import { useQuery } from "@tanstack/react-query";
import { Urls } from "api/urls";
import { useAuth } from "utils/context/Auth/AuthContext";
import { useFetch } from "utils/hooks";
import { CacheTime } from "utils/hooks/useFetch";
import { Profile } from "./type";

const useProfile = (profileId?: string) => {
  const fetch = useFetch();
  const { user } = useAuth();

  const id = profileId ?? user?.profileId;

  const {
    data: profile,
    isRefetching,
    isPending,
    isFetching,
    isError,
  } = useQuery<Profile | null, Error>({
    queryKey: ["profile", id],
    queryFn: () =>
      fetch<Profile>(Urls.Profile, {
        method: "GET",
        slug: { id },
      }),
    gcTime: CacheTime,
    staleTime: CacheTime,
  });

  return {
    profile,
    isPending,
    isRefetching,
    isFetching,
    isError,
  };
};

export default useProfile;
