import { AlertColor } from "@mui/material";
import { To } from "react-router-dom";
import { Routes } from "router";
import { Alert } from "theme";
import { ResponseError } from "utils/hooks/useFetch";
import { ServerErrorCode } from "utils/validation/errors";

type ErrorsAlertProps = {
  message: string | string[] | ResponseError;
  severity?: AlertColor;
  resendEmail?: () => void;
};

type GetAlertPropsReturn = {
  message: string;
  severity?: AlertColor;
  linkTo?: To;
  linkText?: string;
  onClick?: () => void;
};

const getAlertProps = (
  message: ResponseError,
  resendEmail?: () => void,
  severity?: AlertColor,
): GetAlertPropsReturn => {
  switch (message.errorCode) {
    case ServerErrorCode.EmailExists: {
      return {
        message: message.errorMessage,
        severity: "warning",
        linkTo: Routes.SignIn,
        linkText: "Sign in",
      };
    }
    case ServerErrorCode.EmailNotActivated: {
      return {
        message: message.errorMessage,
        severity: "warning",
        onClick: resendEmail,
        linkText: "Send confirmation email",
      };
    }
    case ServerErrorCode.EmailFailedToSend: {
      return {
        message: message.errorMessage,
        severity: "error",
        onClick: resendEmail,
        linkText: "Send confirmation email",
      };
    }
    default:
      return {
        message: message.errorMessage,
        severity,
      };
  }
};

export const ErrorsAlert: React.FC<ErrorsAlertProps> = ({ message, severity, resendEmail }) => {
  if (message instanceof ResponseError) {
    const props = getAlertProps(message, resendEmail, severity);

    return <Alert {...props} />;
  }

  return <Alert message={message} severity={severity} />;
};
