import { default as MuiLink } from "@mui/material/Link";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import Typography, { Variant } from "../Typography/Typography";

interface LinkProps extends RouterLinkProps {
  variant?: Variant;
}

const Link: React.FC<LinkProps> = ({ to, variant = "body2", ...props }) => (
  <Typography variant={variant} component="span" weight="medium">
    <MuiLink component={RouterLink} underline="none" display="inline-flex" to={to} {...props} />
  </Typography>
);

export default Link;
