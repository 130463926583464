import { useCallback } from "react";
import { Grid, styled } from "@mui/material";
import { useMutateProfile, useProfile } from "api/hooks";
import { Typography, Loader } from "theme";
import { useError } from "utils/hooks";
import { mapWorkCertItems } from "utils/mappers/profileMappers";
import { Certifications } from "./components/Certifications";
import EditCertification from "./components/EditCertification";
import EditSkills from "./components/EditSkills";
import EditSummary from "./components/EditSummary";
import EditWorkPreference from "./components/EditWorkPreference";
import ErrorPage from "./components/ErrorPage";
import MyProfile from "./components/MyProfile";
import ProfileWorkExperience from "./components/ProfileWorkExperience";
import SectionsDivider from "./components/SectionsDivider";
import TagsSection from "./components/TagsSection";
import ShowMoreContainer from "../components/ShowMoreContainer";

export enum Sections {
  MyProfile = "my profile",
  Skills = "skills",
  WorkPreference = "work preference",
  Summary = "summary",
  WorkExperience = "work experience",
  Certifications = "certifications",
}

const ProfileWrapper = styled(Grid)(({ theme }) => ({
  "@media print": {
    rowGap: theme.spacing(4),

    "& #my-profile .section-title": {
      display: "none",
    },
  },

  width: "100%",
}));

type UserProfileProps = {
  readOnly?: boolean;
  profileId?: string;
};

const UserProfile: React.FC<UserProfileProps> = ({ readOnly, profileId }) => {
  const { setServerError } = useError({});

  const { profile, isPending, isError } = useProfile(profileId);
  const { skills = [], workPreferences = [], certificates = [], shows = [] } = profile || {};

  const {
    skills: { mutate: updateProfileSkills, isPending: isSkillsMutating },
    workPreferences: { mutate: updateProfileWorkPreferences, isPending: isPreferencesMutating },
    certificates: { mutate: updateProfileCertificates, isPending: isCertificatesMutating },
  } = useMutateProfile(setServerError);

  const shouldShowSection = useCallback(
    (info?: unknown[]) => {
      const isSectionInfoEmpty = !info || info?.length === 0;
      const notShowSection = profileId && isSectionInfoEmpty;

      return !notShowSection;
    },
    [profileId],
  );

  if (isPending) {
    return <Loader size={48} />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const shouldShowSkills = shouldShowSection(skills);
  const shouldShowWorkPreferences = shouldShowSection(workPreferences);
  const shouldShowSummary = !(profileId && !profile?.summary);
  const shouldShowCertificates = shouldShowSection(certificates);
  const shouldShowWorkExperience = shouldShowSection(shows);

  return (
    <ProfileWrapper container flexDirection="column" mb={8}>
      <Grid width="inherit" id="my-profile" item>
        <MyProfile title={Sections.MyProfile} readOnly={readOnly} />
      </Grid>

      {shouldShowSummary && (
        <>
          <SectionsDivider />
          <Grid width="inherit" item>
            <EditSummary readOnly={readOnly} summary={profile?.summary}>
              {profile?.summary && (
                <Typography noWrap variant="body1" color="secondary" component="div">
                  <ShowMoreContainer key={profile?.summary} lines="10">
                    {profile?.summary}
                  </ShowMoreContainer>
                </Typography>
              )}
            </EditSummary>
          </Grid>
        </>
      )}

      {shouldShowWorkExperience && (
        <>
          <SectionsDivider />
          <Grid width="inherit" item>
            <ProfileWorkExperience readOnly={readOnly} />
          </Grid>
        </>
      )}
      {shouldShowSkills && (
        <>
          <SectionsDivider />
          <Grid width="inherit" item>
            <EditSkills readOnly={readOnly} skills={skills || []}>
              {skills?.length > 0 && (
                <TagsSection
                  items={skills}
                  onDelete={updateProfileSkills}
                  isDeleting={isSkillsMutating}
                  readOnly={readOnly}
                />
              )}
            </EditSkills>
          </Grid>
        </>
      )}
      {shouldShowWorkPreferences && (
        <>
          <SectionsDivider />
          <Grid width="inherit" item>
            <EditWorkPreference readOnly={readOnly} preference={workPreferences}>
              {workPreferences?.length > 0 && (
                <TagsSection
                  items={mapWorkCertItems(workPreferences)}
                  onDelete={updateProfileWorkPreferences}
                  isDeleting={isPreferencesMutating}
                  readOnly={readOnly}
                />
              )}
            </EditWorkPreference>
          </Grid>
        </>
      )}
      {shouldShowCertificates && (
        <>
          <SectionsDivider />
          <Grid width="inherit" item>
            <EditCertification readOnly={readOnly} certificates={certificates || []}>
              {certificates?.length > 0 && (
                <Certifications
                  certificates={certificates}
                  onDelete={updateProfileCertificates}
                  isDeleting={isCertificatesMutating}
                  readOnly={readOnly}
                />
              )}
            </EditCertification>
          </Grid>
        </>
      )}
    </ProfileWrapper>
  );
};

export default UserProfile;
