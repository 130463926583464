import { FC, useCallback, useRef } from "react";
import { Grid, styled } from "@mui/material";
import { useAvatar } from "api/hooks";
import { AvatarResponse, ACCEPT_FORMATS } from "api/hooks/useAvatar";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import { Avatar, Button, Typography } from "theme";

type AvatarUploaderProps = Partial<AvatarResponse> & {
  fallback?: string;
};

const HiddenInput = styled("input")`
  display: none;
`;

const AvatarUploader: FC<AvatarUploaderProps> = ({ avatar = "", fallback = "" }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    avatarSrc: newAvatar,
    isPending,
    isError,
    errorsList,
    handleChange,
    handleDelete,
  } = useAvatar(avatar);

  const handleClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return (
    <>
      <Typography variant="body2" mb={1.5}>
        Your avatar
      </Typography>
      <Grid container flexDirection="column" rowGap={2.5}>
        <Grid item container columnGap={2} flexWrap="nowrap">
          <Grid item>
            <Avatar
              fallback={fallback}
              src={newAvatar}
              isPending={isPending}
              size="md"
              isDeleteEnabled={!isPending}
              onDelete={handleDelete}
            />
          </Grid>
          <Grid item container rowGap={1} flexDirection="column">
            <Grid item>
              <Button variant="contained" onClick={handleClick}>
                {newAvatar ? "Change image" : "Choose image"}
              </Button>
              <HiddenInput
                ref={inputRef}
                type="file"
                onChange={handleChange}
                accept={ACCEPT_FORMATS}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" color="secondary">
                Allowed file types .png, .jpeg, .jpg. <br /> Max file size 2MB.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {isError && (
          <Grid item>
            <ErrorsAlert message={errorsList} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AvatarUploader;
