import useForgotPassword from "api/hooks/useForgotPassword/useForgotPassword";
import ResetPasswordForm from "components/Forms/ForgotPassword/ResetPassword";
import SignPageLayout from "components/Layout/SignPageLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Routes } from "router";
import { Button } from "theme";
import { ServerErrorCode } from "utils/validation/errors";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const token = params.get("token");
  const { validateResetPasswordToken } = useForgotPassword(token);

  if (validateResetPasswordToken.error?.errorCode === ServerErrorCode.NotFound) {
    return (
      <SignPageLayout
        iconType="exclamation"
        title="Link expired"
        description="The link has expired, please try resetting your password again."
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate(Routes.ForgotPassword)}
        >
          Reset password
        </Button>
      </SignPageLayout>
    );
  }

  if (!token || validateResetPasswordToken.isError) {
    return (
      <SignPageLayout
        iconType="exclamation"
        title="Failed to reset password"
        description="We're experiencing technical difficulties, please try following the link in the email again."
      />
    );
  }

  return (
    <SignPageLayout
      loading={validateResetPasswordToken.isPending}
      iconType="logo"
      title="Change your password"
      description={`Enter a new password for ${validateResetPasswordToken.data?.email}.`}
    >
      <ResetPasswordForm token={token} />
    </SignPageLayout>
  );
};

export default ResetPassword;
