import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Urls, OptionsParams } from "api/urls";
import { sortArray } from "utils/helpers";
import { useFetch } from "utils/hooks";
import { ExtendedCacheTime } from "utils/hooks/useFetch";
import {
  SpecializationIcons,
  WorkExperienceIcons,
} from "utils/mappers/mapSkillAndCertificationValues";
import { FormattedOptionsList, OptionsList } from "./type";

type OptionsFields = keyof Omit<FormattedOptionsList, "icon" | "order">;
type ToMuiOptionsFields = {
  value: OptionsFields;
  label: OptionsFields;
};

export const toMuiOptions = (
  array?: FormattedOptionsList[] | null,
  fields?: Partial<ToMuiOptionsFields>,
) => {
  if (!array?.length) {
    return [];
  }

  return array.map((i) => ({
    value: i[fields?.value || "id"],
    label: i[fields?.label || "name"],
    ...(i?.icon && { icon: i.icon }),
  }));
};

const useOptionsList = (type: OptionsParams) => {
  const fetch = useFetch();

  const { data, isPending } = useQuery<OptionsList[] | null, Error, FormattedOptionsList[] | null>({
    queryKey: ["lists", type],
    queryFn: () =>
      fetch<OptionsList[]>(Urls.Options, {
        method: "GET",
        slug: { type },
      }),
    gcTime: ExtendedCacheTime,
    staleTime: ExtendedCacheTime,
    select: (data) => {
      const formattedData = sortArray(data?.[0]?.options || [], "order");

      if (!formattedData?.length) {
        return null;
      }

      if (type === OptionsParams.Specializations) {
        return formattedData.map((el) => ({
          ...el,
          icon: SpecializationIcons[el.name as keyof typeof SpecializationIcons],
        }));
      }

      if (type === OptionsParams.ShowExperienceCategories) {
        return formattedData.map((el) => ({
          ...el,
          icon: WorkExperienceIcons[el.name as keyof typeof WorkExperienceIcons],
        }));
      }

      return formattedData;
    },
  });

  const muiOptions = useMemo(() => toMuiOptions(data), [data]);

  return {
    data,
    muiOptions,
    isPending,
  };
};

export default useOptionsList;
