import { FC, useState } from "react";
import { Grid, ListItem, styled } from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { SearchProfile } from "api/hooks/useSearch/type";
import { Avatar, HorizontalList, Typography } from "theme";
import { ArrowRight } from "theme/icons";
import { getGeoNameLabel } from "theme/utils";
import { getFirstnameLetter, getNickname } from "utils/helpers/getFullname";
import { mapProfileExpertiseToString, mapTravelStatus } from "utils/mappers/profileMappers";

type ProfileOptionProps = {
  profile: SearchProfile;
};

const NameWrap = styled(Grid)(({ theme }) => {
  return {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "fit-content(100%) minmax(auto, auto)",
    alignItems: "center",
    columnGap: theme.spacing(1),
    overflow: "hidden",
  };
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  columnGap: theme.spacing(1.5),
  padding: theme.spacing(0.75, 1.5),
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },

  [`&.${autocompleteClasses.option}`]: {
    "&[aria-selected='true']": {
      background: "none",
    },

    [`&.${autocompleteClasses.focused}, &[aria-selected='true'].${autocompleteClasses.focused}`]: {
      backgroundColor: theme.palette.secondary.light,
      cursor: "pointer",
    },
  },
}));

const ArrowWrapper = styled(Grid)(({ theme }) => ({
  marginLeft: "auto",
  height: theme.spacing(3),
}));

const ProfileOption: FC<ProfileOptionProps> = ({ profile }) => {
  const { avatar, name, nickname } = profile;

  const [showArrow, setShowArrow] = useState(false);

  const expertiseText = mapProfileExpertiseToString(profile.expertise);
  const showAccreditation = profile.expertise?.length > 0 && Boolean(expertiseText);

  return (
    <StyledListItem
      key={profile.profileId}
      onMouseEnter={() => setShowArrow(true)}
      onMouseLeave={() => setShowArrow(false)}
    >
      <Avatar src={avatar || ""} size="sm" fallback={getFirstnameLetter(name)} />
      <Grid flex={1}>
        <NameWrap>
          <Typography
            variant="body1"
            weight="medium"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {name}
          </Typography>
          {nickname && (
            <Typography
              variant="body2"
              color="secondary"
              weight="regular"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {getNickname(nickname)}
            </Typography>
          )}
        </NameWrap>
        <HorizontalList>
          {showAccreditation && (
            <Typography color="secondary" component="li" variant="body2">
              {expertiseText}
            </Typography>
          )}
          {Boolean(profile.location) && (
            <Typography color="secondary" component="li" variant="body2">
              {getGeoNameLabel(profile.location)}
            </Typography>
          )}
          <Typography color="secondary" component="li" variant="body2">
            {mapTravelStatus(profile.travel)}
          </Typography>
        </HorizontalList>
      </Grid>
      {showArrow && (
        <ArrowWrapper item>
          <ArrowRight />
        </ArrowWrapper>
      )}
    </StyledListItem>
  );
};

export default ProfileOption;
