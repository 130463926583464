export enum Roles {
  Technician = "technician",
  LaborCoordinator = "labor coordinator",
}

export interface User {
  accountId: string;
  profileId: string | null;
  firstName: string;
  lastName: string;
  nickName: string | null;
  email: string;
  role: Roles;
  isCompleted: boolean;
  company: string;
  avatar: string | null;
}

export type Features = {
  invitationMode: string;
};
