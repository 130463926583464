import { Grid, styled } from "@mui/material";
import Typography from "theme/components/Typography/Typography";

const OptionsInfoWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(1.5),
}));

type ProfileOptionInfoProps = {
  children: React.ReactNode;
};

const ProfileOptionInfo: React.FC<ProfileOptionInfoProps> = ({ children }) => {
  return (
    <OptionsInfoWrapper>
      <Typography variant="body2" weight="medium">
        Technicians{" "}
        <Typography variant="body2" weight="medium" component="span" color="secondary">
          {children}
        </Typography>
      </Typography>
    </OptionsInfoWrapper>
  );
};

export default ProfileOptionInfo;
