import { FC, useState } from "react";
import { Profile } from "api/hooks/useProfile/type";
import SummaryModal from "components/Modals/SummaryModal/SummaryModal";
import { Sections } from "components/User/UserProfile/UserProfile";
import { useParams } from "react-router-dom";
import { TooltipButton } from "theme";
import { Edit } from "theme/icons";
import { ProfileSectionLayout, ProfileSectionProps } from "./ProfileSectionLayout";

type EditSummaryProps = Pick<ProfileSectionProps, "readOnly" | "children"> & {
  summary?: Profile["summary"];
};

const EditSummary: FC<EditSummaryProps> = ({ summary, ...props }) => {
  const { profileId } = useParams();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const shouldShowEditIcon = !!(!profileId && summary);

  return (
    <>
      <ProfileSectionLayout
        {...props}
        shouldShowIcon={shouldShowEditIcon}
        title={Sections.Summary}
        placeholder="Give us a brief overview of your professional path and areas of expertise."
        icon={
          <TooltipButton
            onClick={() => setEditModalOpen(true)}
            variant="contained"
            tooltipTitle="Edit Summary"
            icon={<Edit />}
          />
        }
        onAddClick={() => setEditModalOpen(true)}
      />
      {summary !== undefined && (
        <SummaryModal
          key={`${editModalOpen}`}
          summary={summary || ""}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditSummary;
