import HomeSearch from "components/HomeSearch/HomeSearch";
import PageLayout from "components/Layout/PageLayout";

const Home = () => {
  return (
    <PageLayout
      mode="dark"
      backgroundImageSrc={process.env.PUBLIC_URL + "/images/home-page-background.svg"}
    >
      <HomeSearch />
    </PageLayout>
  );
};

export default Home;
