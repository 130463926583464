export enum ErrorMessage {
  FieldRequired = "Fill out all required fields before proceeding.",
  FieldTooLong = "{fieldName} field is too long.",
  FieldNotValid = "Enter a valid {fieldName} address.",
  FieldTooShort = "{fieldName} must be at least 8 characters long.",
  DateValidation = "{fieldName} cannot be earlier than start date.",
  PasswordsAreSame = "New password can't be the same as the old one.",
  SelectAtLeastOneField = "Select at least one {fieldName} to proceed.",
}

export enum ServerErrorMessage {
  EmailNotActivated = "This email already exists, but this account hasn't been activated.",
  EmailExists = "This email already exists. Sign In or use a different email.",
  EmailNotExists = "This email does not exist in the system.",
  TryAgainLater = "We're experiencing technical issues. Try again later.",
  EmailFailedToSend = "We encountered an issue while sending your confirmation email. Try again later.",
  IncorrectEmailOrPassword = "The email and password combination you entered is incorrect. Double-check your credentials and try again.",
  NewPasswordEquivalentOldPassword = "New password can't be the same as the old one.",
  IncorrectExistingPassword = "The current password is incorrect.",
  NewAndExistingPasswordsMustBeDifferent = "New password can't be the same as the old one.",
}

export enum ServerErrorCode {
  IncorrectEmailOrPassword = "incorrect_email_or_password",
  EmailNotActivated = "email_not_activated",
  EmailExists = "email_already_exists",
  EmailAlreadyActivated = "email_already_activated",
  EmailFailedToSend = "failed_send_email",
  ValidationError = "validation_error",
  TokenBadFormat = "notification_token_is_not_guid_format",
  NewPasswordEquivalentOldPassword = "new_password_equivalent_old_password",
  NotFound = "not_found",
  IncorrectExistingPassword = "incorrect_existing_password",
  NewAndExistingPasswordsMustBeDifferent = "new_and_existing_passwords_must_be_different",
}

export const serverErrorMap = (code: ServerErrorCode): ServerErrorMessage => {
  switch (code) {
    case ServerErrorCode.EmailExists:
      return ServerErrorMessage.EmailExists;
    case ServerErrorCode.EmailNotActivated:
      return ServerErrorMessage.EmailNotActivated;
    case ServerErrorCode.ValidationError:
      return ServerErrorMessage.TryAgainLater;
    case ServerErrorCode.EmailFailedToSend:
      return ServerErrorMessage.EmailFailedToSend;
    case ServerErrorCode.IncorrectEmailOrPassword:
      return ServerErrorMessage.IncorrectEmailOrPassword;
    case ServerErrorCode.NewPasswordEquivalentOldPassword:
      return ServerErrorMessage.NewPasswordEquivalentOldPassword;
    case ServerErrorCode.IncorrectExistingPassword:
      return ServerErrorMessage.IncorrectExistingPassword;
    case ServerErrorCode.NewAndExistingPasswordsMustBeDifferent:
      return ServerErrorMessage.NewAndExistingPasswordsMustBeDifferent;
    default:
      return ServerErrorMessage.TryAgainLater;
  }
};

export enum AvatarErrors {
  AvatarFileToLarge = "The selected avatar is too large. Please choose a file smaller than 2MB.",
  AvatarInvalidExtensions = "Only files with the following extensions are allowed: .png, .jpeg, .jpg",
}

export const getErrorMessage = (fieldName: string, errorMessage: ErrorMessage) => {
  return errorMessage.replace("{fieldName}", fieldName);
};
