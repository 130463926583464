import { useCallback, useEffect, useRef, useState } from "react";
import { default as debounce, Cancelable } from "@mui/utils/debounce";

const USERNAME = "pauldhingra";

/*
  GeoNames Codes info
  https://www.geonames.org/export/codes.html
*/
export type GeoName = {
  adminCode1: string;
  lng: string;
  geonameId: number;
  toponymName: string;
  countryId: string;
  fcl: "A" | "H" | "L" | "P" | "R" | "S" | "T" | "U" | "V";
  population: number;
  countryCode: string;
  name: string;
  fclName: string;
  countryName: string;
  fcodeName: string;
  adminName1: string;
  lat: string;
  fcode: string;
  adminCodes1: {
    ISO3166_2: string;
  };
};

export type GeoNameResponse = {
  totalResultsCount: number;
  geonames: GeoName[];
};

const geoNameSearchCache = new Map();

const geoNameSearch = (query: string): Promise<LocationOption[]> => {
  if (!query) {
    return Promise.resolve([]);
  }

  if (geoNameSearchCache.has(query)) {
    return Promise.resolve(geoNameSearchCache.get(query));
  }

  const queryParams = new URLSearchParams({
    // eslint-disable-next-line camelcase
    name_startsWith: query,
    featureClass: "P",
    orderby: "relevance",
    maxRows: "50",
    username: USERNAME,
  });

  return fetch(`https://secure.geonames.org/searchJSON?${queryParams.toString()}`)
    .then((resp) => resp.json())
    .then((data: GeoNameResponse) => data?.geonames || [])
    .then((data) => {
      const response = data.map((i) => ({
        geoNameId: i.geonameId.toString(),
        countryCode: i.countryCode,
        countryName: i.countryName,
        adminName: i.adminName1,
        adminCode: i.adminCode1,
        cityName: i.toponymName,
      }));

      geoNameSearchCache.set(query, response);

      return response;
    });
};

export const useGeoNamesSearch = ({
  query,
  enabled = true,
}: {
  query: string;
  enabled?: boolean;
}) => {
  const prevQuery = useRef<string>();
  const debouncedGeoNameSearch = useRef<((query: string) => void) & Cancelable>();
  const [data, setData] = useState<LocationOption[]>([]);
  const [isPending, setisPending] = useState(false);
  const [isError, setIsError] = useState(false);

  const search = useCallback(
    (query: string) => {
      geoNameSearch(query)
        .then(setData)
        .catch(() => {
          setIsError(true);
          setData([]);
        })
        .finally(() => setisPending(false));
    },
    [setisPending, setIsError, setData],
  );

  useEffect(() => {
    debouncedGeoNameSearch.current = debounce(search, 500);
  }, [search]);

  useEffect(() => {
    if (!enabled || query === prevQuery.current) {
      return;
    }

    prevQuery.current = query;

    !isPending && setisPending(true);
    isError && setIsError(false);
    data.length && setData([]);

    debouncedGeoNameSearch.current?.(query);
  }, [query, enabled, data, isError, isPending, setisPending, setIsError, setData]);

  return { isPending, isError, data };
};

export type LocationOption = {
  geoNameId: string;
  countryCode: string;
  countryName: string;
  adminName: string;
  adminCode: string;
  cityName: string;
};

export const getGeoNameLabel = (option?: LocationOption | null) => {
  if (!option) {
    return "";
  }

  return [option.cityName, option.adminName, option.countryName].filter(Boolean).join(", ");
};

export const isOptionEqualToValue = (option: unknown, value: unknown) => {
  return Boolean(
    (option as LocationOption)?.geoNameId &&
      (value as LocationOption)?.geoNameId &&
      (option as LocationOption).geoNameId === (value as LocationOption).geoNameId,
  );
};
