import { FC, ReactNode } from "react";
import { LoadingButtonProps } from "@mui/lab/LoadingButton/LoadingButton";
import { Grid } from "@mui/material";
import { Button } from "theme";

type ActionButtonProps = {
  label: string;
} & Pick<
  LoadingButtonProps,
  "color" | "variant" | "startIcon" | "onClick" | "loading" | "disabled"
>;

export type ModalActionButtonsProps = {
  buttons: ActionButtonProps[];
  children?: ReactNode;
};

const ModalActionButtons: FC<ModalActionButtonsProps> = (props) => {
  return (
    <Grid container spacing={2}>
      {props.buttons.map(({ label, variant, ...button }, index) => (
        <Grid item flexGrow={1} key={label + variant + index} width="50%">
          <Button variant={variant || "contained"} {...button} fullWidth>
            {label}
          </Button>
        </Grid>
      ))}

      {props.children && (
        <Grid item xs={12}>
          {props.children}
        </Grid>
      )}
    </Grid>
  );
};

export default ModalActionButtons;
