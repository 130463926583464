import { FC } from "react";
import { Box, CircularProgress, Grid, styled } from "@mui/material";
import { BackButton } from "theme";
import ModalContent, { ModalContentProps } from "theme/components/Modal/ModalContent";
import Layout from "./components/Layout";

type SignPageLayoutProps = ModalContentProps & {
  base?: boolean;
  loading?: boolean;
};

const Loading = styled(Box)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& svg": {
    display: "block",
    width: "100%",
    height: "100%",
  },
}));

const SignPageLayout: FC<SignPageLayoutProps> = ({ base, loading, ...rest }) => {
  return (
    <Layout
      mode="dark"
      backgroundImageSrc={process.env.PUBLIC_URL + "/images/sign-up-page-background.png"}
      base={base}
    >
      {!base && (
        <Box component="header" px={4} py={2}>
          <BackButton />
        </Box>
      )}
      <Grid
        component="main"
        justifyContent="center"
        container
        alignItems="center"
        my={1}
        height={base ? "100vh" : "auto"}
        flexGrow={1}
      >
        {loading ? (
          <Loading bgcolor="background.default">
            <CircularProgress size={42} />
          </Loading>
        ) : (
          <ModalContent {...rest} />
        )}
      </Grid>
    </Layout>
  );
};

export default SignPageLayout;
