import { SyntheticEvent, useCallback, useState } from "react";

const copyToClipboard = async (text: string) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    throw new Error("Clipboard is not supported by your browser");
  }
};

const useCopy = (copyText: string) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      copyToClipboard(copyText).then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2500);
      });
    },
    [copyText],
  );

  return {
    handleCopyClick,
    isCopied,
  };
};

export default useCopy;
