import { FC, memo, useMemo } from "react";
import { Grid, styled } from "@mui/material";
import { SearchProfile } from "api/hooks/useSearch/type";
import { generatePath, useNavigate } from "react-router-dom";
import { Routes } from "router/routes";
import { mapTravelStatus } from "utils/mappers/profileMappers";
import { Expertise } from "utils/types/common";
import ProfileInfo from "./components/ProfileInfo";
import SkillsList from "./components/SkillsList";
import ProfileDetails from "../components/ProfileDetails";

export type UserTileProps = {
  profile: SearchProfile;
};

const StyledWrap = styled(Grid)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.tertiary.main,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    cursor: "pointer",
    userSelect: "none",

    "&:hover, &:active, &:focus": {
      boxShadow: theme.shadows[2],
    },
  };
});

const UserTile: FC<UserTileProps> = memo(({ profile }) => {
  const navigate = useNavigate();

  const profilePath = useMemo(
    () => generatePath(Routes.Profile, { profileId: profile.profileId }),
    [profile.profileId],
  );

  const expertise = useMemo(() => {
    if (!profile.expertise) {
      return null;
    }

    return profile.expertise.map((i) => {
      return {
        specializationName: i.name,
        specializationId: i.name,
        levelName: i.level,
      } as Expertise;
    });
  }, [profile.expertise]);

  return (
    <StyledWrap container flexDirection="column" rowGap={2} onClick={() => navigate(profilePath)}>
      <Grid container item columnGap={1} flexWrap="nowrap">
        <ProfileInfo
          name={profile.name}
          nickname={profile.nickname}
          avatar={profile.avatar}
          expertise={expertise}
          copyText={window.location.origin + profilePath}
        />
      </Grid>
      <Grid item>
        <ProfileDetails
          travelPreference={mapTravelStatus(profile.travel)}
          location={profile.location}
          email={profile.email}
        />
      </Grid>
      {Boolean(profile.skills?.length) && (
        <Grid item>
          <SkillsList skills={profile.skills} />
        </Grid>
      )}
    </StyledWrap>
  );
});

export default UserTile;
