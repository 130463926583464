import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Tag = styled(Chip)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1.25, 1.5),
  borderRadius: theme.shape.borderRadius,
  height: "auto",
  backgroundColor: theme.palette.tertiary.main,
  userSelect: "none",

  "&:hover": {
    backgroundColor: theme.palette.tertiary.dark,
  },

  "& .MuiChip-label": {
    paddingLeft: 0,
  },

  "&:not(.MuiChip-deletable) > .MuiChip-label": {
    paddingRight: 0,
  },

  "& .MuiChip-deleteIcon": {
    margin: theme.spacing(-0.25, 0),
    color: theme.palette.text.primary,
  },

  "@media print": {
    backgroundColor: `${theme.palette.tertiary.main} !important`,
    printColorAdjust: "exact",

    "& .MuiChip-label": {
      padding: 0,
    },

    "& .MuiChip-deleteIcon": {
      display: "none",
    },
  },
}));

export default Tag;
