import { ChangeEvent, useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";
import { Urls } from "api/urls";
import { useAuth } from "utils/context/Auth/AuthContext";
import { User } from "utils/context/Auth/user";
import { useError, useFetch } from "utils/hooks";
import { ResponseError } from "utils/hooks/useFetch";
import { AvatarErrors } from "utils/validation/errors";
import { Profile } from "./useProfile/type";

export type AvatarResponse = {
  avatar: string | null;
};

export const ACCEPT_FORMATS = "image/png, image/jpeg";
export const ACCEPT_EXTENSIONS = "png, jpg, jpeg";
const MAX_FILE_SIZE = 1024 * 1024 * 2;

const useAvatar = (avatar: string | null) => {
  const request = useFetch();
  const { user, setUser } = useAuth();

  const [avatarSrc, setAvatarSrc] = useState<AvatarResponse["avatar"]>(avatar);
  const [validationError, setValidationError] = useState<AvatarErrors | null>(null);
  const { setServerError, errorsList, isError } = useError({
    validationError: {
      message: validationError as string,
    },
  });

  const { mutate: uploadAvatar, isPending } = useMutation({
    mutationFn: (data: FormData) => {
      return request<AvatarResponse>(Urls.ProfileAvatar, {
        method: "POST",
        body: data,
        slug: { profileId: user?.profileId },
      });
    },
    onMutate: () => {
      setValidationError(null);
      setServerError(null);
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      setAvatarSrc(data.avatar);

      setUser({ ...user, avatar: data.avatar } as User);

      if (user?.profileId) {
        queryClient.setQueryData<Profile>(["profile", user?.profileId], (oldData) => {
          return oldData
            ? {
              ...oldData,
              avatar: data.avatar,
            }
            : oldData;
        });
      }
    },
  });

  const { mutate: handleDelete, isPending: isDeleting } = useMutation({
    mutationFn: () => {
      return request<AvatarResponse>(Urls.ProfileAvatar, {
        method: "DELETE",
        slug: { profileId: user?.profileId },
      });
    },
    onMutate: () => {
      setValidationError(null);
      setServerError(null);
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
    onSuccess: () => {
      setAvatarSrc("");

      setUser({ ...user, avatar: null } as User);

      if (user?.profileId) {
        queryClient.setQueryData<Profile>(["profile", user?.profileId], (oldData) => {
          return oldData
            ? {
              ...oldData,
              avatar: null,
            }
            : oldData;
        });
      }
    },
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = [...(e.target.files || [])];

      e.target.value = "";

      if (!files.length) return;

      const currentFile = files[0];

      if (!currentFile?.type || !currentFile?.size || !currentFile?.name) return;

      if (!ACCEPT_FORMATS.includes(currentFile.type)) {
        setValidationError(AvatarErrors.AvatarInvalidExtensions);
        return;
      }

      const extension = currentFile.name.split(".").pop();
      if (!ACCEPT_EXTENSIONS.includes(extension || "")) {
        setValidationError(AvatarErrors.AvatarInvalidExtensions);
        return;
      }

      if (currentFile.size > MAX_FILE_SIZE) {
        setValidationError(AvatarErrors.AvatarFileToLarge);
        return;
      }

      const formData = new FormData();
      formData.append("avatar", currentFile);

      uploadAvatar(formData);
    },
    [setValidationError, uploadAvatar],
  );

  return {
    avatarSrc,
    errorsList,
    isError,
    isPending: isPending || isDeleting,
    uploadAvatar,
    setValidationError,
    handleChange,
    handleDelete,
  };
};

export default useAvatar;
