import { FC, ReactNode } from "react";
import { Box, Grid } from "@mui/material";
import { FormModal, Typography } from "theme";
import FormControls from "./FormControls";

type ModalFormLayoutProps = {
  formId: string;
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  title: string;
  description: string;
  children: ReactNode;
  isPending?: boolean;
  error?: ReactNode;
};

const FormModalLayout: FC<ModalFormLayoutProps> = ({
  formId,
  open,
  onSubmit,
  description,
  title,
  onClose,
  children,
  isPending,
  error,
}) => {
  return (
    <Box onSubmit={onSubmit} component="form" autoComplete="off" id={formId}>
      <FormModal
        open={open}
        title={title}
        onClose={onClose}
        actionButtons={<FormControls isPending={isPending} onClose={onClose} formId={formId} />}
      >
        <Grid container flexDirection="column" rowGap={2.5}>
          <Grid item>
            <Typography variant="body2" color="secondary">
              {description}
            </Typography>
          </Grid>
          <Grid item>{children}</Grid>
          {error && <Grid item>{error}</Grid>}
        </Grid>
      </FormModal>
    </Box>
  );
};

export default FormModalLayout;
