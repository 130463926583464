import { Box, CircularProgress, styled } from "@mui/material";
import { Typography } from "theme";

const EmptyResultsWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 380,
  textAlign: "center",
  padding: "34vh 0",
  position: "sticky",
  margin: "0 auto",
  top: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .MuiTypography-root": {
    marginLeft: theme.spacing(1),
  },
}));

const LoadingResults = () => {
  return (
    <EmptyResultsWrapper>
      <CircularProgress size={24} />
      <Typography variant="body2" weight="regular">
        Loading
      </Typography>
    </EmptyResultsWrapper>
  );
};

export default LoadingResults;
