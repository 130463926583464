import { FC } from "react";
import { useOptionsList } from "api/hooks";
import { OptionsParams } from "api/urls";
import ControlledAutocomplete, {
  ControlledAutocompleteProps,
} from "components/ControlledInputs/ControlledAutocomplete";

type CertificationsProps = Pick<ControlledAutocompleteProps, "control" | "name">;

const Certifications: FC<CertificationsProps> = (props) => {
  const { muiOptions } = useOptionsList(OptionsParams.Certifications);

  return (
    <ControlledAutocomplete
      placeholder="Search Certifications by keywords"
      options={muiOptions}
      {...props}
    />
  );
};

export default Certifications;
