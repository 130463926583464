import { FC } from "react";
import { useOptionsList } from "api/hooks";
import { OptionsParams } from "api/urls";
import ControlledAutocomplete, {
  ControlledAutocompleteProps,
} from "components/ControlledInputs/ControlledAutocomplete";

type SkillsProps = Pick<ControlledAutocompleteProps, "control" | "name">;

const Skills: FC<SkillsProps> = (props) => {
  const { muiOptions } = useOptionsList(OptionsParams.Skills);

  return (
    <ControlledAutocomplete
      placeholder="Search Skills by keywords"
      options={muiOptions}
      {...props}
    />
  );
};

export default Skills;
