import { useState } from "react";
import Grid from "@mui/material/Grid";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { ContentSwitch, ContentSwitchGroup } from "theme";
import { Roles } from "utils/context/Auth/user";
import { ResponseError } from "utils/hooks/useFetch";
import { ErrorsAlert } from "../../components/ErrorsAlert/ErrorsAlert";
import { SignUpFormScheme, SignUpRequest } from "../validation";

type SignUpFieldsProps = {
  registerUser: (data: SignUpRequest) => void;
  resendEmail: () => void;
  isSubmitting: boolean;
  serverError: ResponseError | null;
};

const SignUpFields: React.FC<SignUpFieldsProps> = ({
  registerUser,
  isSubmitting,
  resendEmail,
  serverError,
}) => {
  const [role, setRole] = useState<Roles>(Roles.Technician);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext<SignUpFormScheme>();

  const formErrorsList = Object.values(errors)
    .map((err) => err?.message)
    .filter(Boolean) as string[];
  const formErrors = Array.from(new Set([...formErrorsList]));
  const registrationErrors = formErrors.length > 0 ? formErrors : serverError;
  const isError = registrationErrors !== null;

  const onSubmit: SubmitHandler<SignUpFormScheme> = (data) => {
    registerUser({
      ...data,
      role,
      company: role === Roles.Technician ? "" : data.company,
    });
  };

  const handleChange = (_: React.MouseEvent<HTMLElement>, newToggle: string) => {
    setRole(newToggle as Roles);
  };

  return (
    <Grid
      container
      component="form"
      autoComplete="off"
      id="sign-up-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      flexDirection="column"
      rowSpacing={2.5}
    >
      <Grid item container>
        <ContentSwitchGroup
          exclusive
          disabled={isSubmitting}
          fullWidth
          value={role}
          onChange={handleChange}
        >
          <ContentSwitch value={Roles.Technician}>Technician</ContentSwitch>
          <ContentSwitch value={Roles.LaborCoordinator}>Labor Coordinator</ContentSwitch>
        </ContentSwitchGroup>
      </Grid>
      <Grid item>
        <ControlledTextField
          control={control}
          name="firstName"
          placeholder="Enter your first name"
          label="First name"
          required
          type="text"
          autoComplete="off"
          loading={isSubmitting}
        />
      </Grid>
      <Grid item>
        <ControlledTextField
          control={control}
          name="lastName"
          placeholder="Enter your last name"
          label="Last name"
          required
          type="text"
          autoComplete="off"
          loading={isSubmitting}
        />
      </Grid>
      <Grid item>
        <ControlledTextField
          control={control}
          name="email"
          placeholder="Enter your email"
          label="Email"
          type="text"
          required
          autoComplete="off"
          loading={isSubmitting}
        />
      </Grid>
      {role === Roles.LaborCoordinator && (
        <Grid item>
          <ControlledTextField
            control={control}
            name="company"
            placeholder="Enter your company name"
            label="Company"
            type="text"
            autoComplete="off"
            loading={isSubmitting}
          />
        </Grid>
      )}
      <Grid item>
        <ControlledTextField
          control={control}
          name="password"
          placeholder="8 characters minimum"
          label="Password"
          type="password"
          required
          autoComplete="off"
          loading={isSubmitting}
        />
      </Grid>
      {isError && (
        <Grid item>
          <ErrorsAlert message={registrationErrors} resendEmail={resendEmail} />
        </Grid>
      )}
    </Grid>
  );
};

export default SignUpFields;
