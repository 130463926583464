import { CircularProgress, Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";
import createTypography from "@mui/material/styles/createTypography";
import { CheckboxChecked, CheckboxUnchecked, RadioChecked, RadioUnchecked } from "./icons";

type StyleOverridesProps = { theme: Omit<Theme, "components"> };

const getCheckboxRoot = ({ theme }: StyleOverridesProps) => ({
  padding: theme.spacing(0.75),

  "&, &.Mui-disabled": {
    color: theme.palette.tertiary.main,
  },
  "&.Mui-checked": {
    color: theme.palette.secondary.main,
  },
});

const getScrollStyles = ({ theme }: StyleOverridesProps) => ({
  "&::-webkit-scrollbar": {
    width: 10,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.tertiary.dark,
    border: `solid ${theme.palette.background.default}`,
    borderWidth: "2px 3px",
  },
});

const palette = createPalette({
  primary: {
    main: "#F10C23",
    dark: "#9F1F14",
  },
  secondary: {
    main: "#000",
    dark: "#1A1A1A",
    light: "#F4F4F4",
  },
  text: {
    primary: "#000",
    inverse: "#FFF",
    disabled: "#000",
    secondary: "#666666",
    placeholder: "#808080",
    information: "#CCCCCC",
  },
  error: {
    main: "#F10C23",
    light: "#F10C231A",
  },
  success: {
    main: "#58D170",
  },
  background: {
    primary: "#F4F4F4",
    chart: "#E4E4E4",
    accent: "#f10c231a",
    overlay: "#00000099",
    alertInfo: "#3577F61A",
  },
});

const typography = createTypography(palette, {
  fontFamily: "Inter, sans-serif",
  fontWeightBold: 600,
  h1: {
    fontSize: "3rem",
  },
  h2: {
    fontSize: "2rem",
    lineHeight: 1.5,
  },
  h3: {
    fontSize: "1.75rem",
    lineHeight: 1.43,
  },
  h4: {
    fontSize: "1.5rem",
    lineHeight: 1.34,
  },
  h5: {
    fontSize: "1.25rem",
    lineHeight: 1.2,
  },
});

const themeConfig = createTheme({
  palette: {
    ...palette,
    tertiary: palette.augmentColor({
      color: {
        main: "#E4E4E4",
        dark: "#CCCCCC",
      },
      name: "tertiary",
    }),
    visualization: palette.augmentColor({
      color: {
        main: "#3577F6",
        dark: "#BA272E",
        light: "#884ED3",
      },
      name: "visualization",
    }),
  },
  typography,

  shape: {
    borderRadius: 10,
  },

  layout: {
    windowMinWidth: 1024,
    contentSmallMaxWidth: 1008,
    contentMaxWidth: 1216,
    modalWidth: {
      small: 448,
      medium: 588,
      large: 670,
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        color: "tertiary",
      },
    },
    // @ts-ignore
    MuiLoadingButton: {
      defaultProps: {
        loadingPosition: "start",
        loadingIndicator: <CircularProgress color="primary" size={16} />,
      },
    },
    MuiCheckbox: {
      styleOverrides: { root: getCheckboxRoot },
      defaultProps: {
        size: "small",
        checkedIcon: <CheckboxChecked />,
        icon: <CheckboxUnchecked />,
        disableRipple: true,
      },
    },
    MuiRadio: {
      styleOverrides: { root: getCheckboxRoot },
      defaultProps: {
        size: "small",
        checkedIcon: <RadioChecked />,
        icon: <RadioUnchecked />,
        disableRipple: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body2,
          userSelect: "none",
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiTypography-root": {
            ...theme.typography.body2,
            userSelect: "none",
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: ({ theme }) => ({
          "& > .MuiAutocomplete-paper": {
            ...getScrollStyles({ theme }),
          },

          "& .MuiAutocomplete-listbox": {
            ...getScrollStyles({ theme }),
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: getScrollStyles,
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: getScrollStyles,
      },
    },
  },
});

export default themeConfig;
