import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Button, Modal } from "theme";
import { PartyPopper } from "theme/icons/colored";
import { useAuth } from "utils/context/Auth/AuthContext";

type ProfileCreatedModalProps = {
  open: boolean;
};

const ProfileCreatedModal: React.FC<ProfileCreatedModalProps> = ({ open }) => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  return (
    <Modal
      open={open}
      title="Congratulations!"
      icon={<PartyPopper />}
      size="small"
      actionButtons={
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            if (user) {
              setUser({ ...user, isCompleted: true });
            }
            navigate(Routes.MyProfile, { replace: true });
          }}
        >
          Go to Profile
        </Button>
      }
      description="Your profile has been successfully created. Welcome to our community – your journey to new
      opportunities and connections begins now."
    />
  );
};

export default ProfileCreatedModal;
