import React, { ReactNode } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { useAuth } from "utils/context/Auth/AuthContext";
import { Routes } from "./routes";

export const InvitationModeRoute = ({ children }: { children: ReactNode }) => {
  const { isAccessEnabled } = useAuth();
  const invitationSignUpRouteMatch = useMatch(Routes.InvitationSignUp);

  if (invitationSignUpRouteMatch) {
    if (isAccessEnabled) {
      return <Navigate to={Routes.SignUp} replace />;
    }
  } else {
    if (!isAccessEnabled) {
      return <Navigate to={Routes.Root} replace />;
    }
  }

  return <>{children}</>;
};
