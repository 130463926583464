import { useQuery } from "@tanstack/react-query";
import { Urls } from "api/urls";
import { useFetch, useFilter } from "utils/hooks";
import { CacheTime, ResponseError } from "utils/hooks/useFetch";
import { PaginatedProfilesSearch, getFilters } from "./type";

const useSearch = (search: string | null) => {
  const { filter } = useFilter();
  const fetch = useFetch();

  const profilesSearch = useQuery<PaginatedProfilesSearch | null, ResponseError>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["profile-search", search, filter],
    queryFn: () => {
      const body = {
        searchText: search || "*",
        currentPage: 1,
        filters: getFilters(filter),
      };

      return fetch<PaginatedProfilesSearch>(Urls.ProfilesSearch, {
        method: "POST",
        body: JSON.stringify(body),
      });
    },
    enabled: false,
    gcTime: CacheTime,
    staleTime: CacheTime,
  });

  return { profilesSearch };
};

export default useSearch;
