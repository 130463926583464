import { FC, memo, useCallback, useMemo } from "react";
import { styled, Box, Divider } from "@mui/material";
import { useOptionsList } from "api/hooks";
import { FormattedOptionsList } from "api/hooks/useOptionsList/type";
import { toMuiOptions } from "api/hooks/useOptionsList/useOptionsList";
import { OptionsParams } from "api/urls";
import { Autocomplete, Location, SelectionGroup } from "theme";
import {
  ProfileQueryLabels,
  ProfileQueryValues,
  locationToQueryString,
  nonNullable,
  stringToLocation,
  travelOptions,
} from "utils/helpers";
import { QueryObject } from "utils/hooks/useFilter";

export type SearchFilterProps = {
  filter: QueryObject;
  onChange: (newFilter: QueryObject) => void;
};

const getFilterOptions = (options?: FormattedOptionsList[] | null) =>
  toMuiOptions(options, { value: "name" });

const SearchFilterWrapper = styled(Box)(({ theme }) => ({
  borderStyle: "solid",
  borderColor: theme.palette.tertiary.main,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const SearchFilterItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const SearchFilter: FC<SearchFilterProps> = memo(({ filter, onChange }) => {
  const { data: expertiseOptions, isPending: expertiseOptionsPending } = useOptionsList(
    OptionsParams.Specializations,
  );
  const { data: levelsOptions, isPending: levelsOptionsPending } = useOptionsList(
    OptionsParams.Levels,
  );
  const { data: workPreferenceOptions, isPending: workPreferenceOptionsPending } = useOptionsList(
    OptionsParams.ShowExperienceCategories,
  );
  const { data: skillsOptions } = useOptionsList(OptionsParams.Skills);

  const expertiseOptionsData = useMemo(
    () => getFilterOptions(expertiseOptions),
    [expertiseOptions],
  );
  const workPreferenceOptionsData = useMemo(
    () => getFilterOptions(workPreferenceOptions),
    [workPreferenceOptions],
  );
  const skillsOptionsData = useMemo(() => getFilterOptions(skillsOptions), [skillsOptions]);
  const levelsOptionsData = useMemo(() => getFilterOptions(levelsOptions), [levelsOptions]);

  const onRadioCheckboxChange = useCallback(
    (options: string[], propertyToUpdate: string) => {
      if (options.length === 0) {
        onChange({ [propertyToUpdate]: options });
        return;
      }

      onChange({ [propertyToUpdate]: options.slice(-1) });
    },
    [onChange],
  );

  return (
    <SearchFilterWrapper>
      <SearchFilterItem>
        <SelectionGroup
          direction="column"
          label={ProfileQueryLabels[ProfileQueryValues.Expertise]}
          variant="tiles"
          dense
          loading={expertiseOptionsPending}
          name={ProfileQueryValues.Expertise}
          type="checkbox"
          options={expertiseOptionsData}
          value={filter[ProfileQueryValues.Expertise] || []}
          onChange={(expertise) => {
            onChange({
              expertise,
              ...(!expertise?.length && { level: [] }),
            });
          }}
        />
      </SearchFilterItem>

      <Divider />

      <SearchFilterItem>
        <SelectionGroup
          direction="column"
          name={ProfileQueryValues.Level}
          label={ProfileQueryLabels[ProfileQueryValues.Level]}
          loading={levelsOptionsPending}
          disabled={!filter[ProfileQueryValues.Expertise]?.length}
          disabledTooltip="Select at least one expertise"
          type="checkbox"
          options={levelsOptionsData}
          value={filter[ProfileQueryValues.Level] || []}
          onChange={(level) => onChange({ level })}
        />
      </SearchFilterItem>

      <Divider />

      <SearchFilterItem>
        <Location
          name={ProfileQueryValues.Location}
          label={ProfileQueryLabels[ProfileQueryValues.Location]}
          value={(filter[ProfileQueryValues.Location] || [])
            .map(stringToLocation)
            .filter(nonNullable)}
          placeholder="Search location by City"
          onChange={(value) => {
            onChange({ location: value.map(locationToQueryString) });
          }}
          multiple
        />
      </SearchFilterItem>

      <Divider />

      <SearchFilterItem>
        <SelectionGroup
          direction="column"
          name={ProfileQueryValues.WorkPreference}
          label={ProfileQueryLabels[ProfileQueryValues.WorkPreference]}
          loading={workPreferenceOptionsPending}
          type="checkbox"
          options={workPreferenceOptionsData}
          value={filter[ProfileQueryValues.WorkPreference] || []}
          onChange={(workPreference) => onChange({ workPreference })}
        />
      </SearchFilterItem>

      <Divider />

      <SearchFilterItem>
        <Autocomplete
          multiple
          options={skillsOptionsData}
          name={ProfileQueryValues.Skills}
          label={ProfileQueryLabels[ProfileQueryValues.Skills]}
          value={filter[ProfileQueryValues.Skills] || []}
          onChange={(options) =>
            Array.isArray(options) &&
            onChange({
              skills: options,
            })
          }
          placeholder="Search Skills"
        />
      </SearchFilterItem>

      <Divider />

      <SearchFilterItem>
        <SelectionGroup
          direction="column"
          name={ProfileQueryValues.ReadyToTravel}
          label={ProfileQueryLabels[ProfileQueryValues.ReadyToTravel]}
          value={filter[ProfileQueryValues.ReadyToTravel] || []}
          type="checkbox"
          options={travelOptions}
          onChange={(travel) => onRadioCheckboxChange(travel, ProfileQueryValues.ReadyToTravel)}
        />
      </SearchFilterItem>
    </SearchFilterWrapper>
  );
});

export default SearchFilter;
