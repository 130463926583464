import { Grid, styled } from "@mui/material";
import useInfiniteSearch from "api/hooks/useSearch/useInfiniteSearch";
import UserTile from "components/User/UserTile/UserTile";
import { Alert } from "theme";
import { MagnifyingGlass, MagnifyingGlassError } from "theme/icons/colored";
import { nonNullable } from "utils/helpers";
import { ServerErrorMessage } from "utils/validation/errors";
import EmptyPlaceholder from "./EmptyPlaceholder";
import LoadingResults from "./LoadingResults";
import ShowMoreButton from "./ShowMoreButton";

const ProfilesListContainer = styled(Grid)(({ theme }) => ({
  "& + &": {
    marginTop: theme.spacing(2),
  },

  "& > .MuiGrid-item": {
    maxWidth: "100%",
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ProfilesList = () => {
  const { infiniteProfilesSearch, nextBatchCount } = useInfiniteSearch();
  const { isError, data, hasNextPage, isFetchingNextPage, fetchNextPage, isPending } =
    infiniteProfilesSearch;

  const profilePages = (data?.pages || []).filter(nonNullable);
  const isEmpty = !profilePages?.[0]?.searchProfiles.length;

  if (isEmpty) {
    if (isPending) {
      return <LoadingResults />;
    } else if (isError) {
      return (
        <EmptyPlaceholder
          icon={<MagnifyingGlassError />}
          title="Search failed"
          subtitle="An error occurred during the search, please try again later."
        />
      );
    }

    return (
      <EmptyPlaceholder
        icon={<MagnifyingGlass />}
        title="No results found"
        subtitle="Try to adjust your search or filter options to find what you're looking for."
      />
    );
  }

  return (
    <>
      {profilePages.map(({ searchProfiles }, index) => (
        <ProfilesListContainer key={index} container gap={2} direction="column">
          {(searchProfiles || []).map((profile) => (
            <Grid item key={profile.profileId}>
              <UserTile profile={profile} />
            </Grid>
          ))}
        </ProfilesListContainer>
      ))}

      {isError && <StyledAlert message={ServerErrorMessage.TryAgainLater} severity="error" />}

      {hasNextPage && (
        <ShowMoreButton
          loading={isFetchingNextPage}
          itemsCount={nextBatchCount}
          onClick={fetchNextPage}
        />
      )}
    </>
  );
};

export default ProfilesList;
