import { FC, ReactNode, memo, useMemo } from "react";
import {
  styled,
  CircularProgress,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
} from "@mui/material";
import { Typography } from "theme";
import { ChevronDown, StepperCompleted } from "theme/icons";

type AccordionProps = {
  name: string;
  label: string;
  progress?: number;
  progressMaxValue?: number;
  expanded?: boolean;
  onClick: (name: string) => void;
  children: ReactNode;
};

const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  marginBottom: theme.spacing(1),

  "&:before": {
    content: "none",
  },
}));
const StyledAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  minHeight: theme.spacing(5),
  backgroundColor: theme.palette.background.primary,
  borderRadius: theme.shape.borderRadius,

  "& .MuiAccordionSummary-content": {
    margin: 0,
    alignItems: "center",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.text.primary,
    transform: "rotate(-90deg)",

    "&.Mui-expanded": {
      transform: "rotate(0deg)",
    },
  },
}));
const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: 0,
}));

const StyledProgressWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  width: theme.spacing(3),
  height: theme.spacing(3),

  "& svg": {
    display: "block",
    boxShadow: `0 0 0 1px ${theme.palette.action.hover}`,
    borderRadius: "50%",
    color: theme.palette.success.main,
    backgroundColor: theme.palette.background.default,
  },
}));

const Accordion: FC<AccordionProps> = memo(
  ({ name, label, progress, progressMaxValue, expanded = false, onClick, children }) => {
    const progressIcon = useMemo(() => {
      if (progress === undefined) {
        return null;
      }

      return (
        <StyledProgressWrapper>
          {progressMaxValue && progress >= progressMaxValue ? (
            <StepperCompleted />
          ) : (
            <CircularProgress variant="determinate" thickness={6} value={progress} size={24} />
          )}
        </StyledProgressWrapper>
      );
    }, [progress, progressMaxValue]);

    return (
      <StyledAccordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={() => onClick(name)}
      >
        <StyledAccordionSummary expandIcon={<ChevronDown />}>
          {progressIcon}

          <Typography variant="body2" component="div" weight="medium">
            {label}
          </Typography>
        </StyledAccordionSummary>

        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
    );
  },
);

export default Accordion;
