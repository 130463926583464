import { useProfile } from "api/hooks";
import PageLayoutNarrow from "components/Layout/PageLayoutNarrow";
import UserProfile from "components/User/UserProfile/UserProfile";
import SurveyBanner from "components/User/UserProfile/components/SurveyBanner";
import { Navigate, useParams } from "react-router-dom";
import { Routes } from "router";
import { useAuth } from "utils/context/Auth/AuthContext";
import { useSurveyModal } from "utils/hooks/useSurveyModal";
import { Level } from "utils/types/common";

const Profile = () => {
  const { user } = useAuth();
  const { openSurveyModal } = useSurveyModal();
  const { profileId } = useParams();
  const { profile } = useProfile(profileId);

  const readOnly = Boolean(profileId);

  if (!readOnly && !user?.isCompleted) {
    return <Navigate to={Routes.ProfileCreation} />;
  }

  const showBanner =
    Boolean(profile) &&
    (!profile?.expertise ||
      profile.expertise.length === 0 ||
      profile.expertise.some((exp) => exp.levelName === Level.NoLevel || !exp.levelName));

  return (
    <PageLayoutNarrow
      mode="light"
      backgroundColor="primary"
      banner={!readOnly && showBanner && <SurveyBanner openSurveyModal={() => openSurveyModal()} />}
    >
      <UserProfile readOnly={readOnly} profileId={profileId} />
    </PageLayoutNarrow>
  );
};

export default Profile;
