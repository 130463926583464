import { FC } from "react";
import { Grid, GridProps, Link as MuiLink, TypeText, styled } from "@mui/material";
import { Typography } from "theme";
import { Help } from "theme/icons";

type FooterProps = {
  mode: "light" | "dark";
};

const FooterWrapper = styled("footer")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1.5, 4),
  marginTop: "auto",
  justifyContent: "space-between",
  alignItems: "center",

  "@media print": {
    display: "none",
  },
}));

const EmailWrapper = styled(Grid, {
  shouldForwardProp: (propName) => propName !== "bgcolor",
})<GridProps>(({ bgcolor, theme }) => {
  const iconColor = theme.palette.text[bgcolor as keyof TypeText];
  return {
    alignItems: "center",
    columnGap: theme.spacing(1),

    "& path": {
      stroke: iconColor,
    },
  };
});

const Footer: FC<FooterProps> = ({ mode }) => {
  const color = mode === "light" ? "secondary" : "placeholder";
  const emailColor = mode === "light" ? "primary" : "inverse";

  const email = "support@crewlink.pro";

  return (
    <FooterWrapper>
      <Typography variant="body2" color={color}>
        &copy; 2023 CrewLink. All rights reserved
      </Typography>
      <MuiLink href={`mailto:${email}`} underline="none">
        <EmailWrapper container bgcolor={emailColor}>
          <Help />
          <Typography variant="body2" color={emailColor}>
            {email}
          </Typography>
        </EmailWrapper>
      </MuiLink>
    </FooterWrapper>
  );
};

export default Footer;
