import { FC, useCallback } from "react";
import { useMutateProfile } from "api/hooks";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import WorkPreference from "components/Options/WorkPreference";
import { SubmitHandler, useForm } from "react-hook-form";
import { useError } from "utils/hooks";
import { FormModalProps } from "utils/types/common";
import FormModalLayout from "./components/FormModalLayout";
import { handlePromiseError } from "../../utils/helpers";

type WorkPreferenceValues = { preference: string[] };
type WorkPreferenceModalProps = FormModalProps & {
  defaultValues: WorkPreferenceValues;
};

const WORK_PREFERENCE_FORM_ID = "work-preference-form";

const WorkPreferenceModal: FC<WorkPreferenceModalProps> = ({ open, onClose, defaultValues }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WorkPreferenceValues>({
    defaultValues,
  });

  const { errorsList, isError, setServerError } = useError(errors);
  const {
    workPreferences: { mutateAsync: updateWorkPreference, isPending: isWorkPreferenceLoading },
  } = useMutateProfile(setServerError);

  const onModalClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit: SubmitHandler<WorkPreferenceValues> = async (data) => {
    await handlePromiseError(() => updateWorkPreference(data.preference));
    if (!isError) {
      onModalClose();
    }
  };

  return (
    <FormModalLayout
      formId={WORK_PREFERENCE_FORM_ID}
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onModalClose}
      isPending={isWorkPreferenceLoading}
      title="Edit Work Preference"
      description="Tell us about the types of events for which you would be interested in being hired."
      error={isError ? <ErrorsAlert message={errorsList} /> : undefined}
    >
      <WorkPreference wrap width="30%" name="preference" control={control} />
    </FormModalLayout>
  );
};

export default WorkPreferenceModal;
