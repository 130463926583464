export type Questionnaire = {
  id: string;
  text: string;
  section: string;
  specialization: string;
  order: number;
};

export type GroupedSurveyData = Map<string, Questionnaire[]>;

export enum Option {
  YES = "YES",
  NO = "NO",
}

export const options = [
  { value: Option.YES, label: "Yes" },
  { value: Option.NO, label: "No" },
];
