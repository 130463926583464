import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { z } from "zod";

export const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const passwordValidation = (fieldName: string) => {
  return z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .min(8, { message: getErrorMessage(fieldName, ErrorMessage.FieldTooShort) })
    .max(250, { message: getErrorMessage(fieldName, ErrorMessage.FieldTooLong) });
};

export const emailValidation = (fieldName = "Email") => {
  return z
    .string()
    .nonempty({ message: ErrorMessage.FieldRequired })
    .max(250, { message: getErrorMessage(fieldName, ErrorMessage.FieldTooLong) })
    .regex(emailRegex, { message: getErrorMessage("email", ErrorMessage.FieldNotValid) });
};
