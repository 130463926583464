import { FC, useCallback, useState } from "react";
import { Control, useController } from "react-hook-form";
import { Datepicker } from "theme";
import { DatepickerProps } from "theme/components/Datepicker/Datepicker";

type ControlledDatepickerProps = Omit<DatepickerProps, "value" | "onChange"> & {
  control: Control<any>;
};

const ControlledDatepicker: FC<ControlledDatepickerProps> = ({ name, control, ...props }) => {
  const { field } = useController({
    control,
    name,
  });
  const [date, setDate] = useState<Date | null>(field.value || null);

  const onChange = useCallback(
    (newDate: Date | null) => {
      field.onChange(newDate);
      setDate(newDate);
    },
    [field],
  );

  return <Datepicker value={date} onChange={onChange} name={name} {...props} />;
};

export default ControlledDatepicker;
