import { Grid } from "@mui/material";
import { Routes } from "router";
import { Link } from "theme";
import { ArrowRightSmall } from "theme/icons";
import { SearchParamKey } from "utils/helpers";

const ProfileSeeAll = ({ search }: { search: string }) => {
  return (
    <Grid container justifyContent="center" py={0.5}>
      <Link
        to={{
          pathname: Routes.Search,
          search: new URLSearchParams({ [SearchParamKey]: search }).toString(),
        }}
      >
        <Grid container alignItems="center">
          See All
          <ArrowRightSmall />
        </Grid>
      </Link>
    </Grid>
  );
};

export default ProfileSeeAll;
