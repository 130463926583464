import { FC, useCallback } from "react";
import { useMutateProfile } from "api/hooks";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import Certifications from "components/Options/Certifications";
import { SubmitHandler, useForm } from "react-hook-form";
import { useError } from "utils/hooks";
import { FormModalProps } from "utils/types/common";
import FormModalLayout from "./components/FormModalLayout";
import { handlePromiseError } from "../../utils/helpers";

type CertificationModalValues = {
  certifications: string[];
};
type CertificationModalProps = FormModalProps & {
  defaultValues: CertificationModalValues;
};

const CERTIFICATION_FORM_ID = "certification-form";

const CertificationsModal: FC<CertificationModalProps> = ({ open, onClose, defaultValues }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CertificationModalValues>({
    defaultValues,
  });

  const { errorsList, isError, setServerError } = useError(errors);

  const {
    certificates: { mutateAsync: updateCertificate, isPending: isCertificatesLoading },
  } = useMutateProfile(setServerError);

  const onModalClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit: SubmitHandler<CertificationModalValues> = async (data) => {
    await handlePromiseError(() => updateCertificate(data.certifications));
    if (!isError) {
      onModalClose();
    }
  };

  return (
    <FormModalLayout
      formId={CERTIFICATION_FORM_ID}
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onModalClose}
      isPending={isCertificatesLoading}
      title="Edit Certifications"
      description="Highlight your attained certifications and recognitions."
      error={isError ? <ErrorsAlert message={errorsList} /> : undefined}
    >
      <Certifications name="certifications" control={control} />
    </FormModalLayout>
  );
};

export default CertificationsModal;
