import { ReactNode } from "react";
import {
  Grid,
  InputAdornment,
  InputLabel,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export type TextFieldProps = Omit<
  MuiTextFieldProps,
  "InputLabelProps" | "margin" | "fullWidth" | "variant" | "color" | "size" | "minRows"
> & {
  note?: string;
  name: string;
  startIcon?: ReactNode;
  link?: ReactNode;
  endAdornment?: ReactNode;
};

const Label = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  userSelect: "none",
  marginBottom: theme.spacing(1),
}));

const LabelNote = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  color: theme.palette.text.placeholder,
}));

const LabelAsterisk = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  color: theme.palette.primary.main,

  "&:before": { content: "'*'" },
}));

const Input = styled(MuiTextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,

  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: 1,
    borderColor: theme.palette.tertiary?.main,
  },

  "& input.MuiInputBase-input": {
    padding: theme.spacing(1.25, 2),
  },
  "& .MuiInputAdornment-root + input.MuiInputBase-input": {
    paddingLeft: 0,
  },

  "& .Mui-error": {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,

    "&.MuiFormHelperText-root": {
      background: "none",
    },
  },

  "& .Mui-error input.MuiInputBase-input::placeholder": {
    color: theme.palette.error.main,
    opacity: 1,
  },

  "& .MuiInputBase-root": {
    ...theme.typography.body2,

    "&:focus": {
      outlineOffset: theme.spacing(0.25),
      outlineWidth: theme.spacing(0.25),
      outlineStyle: "solid",
      outlineColor: theme.palette.tertiary?.dark,
    },

    "&.Mui-error:focus": {
      outlineOffset: theme.spacing(0.25),
      outlineWidth: theme.spacing(0.25),
      outlineStyle: "solid",
      outlineColor: theme.palette.primary?.main,
    },

    "&.Mui-focused, &:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 1,
        borderColor: theme.palette.tertiary?.dark,
      },
    },

    "&.Mui-error:active, &.Mui-error:hover": {
      backgroundColor: theme.palette.common.white,
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 1,
        borderColor: theme.palette.primary?.main,
      },
    },

    "&.Mui-error:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 2,
      },
    },
  },

  "& .MuiInputBase-multiline": {
    display: "flex",
    flexDirection: "column",

    "& .MuiInputBase-inputMultiline": {
      marginBottom: theme.spacing(1),
    },

    "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
      height: "auto",
      margin: 0,
      alignSelf: "start",
    },
  },
}));

const TextField = ({
  label,
  note,
  name,
  multiline,
  startIcon,
  id,
  link,
  endAdornment,
  ...rest
}: TextFieldProps) => {
  return (
    <>
      {label && (
        <Label shrink={false} htmlFor={id || name}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {label}
              {note && <LabelNote>({note})</LabelNote>}
              {rest.required && <LabelAsterisk />}
            </Grid>
            {Boolean(link) && <Grid item>{link}</Grid>}
          </Grid>
        </Label>
      )}
      <Input
        id={id || name}
        fullWidth
        name={name}
        variant="outlined"
        size="small"
        multiline={multiline}
        minRows={multiline && !rest.rows ? 5 : undefined}
        {...rest}
        InputProps={{
          ...rest.InputProps,
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : (
            rest.InputProps?.startAdornment
          ),
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : (
            rest.InputProps?.endAdornment
          ),
        }}
      />
    </>
  );
};

export default TextField;
