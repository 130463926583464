import { FC, memo, useState, useCallback } from "react";
import { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { Tag, TextField } from "theme";
import { Search, Clear, ChevronDown, ClearSmall, MapPinSmall } from "theme/icons";
import {
  LocationOption,
  getGeoNameLabel,
  isOptionEqualToValue,
  useGeoNamesSearch,
} from "theme/utils";
import DropdownContainer from "./components/DropdownContainer";
import DropdownLoader from "./components/DropdownLoader";
import DropdownNoOptions from "./components/DropdownNoOptions";
import DropdownOption from "./components/DropdownOption";
import DropdownServiceUnavailable from "./components/DropdownServiceUnavailable";
import SelectedValuesContainer from "./components/SelectedValuesContainer";
import StyledAutocomplete from "./components/StyledAutocomplete";

export type LocationProps = {
  name: string;
  label?: string;
  placeholder?: string;
  error?: boolean;
  required?: boolean;
  readonly?: boolean;
} & (
  | {
      multiple?: false;
      value: LocationOption | null;
      onChange: (options: LocationOption) => void;
    }
  | {
      multiple: true;
      value: LocationOption[];
      onChange: (options: LocationOption[]) => void;
    }
);

const MAXIMUM_SELECTED_ITEM = 20;

const Location: FC<LocationProps> = memo((props) => {
  const { readonly = false, multiple = false } = props;

  const [searchValue, setSearchValue] = useState<string>("");

  const handleDeleteValue = (option: LocationOption) => () => {
    if (props.multiple) {
      const newOptions = props.value.filter((v) => v.geoNameId !== option.geoNameId);

      props.onChange(newOptions);
    }
  };

  const isEnabledSearch = searchValue.length >= 2;

  const { data, isPending, isError } = useGeoNamesSearch({
    query: searchValue,
    enabled: isEnabledSearch,
  });

  const handleInputChange = (
    _: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === "reset") {
      return;
    }

    setSearchValue(value);
  };

  const handleRenderTags = useCallback(() => null, []);
  const getOptionLabel = useCallback(
    (option: unknown) => getGeoNameLabel(option as LocationOption),
    [],
  );

  return (
    <>
      <StyledAutocomplete
        open={isEnabledSearch}
        onInputChange={handleInputChange}
        loading={isPending}
        disableCloseOnSelect={multiple}
        multiple={multiple}
        options={data}
        value={props.value}
        readOnly={readonly}
        renderTags={handleRenderTags}
        loadingText={<DropdownLoader />}
        noOptionsText={isError ? <DropdownServiceUnavailable /> : <DropdownNoOptions />}
        isOptionEqualToValue={isOptionEqualToValue}
        onClose={() => setSearchValue("")}
        getOptionLabel={getOptionLabel}
        PopperComponent={DropdownContainer}
        clearIcon={!multiple && <ClearSmall />}
        popupIcon={<ChevronDown />}
        onChange={(_, newValue) => {
          if (multiple && newValue.length > MAXIMUM_SELECTED_ITEM) {
            return;
          }

          props.onChange(newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              name={props.name}
              label={props.label}
              error={props.error}
              required={props.required}
              startIcon={<Search />}
              placeholder={props.placeholder}
              helperText={
                props.multiple && props.value.length === MAXIMUM_SELECTED_ITEM
                  ? `${MAXIMUM_SELECTED_ITEM} items max.`
                  : undefined
              }
            />
          );
        }}
        renderOption={(props, option, { selected }) => (
          <DropdownOption
            {...props}
            icon={<MapPinSmall />}
            selected={selected}
            label={option.cityName}
            note={`${option.adminName}, ${option.countryName}`}
          />
        )}
      />

      {props.multiple && Boolean(props.value.length) && (
        <SelectedValuesContainer>
          {props.value.map((option: LocationOption) => (
            <Tag
              key={option.geoNameId}
              label={getGeoNameLabel(option)}
              deleteIcon={<Clear />}
              onDelete={handleDeleteValue(option)}
            />
          ))}
        </SelectedValuesContainer>
      )}
    </>
  );
});

export default Location;
