import { FC, ReactNode } from "react";
import { Box, styled } from "@mui/material";
import Header from "components/Header/Header";
import Layout, { LayoutProps } from "./components/Layout";

type PageLayoutNarrowProps = Pick<
  LayoutProps,
  "backgroundImageSrc" | "backgroundColor" | "mode" | "children"
> & {
  banner?: ReactNode;
};

const MainContent = styled("main")(({ theme }) => {
  return {
    maxWidth: theme.layout.contentSmallMaxWidth,
    width: `calc(100% - ${theme.spacing(8)})`,
    padding: theme.spacing(4),
    margin: "0 auto",
    boxSizing: "content-box",

    "@media print": {
      padding: 0,
    },
  };
});

const PageLayoutNarrow: FC<PageLayoutNarrowProps> = ({ children, banner, mode, ...props }) => {
  return (
    <Layout mode={mode} {...props}>
      <Header mode={mode} />

      <Box alignSelf="flex-start" width="100%">
        {banner}
      </Box>

      <MainContent>{children}</MainContent>
    </Layout>
  );
};

export default PageLayoutNarrow;
