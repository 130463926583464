import { FC, useMemo } from "react";
import { styled } from "@mui/material";
import { Questionnaire } from "api/hooks/useSurvey/type";
import { useFormContext } from "react-hook-form";
import { Accordion } from "theme";
import QuestionsListItem from "./QuestionsListItem";

type QuestionsListProps = {
  name: string;
  questions: Questionnaire[];
  expanded: boolean;
  onClick: (name: string) => void;
};
const QuestionsListWrapper = styled("ol")(({ theme }) => ({
  maxHeight: "24rem",
  overflowY: "auto",
  margin: 0,
  padding: 0,
  listStyle: "none",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  userSelect: "none",
}));

const PROGRESS_MAX_VALUE = 100;

const QuestionsList: FC<QuestionsListProps> = ({ name, questions, expanded, onClick }) => {
  const { watch } = useFormContext();

  const selected = watch(questions.map(({ id }) => id)).filter(Boolean).length;
  const questionsLength = questions.length;

  const progress = useMemo(
    () => Math.round((selected / questionsLength) * PROGRESS_MAX_VALUE),
    [selected, questionsLength],
  );

  return (
    <Accordion
      label={name}
      name={name}
      expanded={expanded}
      progress={progress}
      progressMaxValue={PROGRESS_MAX_VALUE}
      onClick={onClick}
    >
      <QuestionsListWrapper>
        {questions.map((question, index) => (
          <QuestionsListItem key={question.id} question={question} index={index} />
        ))}
      </QuestionsListWrapper>
    </Accordion>
  );
};

export default QuestionsList;
