import { FC } from "react";
import { Grid, styled, useTheme } from "@mui/material";
import Logo from "components/Logo/Logo";
import { ProgressSteps, Typography } from "theme";
import Layout, { LayoutProps } from "./components/Layout";

type StepperLayoutProps = Pick<LayoutProps, "children"> & {
  title: string;
  description?: string;
  step: number;
  steps: string[];
};

const MainContent = styled("main")(({ theme }) => {
  return {
    maxWidth: theme.layout.contentSmallMaxWidth,
    width: `calc(100% - ${theme.spacing(8)})`,
    padding: theme.spacing(0, 4),
    margin: theme.spacing(12, "auto"),
    boxSizing: "content-box",
  };
});

const StyledLogo = styled(Logo)(({ theme }) => ({
  paddingRight: theme.spacing(3),
}));

const StepperLayout: FC<StepperLayoutProps> = ({ children, title, description, step, steps }) => {
  const { spacing, layout } = useTheme();

  return (
    <Layout mode="light" backgroundColor="default">
      <Grid
        container
        direction="row"
        position="relative"
        alignItems="center"
        justifyContent="space-between"
        component="header"
        p={spacing(0, 4)}
      >
        <Grid item xs={1.5} minWidth="fit-content">
          <StyledLogo mode="light" />
        </Grid>
        <Grid item flexGrow={1} maxWidth={layout.contentSmallMaxWidth}>
          <ProgressSteps activeStep={step} steps={steps} />
        </Grid>
        <Grid item xs={0} lg={1.5} />
      </Grid>

      <MainContent>
        <Grid container flexDirection="column" rowGap={4} height="100%">
          <Grid container item flexDirection="column" rowGap={1}>
            <Typography variant="h2">{title}</Typography>
            {description && (
              <Typography variant="body1" color="secondary" maxWidth="36rem">
                {description}
              </Typography>
            )}
          </Grid>

          <Grid item flex={1}>
            {children}
          </Grid>
        </Grid>
      </MainContent>
    </Layout>
  );
};

export default StepperLayout;
