import { Box } from "@mui/material";
import { useProfile } from "api/hooks";
import ProfileInfo from "components/Profile/ProfileInfo/ProfileInfo";
import { mapProfileToProfileInfo } from "utils/mappers/profileMappers";

const DEFAULT_VALUES = {
  nickname: "",
  expertise: [],
  location: null,
  travel: [],
};

const PopulatedProfileInfo = () => {
  const { profile, isPending, isRefetching } = useProfile();

  if (profile && !isPending && !isRefetching)
    return <ProfileInfo profile={profile} defaultValues={mapProfileToProfileInfo(profile)} />;

  return (
    <Box>
      <ProfileInfo defaultValues={DEFAULT_VALUES} />
    </Box>
  );
};

export default PopulatedProfileInfo;
