import React, { FC, ReactNode, SyntheticEvent } from "react";
import { styled, ButtonProps, BoxProps } from "@mui/material";
import { Button, Tooltip } from "theme/index";

export type TooltipButtonProps = {
  tooltipTitle: string;
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  icon?: ReactNode;
  onClick?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  loading?: boolean;
  mb?: BoxProps["mb"];
};

const StyledTooltipButton = styled(Button)(({ theme }) => {
  return {
    padding: theme.spacing(1),

    "@media print": {
      display: "none",
    },

    "& .MuiLoadingButton-loadingIndicator": {
      left: 12,
    },

    "&.MuiLoadingButton-loading": {
      "& > svg": {
        visibility: "hidden",
      },
    },

    "&.MuiLoadingButton-root": {
      minWidth: "2.5rem",
      height: "fit-content",

      "&:hover, &:focus, &:active": {
        backgroundColor: theme.palette.tertiary.dark,
      },
    },
  };
});

const TooltipButton: FC<TooltipButtonProps> = ({
  tooltipTitle,
  variant = "text",
  color,
  icon,
  onClick,
  disabled = false,
  loading = false,
  ...props
}) => {
  return (
    <Tooltip title={tooltipTitle} {...props}>
      <StyledTooltipButton
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        {...(color && {
          color,
        })}
      >
        {icon}
      </StyledTooltipButton>
    </Tooltip>
  );
};

export default TooltipButton;
