import { Grid, styled } from "@mui/material";
import { queryClient } from "api/queryClient";
import { Button, Typography } from "theme";
import { Gear } from "theme/icons";

const ErrorPageWrapper = styled(Grid)(() => ({
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "24rem",
}));

const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <Grid item container rowGap={4} flexDirection="column">
        <Grid item container rowGap={2.5} flexDirection="column" alignItems="center">
          <Grid item>
            <Gear />
          </Grid>
          <Grid item textAlign="center">
            <Typography variant="h5" weight="semiBold" mb={1}>
              This page isn’t available
            </Typography>
            <Typography variant="body2" color="secondary">
              This may be because of a technical error that we’re working to get fixed. Try
              reloading this page.
            </Typography>
          </Grid>
        </Grid>
        <Grid item textAlign="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ["profile"] });
            }}
          >
            <Typography weight="medium">Reload page</Typography>
          </Button>
        </Grid>
      </Grid>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
