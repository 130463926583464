import { ErrorMessage, getErrorMessage } from "utils/validation/errors";
import { z } from "zod";

export const WorkExperienceScheme = z
  .object({
    id: z.string().nullish(),
    showTitle: z
      .string()
      .nonempty({ message: ErrorMessage.FieldRequired })
      .max(250, { message: getErrorMessage("Show title", ErrorMessage.FieldTooLong) }),
    showType: z.array(z.string()),
    startDate: z.date().nullish(),
    endDate: z.date().nullish(),
    location: z
      .object({
        adminCode: z.string(),
        adminName: z.string(),
        cityName: z.string(),
        countryCode: z.string(),
        countryName: z.string(),
        geoNameId: z.string(),
      })
      .nullable(),
    position: z
      .string()
      .max(250, { message: getErrorMessage("Position", ErrorMessage.FieldTooLong) }),
    responsibilities: z
      .string()
      .max(1000, { message: getErrorMessage("Responsibilities", ErrorMessage.FieldTooLong) }),
  })
  .refine((data) => !!data?.endDate && !!data?.startDate, {
    message: ErrorMessage.FieldRequired,
    path: ["endDate"],
  })
  .refine((data) => data.endDate && data.startDate && data.endDate >= data.startDate, {
    message: getErrorMessage("End date", ErrorMessage.DateValidation),
    path: ["endDate"],
  });

export type WorkExperienceFormScheme = z.infer<typeof WorkExperienceScheme>;
