import { FC } from "react";
import { Grid } from "@mui/material";
import { Button } from "theme";

type FormControlsProps = {
  onClose: () => void;
  formId: string;
  isPending?: boolean;
};

const FormControls: FC<FormControlsProps> = ({ onClose, formId, isPending }) => {
  return (
    <Grid container columnGap={2}>
      <Button variant="contained" onClick={onClose}>
        Cancel
      </Button>
      <Button loading={isPending} form={formId} type="submit" color="secondary" variant="contained">
        {isPending ? "Saving" : "Save"}
      </Button>
    </Grid>
  );
};

export default FormControls;
