import { useMemo } from "react";
import { useOptionsList } from "api/hooks";
import { OptionsParams } from "api/urls";
import StepperLayout from "components/Layout/StepperLayout";
import { Navigate, Outlet, generatePath, useLocation } from "react-router-dom";
import { Routes } from "router/routes";
import { Loader } from "theme";
import { nonNullable } from "utils/helpers";
import { useSurveyModal } from "utils/hooks/useSurveyModal";

export type StepData = {
  step: number;
  title: string;
  name: string;
  route: string;
};

const Survey = () => {
  const { pathname } = useLocation();
  const { data, isPending } = useOptionsList(OptionsParams.Specializations);
  const { selectedSurveys, openSurveyModal } = useSurveyModal();

  const stepsData = useMemo<StepData[]>(() => {
    return (data || [])
      .map((i, idx) => {
        const enabled = (selectedSurveys || []).includes(i.id);

        return enabled
          ? {
            step: idx + 1,
            title: `${i.name} survey`,
            name: i.name,
            route: generatePath(Routes.SurveyQuestionnaire, { specialization: i.name }),
          }
          : null;
      })
      .filter(nonNullable);
  }, [selectedSurveys, data]);

  const steps = useMemo(() => stepsData.map((i) => i.title), [stepsData]);
  const stepData = useMemo<StepData | undefined>(
    () => stepsData.find((i) => i.route === pathname),
    [pathname, stepsData],
  );

  if (!selectedSurveys) {
    openSurveyModal(Routes.MyProfile);
    return null;
  }

  if (pathname === Routes.Survey) {
    return !isPending ? (
      <Navigate to={stepsData[0]?.route as string} state={{ selectedSurveys }} replace />
    ) : (
      <Loader size={48} />
    );
  }

  return (
    <StepperLayout
      steps={steps}
      step={stepData?.step ?? 1}
      title={stepData?.title ?? ""}
      description={
        "Please review the questions and check \"Yes\" box if you feel confident about the described task/skill"
      }
    >
      <Outlet context={{ stepsData, stepData }} />
    </StepperLayout>
  );
};

export default Survey;
