import { FC, ReactNode } from "react";
import { Box, DialogContent, Divider, Grid, GridProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "theme";
import { Clear } from "theme/icons";
import { StyledMuiModal } from "./styles";

type FormModalProps = {
  open: boolean;
  children: ReactNode;
  title: string;
  onClose: () => void;
  actionButtons?: React.JSX.Element;
  actionButtonsAlignment?: GridProps["justifyContent"];
};

const ModalWrap = styled(Box)(({ theme }) => ({
  width: theme.layout.modalWidth.large,
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  margin: "0 auto",
}));

const StyledDialogContent = styled(DialogContent)({
  maxHeight: "90%",
  overflowY: "auto",
});

const ClearButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: "1.5rem",
}));

const FormModal: FC<FormModalProps> = ({
  open,
  children,
  title,
  onClose,
  actionButtons,
  actionButtonsAlignment = "end",
}) => {
  const isFullWidthActionButtons = actionButtonsAlignment !== "end";

  return (
    <StyledMuiModal disableEnforceFocus disableAutoFocus open={open}>
      <StyledDialogContent>
        <ModalWrap>
          <Box px={4} pt={2.5} pb={4}>
            <Grid mb={2.5} container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h4">{title}</Typography>
              </Grid>
              <Grid item>
                <ClearButton onClick={onClose} variant="text" color="secondary">
                  <Clear />
                </ClearButton>
              </Grid>
            </Grid>
            {children}
          </Box>
          {actionButtons && (
            <>
              <Divider />
              <Grid container justifyContent={actionButtonsAlignment} py={2} px={4}>
                <Box width={isFullWidthActionButtons ? "100%" : "fit-content"}>{actionButtons}</Box>
              </Grid>
            </>
          )}
        </ModalWrap>
      </StyledDialogContent>
    </StyledMuiModal>
  );
};

export default FormModal;
