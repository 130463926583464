import { Popper, styled } from "@mui/material";

const DropdownContainer = styled(Popper)(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxHeight: "13rem",
    boxShadow: theme.shadows[3],

    "& .MuiAutocomplete-listbox": {
      maxHeight: "100%",
    },
  },
}));

export default DropdownContainer;
