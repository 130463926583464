import { FC, ReactNode } from "react";
import { Grid } from "@mui/material";
import { Typography } from "theme";
import DetailIcon from "./DetailIcon";

type UserDetailItemSize = "sm" | "md";

type UserDetailItemProps = {
  icon: ReactNode;
  children: ReactNode;
  size?: UserDetailItemSize;
};

const UserDetailItem: FC<UserDetailItemProps> = ({ icon, children, size = "sm" }) => {
  const isSmall = size === "sm";

  return (
    <Grid
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      container
      flexWrap="nowrap"
      alignItems="center"
      columnGap={isSmall ? 1 : 2}
    >
      <Grid item p={isSmall ? 0.5 : 0}>
        <DetailIcon>{icon}</DetailIcon>
      </Grid>
      <Grid item textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        <Typography
          component="div"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          weight="medium"
          variant={isSmall ? "body2" : "body1"}
        >
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserDetailItem;
