import React, { FC, ReactNode, useState } from "react";
import {
  Box,
  Popper as MuiPopper,
  styled,
  PopperProps as MuiPopperProps,
  BoxProps,
} from "@mui/material";

type PopperProps = {
  toggle: ReactNode;
  children: ReactNode;
} & Pick<MuiPopperProps, "placement">;

const ToggleWrap = styled(Box)({
  cursor: "pointer",
});

const StyledPopper = styled(MuiPopper)(({ theme }) => ({
  "& > .MuiBox-root": {
    marginTop: theme.spacing(1),
  },
}));

const ContentWrap = styled(Box)(({ theme }) => {
  return {
    maxWidth: "18.5rem",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.tertiary.main,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
  };
});

const Toggle = styled(Box, {
  shouldForwardProp: (propName) => propName !== "isHighlighted",
})<BoxProps & { isHighlighted?: boolean }>(({ theme, isHighlighted }) => {
  return {
    padding: theme.spacing(0.5),
    ...(isHighlighted && {
      borderRadius: "50%",
      outlineOffset: -4,
      outlineWidth: 4,
      outlineStyle: "solid",
      outlineColor: theme.palette.tertiary.main,
    }),
  };
});

const Popper: FC<PopperProps> = ({ toggle, children, placement = "bottom" }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isOpen = !!anchorEl;

  return (
    <>
      <ToggleWrap component="span" role="button" onClick={handleClick}>
        <Toggle isHighlighted={isOpen}>{toggle}</Toggle>
      </ToggleWrap>
      <StyledPopper open={isOpen} anchorEl={anchorEl} placement={placement}>
        <ContentWrap>{children}</ContentWrap>
      </StyledPopper>
    </>
  );
};

export default Popper;
