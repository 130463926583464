import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";

export default styled(LoadingButton)<LoadingButtonProps>(({ theme }) => {
  return {
    textTransform: "none",
    padding: theme.spacing(1, 2),
    boxShadow: theme.shadows[0],

    "@media print": {
      display: "none",
    },

    "&:hover, &:focus, &:active": {
      boxShadow: theme.shadows[0],
    },

    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(1.5),
    },

    "&.Mui-disabled": {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.grey.A100,
    },

    "& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root": {
      color: theme.palette.text.inverse,
    },

    "&.MuiButton-containedPrimary": {
      "&.MuiLoadingButton-loading": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.text.inverse,
      },

      "& .MuiLoadingButton-loadingIndicator": {
        position: "initial",
        marginRight: theme.spacing(1.5),
      },
    },

    "&.MuiButton-containedSecondary": {
      "&.MuiLoadingButton-loading": {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.text.inverse,
      },

      "& .MuiLoadingButton-loadingIndicator": {
        position: "initial",
        marginRight: theme.spacing(1.5),
      },
    },

    "&.MuiButton-containedTertiary": {
      "& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root": {
        color: theme.palette.primary.main,
      },
    },
  };
});
