import { ProfileCertificate, ProfileSkill, WorkPreference } from "api/hooks/useProfile/type";
import {
  Flashlight,
  Rigging,
  SoundModule,
  Theatre,
  Corporate,
  Movie,
  Concert,
  SpecialEvents,
} from "theme/icons";

export const SpecializationIcons = {
  Lighting: <Flashlight />,
  Automation: <SoundModule />,
  Rigging: <Rigging />,
};

export const WorkExperienceIcons = {
  Corporate: <Corporate />,
  Theater: <Theatre />,
  "Tv/Film": <Movie />,
  "Concert/Touring": <Concert />,
  Festivals: <SpecialEvents />,
};

const mapSkillAndCertificationValues = ({
  skills,
  workPreferences,
  certifications,
}: {
  skills: ProfileSkill[];
  certifications: ProfileCertificate[];
  workPreferences: WorkPreference[];
}) => {
  return {
    skills: skills.map((el) => el.id),
    workPreferences: workPreferences.map((el) => el.id),
    certifications: certifications.map((el) => el.id),
  };
};

export default mapSkillAndCertificationValues;
