import { useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "theme";
import { useError, useFetch } from "utils/hooks";
import { ResponseError } from "utils/hooks/useFetch";
import { ErrorMessage } from "utils/validation/errors";
import { passwordValidation } from "utils/validation/utils";
import { z } from "zod";
import { ErrorsAlert } from "../components/ErrorsAlert/ErrorsAlert";

const currentPasswordScheme = passwordValidation("Password");
const newPasswordScheme = passwordValidation("New password");

const changePasswordScheme = z
  .object({
    currentPassword: currentPasswordScheme,
    newPassword: newPasswordScheme,
  })
  .superRefine((data, ctx) => {
    const { success: currentPasswordSuccess } = currentPasswordScheme.safeParse(
      data.currentPassword,
    );
    const { success: newPasswordSuccess } = newPasswordScheme.safeParse(data.newPassword);

    if (newPasswordSuccess && currentPasswordSuccess && data.currentPassword === data.newPassword) {
      ctx.addIssue({
        code: "custom",
        message: ErrorMessage.PasswordsAreSame,
      });
    }
  });

type ChangePasswordForm = z.infer<typeof changePasswordScheme>;

const ChangePassword = () => {
  const request = useFetch();

  const { handleSubmit, control, reset, formState } = useForm<ChangePasswordForm>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(changePasswordScheme),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });

  const { isError, errorsList, setServerError } = useError(formState.errors);

  const {
    mutate,
    isPending: isChangePasswordLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (data: { currentPassword: string; newPassword: string }) => {
      return request(Urls.ChangePassword, {
        method: "PATCH",
        body: JSON.stringify(data),
      });
    },
    onMutate: () => {
      setServerError(null);
    },
    onError: (error) => {
      const err = error as ResponseError;
      setServerError(err);
    },
    onSuccess: () => {
      reset();
    },
  });

  const handlePasswordChangeSubmit: SubmitHandler<ChangePasswordForm> = (data) => {
    mutate(data);
  };

  const submitBtnText = useMemo(() => {
    if (isChangePasswordLoading) {
      return "Saving...";
    }

    if (isSuccess && !formState.isDirty) {
      return "Updated";
    }

    return "Update password";
  }, [isChangePasswordLoading, isSuccess, formState.isDirty]);

  return (
    <Grid
      component="form"
      id="change-password-form"
      noValidate
      autoComplete="off"
      container
      flexDirection="column"
      rowGap={2.5}
      onSubmit={handleSubmit(handlePasswordChangeSubmit)}
    >
      <Grid item>
        <ControlledTextField
          control={control}
          name="currentPassword"
          label="Current password"
          type="password"
          autoComplete="current-password"
          required
          placeholder="Enter current password"
          loading={isChangePasswordLoading}
        />
      </Grid>
      <Grid item>
        <ControlledTextField
          control={control}
          name="newPassword"
          label="New password"
          type="password"
          autoComplete="new-password"
          required
          placeholder="8 characters minimum"
          loading={isChangePasswordLoading}
        />
      </Grid>
      {isError && (
        <Grid item>
          <ErrorsAlert message={errorsList} />
        </Grid>
      )}
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          loading={isChangePasswordLoading}
        >
          {submitBtnText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
