import { format, parseISO } from "date-fns";
import { Roles } from "utils/context/Auth/user";

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export type TokenContext = {
  accountId: string;
  profileId?: string;
  email: string;
  role: Roles;
  iat: number;
  iss: string;
  aud: string;
  exp: number;
  nbf: number;
};

export function parseToken(token: string | null): TokenContext | null {
  try {
    if (!token) {
      throw new Error("Token is missing");
    }

    return JSON.parse(atob(token.split(".")[1])) as TokenContext;
  } catch (e) {
    return null;
  }
}

export const sortArray = <T extends Object>(arr: T[], key: keyof T) =>
  arr.sort((a: T, b: T) => {
    if (a[key] < b[key]) {
      return -1;
    }

    if (a[key] > b[key]) {
      return 1;
    }

    return 0;
  });

export const parseDate = (date: string) => format(parseISO(date), "MMM, yyyy");

export const parseDateRange = (startDate: string, endDate: string) => {
  const parsedStartDate = parseISO(startDate);
  const parsedEndDate = parseISO(endDate);

  if (parsedStartDate.getFullYear() === parsedEndDate.getFullYear()) {
    if (parsedStartDate.getMonth() === parsedEndDate.getMonth()) {
      return `${parseDate(endDate)}`;
    }

    return `${format(parsedStartDate, "MMM")} - ${parseDate(endDate)}`;
  }

  return `${parseDate(startDate)} - ${parseDate(endDate)}`;
};

// This helper function is used to wrap mutateAsync calls to catch "Uncaught (in promise)" error
export const handlePromiseError = async (cb: () => Promise<unknown>) => {
  await cb().catch(console.error);
};
