import { useCallback, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import ChangePassword from "components/Forms/ChangePassword/ChangePassword";
import ProfileInfoForm from "components/Forms/ProfileInfoForm/ProfileInfoForm";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import PageLayoutNarrow from "components/Layout/PageLayoutNarrow";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { BackButton, Button, Modal, Typography } from "theme";
import ModalActionButtons from "theme/components/Modal/ModalActionButtons";
import { Delete } from "theme/icons";
import { useAuth } from "utils/context/Auth/AuthContext";
import { useFetch } from "utils/hooks";
import { ResponseError } from "utils/hooks/useFetch";

const AccountSettings = () => {
  const request = useFetch();
  const { clearToken } = useAuth();
  const navigate = useNavigate();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [serverError, setServerError] = useState<ResponseError | null>(null);

  const { mutate: deleteUser, isPending } = useMutation<unknown, ResponseError>({
    mutationFn: () =>
      request(Urls.Accounts, {
        method: "DELETE",
      }),
    onMutate: () => setServerError(null),
    onSuccess: () => {
      clearToken();
      navigate(Routes.Root, { replace: true });
    },
    onError: setServerError,
  });

  const handleDeleteClick = useCallback(() => {
    setDeleteConfirmation(true);
  }, [setDeleteConfirmation]);

  const handleModalClose = useCallback(() => {
    setDeleteConfirmation(false);
    setServerError(null);
  }, [setDeleteConfirmation, setServerError]);

  return (
    <>
      <PageLayoutNarrow mode="light" backgroundColor="default">
        <Grid container flexDirection="column" rowGap={4}>
          <Grid container item justifyContent="space-between" alignItems="center" rowGap={1}>
            <Typography variant="h2">Account Settings</Typography>
            <Grid item>
              <BackButton />
            </Grid>
          </Grid>

          <Grid item container rowGap={2.5} flexDirection="column">
            <Grid item>
              <Typography variant="h5">Personal info</Typography>
              <Typography variant="body2" color="secondary">
                Update your photo and personal details.
              </Typography>
            </Grid>
            <Grid item>
              <ProfileInfoForm />
            </Grid>
          </Grid>

          <Divider />

          <Grid item container rowGap={2.5} flexDirection="column">
            <Grid item>
              <Typography variant="h5">Password</Typography>
            </Grid>
            <Grid item>
              <ChangePassword />
            </Grid>
          </Grid>

          <Divider />

          <Grid item textAlign="end">
            <Button
              color="tertiary"
              variant="contained"
              startIcon={<Delete />}
              onClick={handleDeleteClick}
            >
              Delete account
            </Button>
          </Grid>
        </Grid>
      </PageLayoutNarrow>

      <Modal
        open={deleteConfirmation}
        iconType="exclamation"
        title="Confirm deletion"
        description="Are you sure that you want to delete your account? This action cannot be undone."
        actionButtons={
          <ModalActionButtons
            buttons={[
              {
                label: "Cancel",
                color: "tertiary",
                onClick: handleModalClose,
                disabled: isPending,
              },
              {
                label: "Delete account",
                color: "primary",
                onClick: () => deleteUser(),
                loading: isPending,
              },
            ]}
          />
        }
      >
        {serverError && <ErrorsAlert message={serverError} />}
      </Modal>
    </>
  );
};

export default AccountSettings;
