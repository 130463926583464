import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import ControlledTextField from "components/ControlledInputs/ControlledTextField";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { Button } from "theme";
import { useError } from "utils/hooks";
import useFetch, { ResponseError } from "utils/hooks/useFetch";
import { ServerErrorCode, ServerErrorMessage } from "utils/validation/errors";
import {
  ForgotPasswordFormScheme,
  ForgotPasswordResponse,
  forgotPasswordScheme,
} from "./validation";
import { ErrorsAlert } from "../components/ErrorsAlert/ErrorsAlert";

const FORM_ID = "forgot-password-form";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const request = useFetch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordFormScheme>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(forgotPasswordScheme),
    defaultValues: {
      email: "",
    },
  });

  const { setServerError, errorsList, isError } = useError(errors);

  const { mutate: resetPassword, isPending } = useMutation({
    mutationFn: (data: ForgotPasswordFormScheme) => {
      return request<ForgotPasswordResponse>(Urls.ForgotPassword, {
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    onMutate: () => setServerError(null),
    onError: (error: ResponseError) => {
      if (error.errorCode === ServerErrorCode.NotFound) {
        error.errorMessage = ServerErrorMessage.EmailNotExists;
      }

      setServerError(error);
    },
    onSuccess: () => {
      navigate(Routes.ForgotPassword, { replace: true, state: { reset: true } });
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormScheme> = (data) => {
    resetPassword(data);
  };

  return (
    <Grid container rowSpacing={4}>
      <Grid
        item
        container
        component="form"
        autoComplete="off"
        id={FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        flexDirection="column"
        rowSpacing={2.5}
        flexGrow={1}
      >
        <Grid item>
          <ControlledTextField
            control={control}
            name="email"
            placeholder="Enter your email"
            label="Email"
            required
            type="text"
            autoComplete="off"
            loading={isPending}
          />
        </Grid>

        {isError && (
          <Grid item>
            <ErrorsAlert message={errorsList} />
          </Grid>
        )}
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container flexDirection="column" rowSpacing={2}>
          <Grid item>
            <Button
              loading={isPending}
              type="submit"
              form={FORM_ID}
              color="primary"
              variant="contained"
              fullWidth
            >
              {isPending ? "Loading" : "Reset password"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
