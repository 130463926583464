import {
  Profile,
  ProfileCertificate,
  ProfileShow,
  WorkPreference,
} from "api/hooks/useProfile/type";
import { WorkExperienceFormScheme } from "components/Modals/WorkExperienceModal/validation";
import { ProfileInfoFormScheme } from "components/Profile/ProfileInfo/validation";
import { parseISO } from "date-fns";
import { Expertise, Level, ProfileExpertise, TravelStatus } from "utils/types/common";

export enum TravelStatusLabel {
  TravelAnywhere = "Travel anywhere",
  TravelInside = "Travel inside the country",
  NotReadyToTravel = "Not ready to travel",
}

export const mapTravelStatus = (travelStatus: TravelStatus | null) => {
  switch (travelStatus) {
    case TravelStatus.TravelAbroad:
      return TravelStatusLabel.TravelAnywhere;
    case TravelStatus.TravelInside:
      return TravelStatusLabel.TravelInside;
    default:
      return TravelStatusLabel.NotReadyToTravel;
  }
};

export const mapProfileExpertiseToString = (expertise: ProfileExpertise[]) =>
  expertise
    .filter((acc) => acc.name !== "")
    .map((acc) => acc.name)
    .join(", ");

export const mapExpertiseLevel = (level: Level | null) => {
  switch (level) {
    case Level.Entry:
      return 1;
    case Level.Intermediate:
      return 2;
    case Level.Advanced:
      return 3;
    case Level.Senior:
      return 4;
    case Level.Expert:
      return 5;
    default:
      return 0;
  }
};

export const mapExpertise = (expertise?: Expertise[] | null) => {
  return expertise?.map((exp) => ({
    label: exp.specializationName,
    value: exp.levelName,
    selectedValue: mapExpertiseLevel(exp.levelName),
  }));
};

export const mapWorkCertItems = (workPreferences: WorkPreference[] | ProfileCertificate[]) => {
  return workPreferences?.map((pref) => ({
    text: pref.name,
    id: pref.id,
  }));
};

export const mapProfileInfoToProfile = (data: ProfileInfoFormScheme) => {
  const { nickname, expertise, travel, location } = data;

  return {
    nickname,
    travel: travel?.[0] || null,
    location: location || null,
    expertiseIds: expertise,
  };
};

export const mapProfileToProfileInfo = (profile: Profile) => {
  const { nickName, expertise, travel, location } = profile;

  const mappedExpertise = expertise ? expertise.map((el) => el.specializationId) : [];

  return {
    nickname: nickName || "",
    expertise: mappedExpertise,
    location: location || null,
    travel: travel ? [travel] : [],
  };
};

export const mapToEditPersonalInfo = (profile: Profile) => {
  const { firstName, lastName, nickName, expertise, location, travel } = profile;
  const mappedExpertise = expertise ? expertise.map((el) => el.specializationId) : [];

  return {
    firstName,
    lastName,
    nickname: nickName || "",
    expertise: mappedExpertise,
    location: location || null,
    travel: travel ? [travel] : [],
  };
};

export const mapToWorkExperience = (data: WorkExperienceFormScheme) => {
  const { showTitle, location, showType, position, responsibilities, startDate, endDate, id } =
    data;

  return {
    showTitle,
    location: location || null,
    position,
    responsibilities,
    startDate,
    endDate,
    ...(showType.length > 0 && {
      genre: showType[0],
    }),
    ...(id && {
      id,
    }),
  };
};

export const mapShowToWorkExperience = (data: ProfileShow): WorkExperienceFormScheme => {
  const { id, showTitle, genre, startDate, endDate, location, position, responsibilities } = data;

  return {
    id,
    showTitle,
    showType: genre ? [genre] : [],
    startDate: parseISO(startDate),
    endDate: parseISO(endDate),
    location: location || null,
    position,
    responsibilities,
  };
};
