import { FC } from "react";
import { Grid, PaletteColor, useTheme } from "@mui/material";
import { CircularProgressBar, Typography } from "theme";
import { Flashlight, Rigging, SoundModule } from "theme/icons";
import { Level, Specialization } from "utils/types/common";

const getProgressBarProps = (label: Specialization, palette: PaletteColor) => {
  switch (label) {
    case Specialization.Automation:
      return {
        activeColor: palette.light,
        icon: <SoundModule />,
      };
    case Specialization.Lighting:
      return {
        activeColor: palette.main,
        icon: <Flashlight />,
      };
    case Specialization.Rigging:
      return {
        activeColor: palette.dark,
        icon: <Rigging />,
      };
  }
};

type ExpertiseLevelProps = {
  label: Specialization;
  value: Level | null;
  selectedValue: number;
};

const ExpertiseLevel: FC<ExpertiseLevelProps> = ({ label, selectedValue, value }) => {
  const {
    palette: { visualization },
  } = useTheme();

  const progressBarProps = getProgressBarProps(label, visualization);

  return (
    <Grid flexWrap="nowrap" container columnGap={2} alignItems="center">
      <Grid item height="3rem">
        <CircularProgressBar
          selectedValue={selectedValue}
          backgroundColor="primary"
          {...progressBarProps}
        />
      </Grid>
      <Grid flexDirection="column" item container>
        <Typography variant="body1" color="secondary">
          {label}
        </Typography>
        <Typography variant="body1" weight="medium">
          {selectedValue === 0 ? "Level not set" : value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ExpertiseLevel;
