import { FC, ReactNode } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { TypeBackground } from "@mui/material/styles/createPalette";
import Footer from "components/Footer/Footer";

export type LayoutProps = {
  children?: ReactNode;
  mode: "light" | "dark";
  backgroundImageSrc?: string;
  base?: boolean;
  backgroundColor?: keyof TypeBackground;
};

export const StyledLayout = styled(Box, {
  shouldForwardProp: (propName: string) =>
    !["backgroundImageSrc", "backgroundColor"].includes(propName),
})<Pick<LayoutProps, "backgroundImageSrc" | "backgroundColor">>(({
  theme,
  backgroundImageSrc,
  backgroundColor,
}) => {
  return {
    display: "flex",
    flexDirection: "column",
    minWidth: theme.layout.windowMinWidth,
    width: "100%",
    minHeight: "100vh",

    ...(backgroundImageSrc
      ? {
        backgroundImage: `url(${backgroundImageSrc})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }
      : { backgroundColor: theme.palette.background[backgroundColor || "primary"] }),
  };
});

const Layout: FC<LayoutProps> = ({
  children,
  mode,
  backgroundImageSrc,
  backgroundColor,
  base = false,
}) => {
  return (
    <StyledLayout backgroundImageSrc={backgroundImageSrc} backgroundColor={backgroundColor}>
      {children}
      {!base && <Footer mode={mode} />}
    </StyledLayout>
  );
};

export default Layout;
