import { useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Urls } from "api/urls";
import { sortArray } from "utils/helpers";
import { useFetch } from "utils/hooks";
import { CacheTime, ResponseError } from "utils/hooks/useFetch";
import { Expertise, Specialization } from "utils/types/common";
import { GroupedSurveyData, Questionnaire } from "./type";

const useSurvey = (expertise?: Specialization) => {
  const fetch = useFetch();

  const questionnaires = useQuery<Questionnaire[] | null, ResponseError>({
    queryKey: ["survey", "questionnaires", expertise],
    queryFn: () =>
      fetch<Questionnaire[]>(Urls.Questionnaires, {
        method: "GET",
        slug: { expertise },
      }),
    enabled: Boolean(expertise),
    gcTime: CacheTime,
    staleTime: CacheTime,
  });

  const submitSurvey = useMutation<Expertise | null, ResponseError, { questionIds: string[] }>({
    mutationFn: (data) =>
      fetch<Expertise>(Urls.SubmitSurvey, {
        method: "POST",
        body: JSON.stringify(data),
        slug: { expertise },
      }),
  });

  const groupedQuestionnaires = useMemo(() => {
    return sortArray(questionnaires.data || [], "order").reduce<GroupedSurveyData>((acc, i) => {
      if (acc.has(i.section)) {
        acc.get(i.section)?.push(i);
      } else {
        acc.set(i.section, [i]);
      }

      return acc;
    }, new Map());
  }, [questionnaires.data]);

  return {
    submitSurvey,
    questionnaires,
    groupedQuestionnaires,
  };
};

export default useSurvey;
