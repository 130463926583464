import { FC } from "react";
import { Typography } from "theme";

type HighlightProps = {
  text?: string;
  highlight?: string;
};

const Highlight: FC<HighlightProps> = ({ highlight = "", text = "" }) => {
  if (!highlight) return <>{text}</>;

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <>
      {parts.map((part, i) => {
        const shouldHighlightPart = part.toLowerCase() === highlight.toLowerCase();

        return (
          <Typography
            variant="body2"
            color="primary"
            weight={shouldHighlightPart ? "semiBold" : "regular"}
            component="span"
            key={i}
          >
            {part}
          </Typography>
        );
      })}
    </>
  );
};

export default Highlight;
