import { LocationOption } from "theme/utils";
import { ProfileQueryValues, nonNullable, stringToLocation } from "utils/helpers";
import { QueryObject } from "utils/hooks/useFilter";
import { ProfileExpertise, TravelStatus } from "utils/types/common";

export type SearchProfile = {
  profileId: string;
  email: string;
  name: string;
  nickname?: string | null;
  avatar: string | null;
  travel: TravelStatus | null;
  skills?: string[];
  location: LocationOption | null;
  expertise: ProfileExpertise[];
};

export type PaginatedProfilesSearch = {
  totalProfiles: number;
  searchProfiles: SearchProfile[];
};

export const getFilters = (filter: QueryObject) => ({
  byLocation: filter[ProfileQueryValues.Location]
    ?.map((i) => {
      const { geoNameId } = stringToLocation(i) || {};
      return geoNameId ? { geoCode: geoNameId } : null;
    })
    .filter(nonNullable),
  bySkills: filter[ProfileQueryValues.Skills],
  byWorkPreferences: filter[ProfileQueryValues.WorkPreference],
  byTravelStatus: filter[ProfileQueryValues.ReadyToTravel]?.[0],
  byExpertiseNames: filter[ProfileQueryValues.Expertise],
  byExpertiseLevels: filter[ProfileQueryValues.Level],
});
