import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Urls } from "api/urls";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { useFetch } from "utils/hooks";

const useValidateInvitation = (token: string) => {
  const request = useFetch();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      return request<any>(Urls.SystemValidateInvitation, {
        method: "POST",
        slug: {
          token,
        },
      });
    },
    onError: () => {
      navigate(Routes.Root, { replace: true });
    },
  });

  useEffect(() => {
    if (token) {
      mutate();
    }
  }, [mutate, token]);

  return {
    isPending,
  };
};

export default useValidateInvitation;
