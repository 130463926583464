import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Popover)(({ theme }) => ({
  marginLeft: theme.spacing(-2),
  marginTop: theme.spacing(2),

  "& + .MuiTooltip-popper": {
    "&.MuiTooltip-popper > .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop": {
      marginBottom: theme.spacing(2.25),
    },
  },
}));
