export const SearchParamKey = "search";

export enum ProfileQueryValues {
  Expertise = "expertise",
  Level = "level",
  Location = "location",
  WorkPreference = "workPreference",
  Skills = "skills",
  ReadyToTravel = "readyToTravel",
}

export const ProfileQueryLabels = {
  [ProfileQueryValues.Expertise]: "Expertise",
  [ProfileQueryValues.Level]: "Level",
  [ProfileQueryValues.Location]: "Location",
  [ProfileQueryValues.WorkPreference]: "Work preference",
  [ProfileQueryValues.Skills]: "Skills",
  [ProfileQueryValues.ReadyToTravel]: "Ready to travel",
};
