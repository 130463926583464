import { FC, useCallback } from "react";
import { useMutateProfile } from "api/hooks";
import { ErrorsAlert } from "components/Forms/components/ErrorsAlert/ErrorsAlert";
import Skills from "components/Options/Skills";
import { SubmitHandler, useForm } from "react-hook-form";
import { useError } from "utils/hooks";
import { FormModalProps } from "utils/types/common";
import FormModalLayout from "./components/FormModalLayout";
import { handlePromiseError } from "../../utils/helpers";

type SkillsFormValues = { skills: string[] };
type SkillsModalProps = FormModalProps & {
  defaultValues: SkillsFormValues;
};

const SKILLS_FORM_ID = "skills-form";

const SkillsModal: FC<SkillsModalProps> = ({ open, onClose, defaultValues }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SkillsFormValues>({
    defaultValues,
  });

  const { errorsList, isError, setServerError } = useError(errors);

  const {
    skills: { mutateAsync: updateSkills, isPending: isSkillsLoading },
  } = useMutateProfile(setServerError);

  const onModalClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit: SubmitHandler<SkillsFormValues> = async (data) => {
    await handlePromiseError(() => updateSkills(data.skills));
    if (!isError) {
      onModalClose();
    }
  };

  return (
    <FormModalLayout
      formId={SKILLS_FORM_ID}
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onModalClose}
      title="Edit Skills"
      isPending={isSkillsLoading}
      description="Highlight your professional skills. This will help Labor Coordinators connect with you for relevant opportunities."
      error={isError ? <ErrorsAlert message={errorsList} /> : undefined}
    >
      <Skills control={control} name="skills" />
    </FormModalLayout>
  );
};

export default SkillsModal;
