import { Grid, styled } from "@mui/material";
import { Button, Typography } from "theme";
import { ModalContentIcon } from "theme/components/Modal/styles";
import { FirstPlaceMedal } from "theme/icons";

const BannerWrapper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  backgroundColor: theme.palette.background.alertInfo,

  "@media print": {
    display: "none",
  },
}));

const Banner = styled(Grid)(({ theme }) => ({
  maxWidth: theme.layout.contentSmallMaxWidth,
  width: `calc(100% - ${theme.spacing(8)})`,
  padding: theme.spacing(0, 4),
  margin: "0 auto",
  boxSizing: "content-box",
}));

const IconWrapper = styled(ModalContentIcon)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  padding: theme.spacing(0.75),
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

type SurveyBannerProps = {
  openSurveyModal: () => void;
};

const SurveyBanner: React.FC<SurveyBannerProps> = ({ openSurveyModal }) => {
  return (
    <BannerWrapper>
      <Banner container item justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container item>
            <IconWrapper>
              <FirstPlaceMedal />
            </IconWrapper>
            <Grid item alignItems="center">
              <Grid container height="100%" alignItems="center">
                <Typography variant="body1" weight="medium" mr={1}>
                  Want to set your expertise level?
                </Typography>
                <Typography variant="body1" color="secondary">
                  Fill out our survey to define your level of expertise as a technician.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Button variant="contained" color="secondary" onClick={openSurveyModal}>
          Take a survey
        </Button>
      </Banner>
    </BannerWrapper>
  );
};

export default SurveyBanner;
