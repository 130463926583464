import { FC } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserTileProps } from "components/User/UserTile/UserTile";
import { HorizontalList, Typography } from "theme";
import { useWrappedElement } from "utils/hooks";

type SkillsListProps = Pick<UserTileProps["profile"], "skills">;

const StyledTypography = styled(Typography)(({ theme }) => ({
  "&::before": {
    width: "1rem",
    display: "inline-flex",
    justifyContent: "center",
    color: theme.palette.text.placeholder,
    content: "\"•\"",
  },
}));

const SkillsList: FC<SkillsListProps> = ({ skills }) => {
  const { listRefs, list, hiddenElementsCount } = useWrappedElement<string>(skills);

  const shouldShowNumberOfHiddenElements = hiddenElementsCount !== 0;

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item>
        <HorizontalList>
          {list.map((skill, index) => (
            <Typography
              ref={listRefs.current[index]}
              key={index}
              component="li"
              weight="medium"
              variant="body2"
            >
              {skill}
            </Typography>
          ))}
        </HorizontalList>
      </Grid>
      {shouldShowNumberOfHiddenElements && (
        <StyledTypography
          minWidth="max-content"
          weight="medium"
          color="placeholder"
          variant="body2"
        >
          +{hiddenElementsCount} more
        </StyledTypography>
      )}
    </Grid>
  );
};

export default SkillsList;
