import { FC, useState } from "react";
import { Profile } from "api/hooks/useProfile/type";
import WorkPreferenceModal from "components/Modals/WorkPreferenceModal";
import { Sections } from "components/User/UserProfile/UserProfile";
import { useParams } from "react-router-dom";
import { TooltipButton } from "theme";
import { Edit } from "theme/icons";
import { ProfileSectionLayout, ProfileSectionProps } from "./ProfileSectionLayout";

type EditWorkExperienceProps = Pick<ProfileSectionProps, "readOnly" | "children"> & {
  preference?: Profile["workPreferences"];
};

const EditWorkPreference: FC<EditWorkExperienceProps> = ({ preference, ...props }) => {
  const { profileId } = useParams();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const shouldShowEditIcon = !profileId && preference && preference?.length > 0;

  return (
    <>
      <ProfileSectionLayout
        {...props}
        title={Sections.WorkPreference}
        shouldShowIcon={shouldShowEditIcon}
        placeholder="Tell us about the types of events for which you would be interested in being hired."
        icon={
          <TooltipButton
            onClick={() => setEditModalOpen(true)}
            variant="contained"
            tooltipTitle="Edit Work Preference"
            icon={<Edit />}
          />
        }
        onAddClick={() => setEditModalOpen(true)}
      />
      {preference && (
        <WorkPreferenceModal
          key={`${editModalOpen}`}
          defaultValues={{
            preference: preference?.map((el) => el.id) || [],
          }}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditWorkPreference;
