import {
  Stepper,
  useTheme,
  styled,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
} from "@mui/material";
import { StepperCompleted, StepperUncompleted } from "theme/icons";

type StepIconComponentProps = {
  active: boolean;
  completed: boolean;
};

type ProgressStepsProps = {
  activeStep: number;
  steps: string[];
};

const StepIconComponent = ({ active, completed }: StepIconComponentProps) => {
  const {
    palette: { success, tertiary },
  } = useTheme();

  if (completed) {
    return <StepperCompleted color={success.main} />;
  }

  return <StepperUncompleted color={active ? success.main : tertiary.main} />;
};

export const Step = styled(MuiStep)({
  width: "100%",
  padding: 0,
  position: "relative",
});

export const StepLabel = styled(MuiStepLabel)(({ theme }) => {
  return {
    padding: theme.spacing(2.5, 0),
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.primary.main,
    userSelect: "none",

    "&.Mui-disabled": {
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.tertiary.main,

      "& .MuiStepLabel-labelContainer": {
        color: theme.palette.text.placeholder,
      },
    },
  };
});

const ProgressSteps = ({ activeStep, steps }: ProgressStepsProps) => {
  return (
    <Stepper activeStep={activeStep - 1} variant="elevation" connector={null}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel icon={null} StepIconComponent={StepIconComponent}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ProgressSteps;
